import React, { Component } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getLocalStorageInfo } from '../../../../utils';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
class CashFlowGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    var headingText = this.props.headingText
    var lessorDetail = this.props.lessorDetail
    var colors = [ '#3c7ac2', '#85c472', '#ee7c26','#E6B333', '#757526']
    const {jspLessor, cashflowSummaryWidget } = this.props;
    const options = {
      chart: {
        type: this.props.graphType,
        zoomType: 'x',
        height: '300',
        marginTop: 60,
        events: {
          load: function () {
            if (lessorDetail && lessorDetail.lessor_name) {
              var label = this.renderer.label(headingText + "<br/>Lessor: " + lessorDetail.lessor_name)
            } else {
              var label = this.renderer.label(headingText + "<br/>Lessor: " + getLocalStorageInfo().defaultLessor.name)
            }
            label.css({
              width: '400px',
              fontSize: '9px'
            })
              .attr({
                'stroke': 'silver',
                'stroke-width': 1,
                'r': 2,
                'padding': 5
              })
              .add();

            label.align(Highcharts.extend(label.getBBox(), {
              align: 'left',
              x: 0, // offset
              verticalAlign: 'bottom',
              y: -250 // offset
            }), null, 'spacingBox');

          }
        }
      },
      colors:colors,
      title: {
        text: this.props.graphTitle
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: this.props.graphxLabel
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      lang: {
        noData: 'No Records Found'
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '20px',
          color: '#000',
          fontFamily: 'Conv_IBMPlexSans-Regular'
        }
      },

      yAxis: {
        title: {
          text: this.props.graphyLabel
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        },
        gridLineDashStyle: 'longdash'
      },
      series: jspLessor ? [{
        name: 'MR Cash Flow',
        data: cashflowSummaryWidget && cashflowSummaryWidget.currency_usd && Object.keys(cashflowSummaryWidget.currency_usd.mr_plot).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.currency_usd.mr_plot[year] } })
      },
      {
        name: 'Esp Cash Flow',
        data: cashflowSummaryWidget && cashflowSummaryWidget.currency_usd && Object.keys(cashflowSummaryWidget.currency_usd.esp_plot).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.currency_usd.esp_plot[year] } })
      },
      {
        name: 'Lsp Cash Flow',
        data: cashflowSummaryWidget && cashflowSummaryWidget.currency_usd && Object.keys(cashflowSummaryWidget.currency_usd.lsp_plot).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.currency_usd.lsp_plot[year] } })
      },
      {
        name: 'Jsp Cash Flow',
        data: cashflowSummaryWidget && cashflowSummaryWidget.currency_usd && Object.keys(cashflowSummaryWidget.currency_usd.jsp_plot).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.currency_usd.jsp_plot[year] } })
      },
      {
        name: 'Rental Cash Flow',
        data: cashflowSummaryWidget && cashflowSummaryWidget.currency_usd && Object.keys(cashflowSummaryWidget.currency_usd.rental_plot).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.currency_usd.rental_plot[year] } })
      }]:
      [{
        name: 'MR Cash Flow',
        data: cashflowSummaryWidget && cashflowSummaryWidget.currency_usd && Object.keys(cashflowSummaryWidget.currency_usd.mr_plot).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.currency_usd.mr_plot[year] } })
      },
      {
        name: 'Rental Cash Flow',
        data: cashflowSummaryWidget && cashflowSummaryWidget.currency_usd && Object.keys(cashflowSummaryWidget.currency_usd.rental_plot).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.currency_usd.rental_plot[year] } })
      }] ,
      credits: {
        enabled: false
      },
      exporting: {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart({ type: 'image/png' });
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      }
    }
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    )
  }
}
export default CashFlowGraph




