import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { ContrInputField, ContrTextareaField } from '../../Elements';
import NumericLabel from 'react-pretty-numbers';
import { utilizationFormat } from '../../../../constants';
import { toastFlashMessage } from '../../../../utils';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldInput } from '../../Elements';
import { TextInputField } from '../../../technicalInspection/Elements';
import { checkNumber, checkString } from '../../../../formValidator';
import EditUitlField from './EditUitlField';
import LLpSplit from './LLpSplit';
const decimalRegex = /^[0-9\.\/]+$/;
export default class EditUtils extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utilsEdit: {},
      utilsCopy: {},
      error: {},
      lLpSplit:false
    }
  }
  componentDidMount() {
    this.setState({
      utilsEdit: this.props.utilsObj,
      utilsCopy: this.props.utilsObj
    })
  }
  roundBy60 = (val1, val2, type, name) => {
    let number;
    let floatNumber;
    if (type == 'add') {
      // if(name.includes('APU') || name.includes('apu')){
      //   number = (parseFloat(val1) + parseFloat(val2)).toFixed(2)
      // }else{
      //   number = Math.floor(val1) + Math.floor(val2) + parseFloat(parseFloat(((parseFloat(val1 % Math.floor(val1)) + parseFloat(val2 % Math.floor(val2))).toFixed(2)%0.60).toFixed(2)).toFixed(2)) + parseInt(parseFloat(((parseFloat(val1 % Math.floor(val1)) + parseFloat(val2 % Math.floor(val2))).toFixed(2)/0.60).toFixed(2)).toFixed(2));
      // }
      number = (parseFloat(val1) + parseFloat(val2)).toFixed(2)
    } else {
      // if(name.includes('APU') || name.includes('apu')){
      //   number = (parseFloat(val1) - parseFloat(val2)).toFixed(2)
      // }else{
      //   let diff = parseFloat(parseFloat(parseFloat(val1 % Math.floor(val1)) - parseFloat(val2 % Math.floor(val2))).toFixed(2));
      //   let diffVale = Math.floor(val1) - Math.floor(val2);
      //   number = (diff > 0 ? (diffVale+diff):((diffVale - 1) + (.60 + diff)));
      // }
      number = (parseFloat(val1) - parseFloat(val2)).toFixed(2)
    }
    return number
  }
  updateForm = (type, value, totalHours, totalCycles) => {
    if (this.state.utilsEdit.tsn_as_of_last_month > 0) {
      if (value != '') {
        let assetObj = Object.assign({}, this.state.utilsEdit);
        switch (type) {
          case 'hours':
            assetObj = {
              ...assetObj,
              hours: value,
              cycles: this.state.utilsEdit.cycles,
              tsn: parseFloat(this.roundBy60(this.state.utilsEdit.tsn_as_of_last_month, parseFloat(value), 'add', this.state.utilsEdit.name)),
              csn: this.state.utilsEdit.csn
            }

            break;
          case 'cycles':
            assetObj = {
              ...assetObj,
              hours: this.state.utilsEdit.hours,
              cycles: value,
              tsn: this.state.utilsEdit.tsn,
              csn: (parseFloat(this.state.utilsEdit.csn_as_of_last_month) + parseFloat(value)).toFixed(2)
            }
            break;
          case 'tsn':
            assetObj = {
              ...assetObj,
              hours: this.roundBy60(value, this.state.utilsEdit.tsn_as_of_last_month, 'sub', this.state.utilsEdit.name),
              cycles: this.state.utilsEdit.cycles,
              tsn: value,
              csn: this.state.utilsEdit.csn
            }
            break;
          case 'csn':
            assetObj = {
              ...assetObj,
              hours: this.state.utilsEdit.hours,
              cycles: (parseFloat(value) - parseFloat(this.state.utilsEdit.csn_as_of_last_month)).toFixed(2),
              tsn: this.state.utilsEdit.tsn,
              csn: value
            }
            break;
            case 'thrust_split':
              assetObj={
                ...assetObj,
                'thrust_split':value,
                cycles:totalCycles,
                hours:totalHours,
                csn: parseFloat((parseFloat(this.state.utilsEdit.csn_as_of_last_month) + parseFloat(totalCycles)).toFixed(2)),
                tsn: parseFloat((parseFloat(this.state.utilsEdit?.tsn_as_of_last_month) +parseFloat(totalHours)).toFixed(2)) ,
              }
            break;
          case 'remarks':
            assetObj = {
              ...assetObj,
              remarks: value
            }
            break
          case 'serviceability':
            assetObj = {
              ...assetObj,
              serviceability: value
            }
            break
          case 'asset_location':
            assetObj = {
              ...assetObj,
              asset_location: value
            }
            break;
          case 'tsn_as_of_last_month':
            assetObj = {
              ...assetObj,
              tsn_as_of_last_month: value,
              tsn: parseFloat(this.roundBy60(this.state.utilsEdit.hours, parseFloat(value), 'add', this.state.utilsEdit.name))
            }
            break;
          case 'csn_as_of_last_month':
            assetObj = {
              ...assetObj,
              csn_as_of_last_month: value,
              csn: (parseFloat(value) + parseFloat(this.state.utilsEdit.cycles)).toFixed(2)
            }
            break;
          default:
        }

        this.setState(prevState => ({
          ...prevState,
          utilsEdit: assetObj,
          error: {
            ...prevState.error,
            [type]: ''
          }
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          utilsEdit: {
            ...prevState.utilsEdit,
            [type]: value
          },
          error: {
            ...prevState.error,
            [type]: ''
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        utilsEdit: {
          ...prevState.utilsEdit,
          [type]: value,
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));

    }
  }
  editUtilsInfo = (e) => {
    e.preventDefault();
    const validateNewInput = {
      hours: checkNumber({ value: this.state.utilsEdit.hours, required: true, minLength: '', maxLength: '', message: `${decimalRegex.test(this.state.utilsEdit.hours) ? 'Enter max upto 2 decimals ' : 'Please enter Hours'}` }),
      cycles: checkNumber({ value: this.state.utilsEdit.cycles, required: true, minLength: '', maxLength: '', message: `${decimalRegex.test(this.state.utilsEdit.cycles) ? 'Enter max upto 2 decimals ' : 'Please enter Cycles'}` }),
      tsn: checkNumber({ value: this.state.utilsEdit.tsn && this.state.utilsEdit.tsn != '' ? this.state.utilsEdit.tsn : 0, required: true, minLength: '', maxLength: '', message: `${decimalRegex.test(this.state.utilsEdit.tsn) ? 'Enter max upto 2 decimals ' : 'Please enter TSN'}` }),
      csn: checkNumber({ value: this.state.utilsEdit.csn && this.state.utilsEdit.csn != '' ? this.state.utilsEdit.csn : 0, required: true, minLength: '', maxLength: '', message: `${decimalRegex.test(this.state.utilsEdit.csn) ? 'Enter max upto 2 decimals ' : 'Please enter CSN'}` }),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      // if (this.props.type == 'add') {
        this.props.toggleEditSideBar();
      // }
      let data = {
        ...this.state.utilsEdit,
        csn: this.state.utilsEdit.csn && this.state.utilsEdit.csn != '' ? this.state.utilsEdit.csn : 0,
        tsn: this.state.utilsEdit.tsn && this.state.utilsEdit.tsn != '' ? this.state.utilsEdit.tsn : 0,
      }
      this.props.editUtilsInfo(this.props.contract_slug, data);
    } else {
      this.setState({
        error: validateNewInput
      });
    }

  }
  render() {
    const { utilsEdit, error, lLpSplit } = this.state;
    const { type } = this.props;
    if (!Object.keys(utilsEdit).length) {
      return null
    }
    return (
      <form onSubmit={this.editUtilsInfo} className="sidebar-form">
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
          <Row >
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Name
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.name}
                  keyParam="name"
                  type="view"
                  updateUtilsFields={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  TSN of Last Month
                </FieldLabel>
                <TextInputField
                  value={utilsEdit.tsn_as_of_last_month}
                  type={utilsEdit.first_utilization ? type : "view"}
                  keyParam="part_number"
                  delay={true}
                  updateForm={(value) => value != utilsEdit.tsn_as_of_last_month ? this.updateForm('tsn_as_of_last_month', value) : null}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  CSN of Last Month
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.csn_as_of_last_month > 0 ? utilsEdit.csn_as_of_last_month : '0'}
                  keyParam="csn_as_of_last_month"
                  type={utilsEdit.first_utilization ? type : "view"}
                  updateUtilsFields={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Hours {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.hours}
                  keyParam="hours"
                  type={utilsEdit?.asset_type == 2? 'view':type}
                  updateUtilsFields={this.updateForm}
                />
                { utilsEdit?.asset_type == 2 ? <div className='lnk-redrxn' onClick={()=>this.setState({lLpSplit:{modal:true,data:utilsEdit,index:0, viewType:type}})}> {type=='view'? "View": 'Edit'} Thrust Split</div>: null}
                <h6 className="error-msg">{error.hours}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Cycles {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.cycles}
                  keyParam="cycles"
                  type={utilsEdit?.asset_type == 2? 'view':type}
                  // type={type}
                  updateUtilsFields={this.updateForm}
                />
                { utilsEdit?.asset_type == 2 ? <div className='lnk-redrxn' onClick={()=>this.setState({lLpSplit:{modal:true,data:utilsEdit,index:0, viewType:type}})}> {type=='view'? "View": 'Edit'} Thrust Split</div>: null}
                <h6 className="error-msg">{error.cycles}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">TSN</FieldLabel>
                <EditUitlField
                  value={utilsEdit.tsn}
                  keyParam="tsn"
                  type={type}
                  updateUtilsFields={this.updateForm}
                />
                <h6 className="error-msg">{error.tsn}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  CSN
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.csn}
                  keyParam="csn"
                  type={type}
                  updateUtilsFields={this.updateForm}
                />
                <h6 className="error-msg">{error.csn}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Present Location of Original Assembly
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.asset_location}
                  type={type}
                  keyParam="asset_location"
                  updateUtilsFields={this.updateForm}
                />

              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Remark
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.remarks}
                  type={type}
                  keyParam="remarks"
                  updateUtilsFields={this.updateForm}
                />
                <h6 className="error-msg">{error.remarks}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Serviceability
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.serviceability}
                  type={type}
                  maxLength='25'
                  keyParam="serviceability"
                  updateUtilsFields={this.updateForm}
                />
                <h6 className="error-msg">{error.serviceability}</h6>

              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes" />
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div> : null
        }
        {lLpSplit?.modal ?
          <LLpSplit
              lLpSplit={lLpSplit}
              data={lLpSplit?.data}
              onClose={() => this.setState({ lLpSplit: false })}
              saveLLpSplit={(data, totalHours, totalCycles) => {this.setState({ lLpSplit:{...lLpSplit, modal:false} });this.updateForm('thrust_split',data, totalHours, totalCycles)}}
            />
            : null
        }
      </form>
    )
  }
}
