import React, { useState } from 'react';
import { TableRow, TableCell, } from '@material-ui/core';
import MemberCRU from './MemberCRU';
export default function GroupUserList({ item, member, groupId, getResponseBack, contractInfo }) {
  const [showMore, setShowMore] = useState({});
  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{`${member.first_name} ${member.last_name}`}</TableCell>
      <TableCell>{member.designation ? member.designation : '--'}</TableCell>
      <TableCell>{member.email}</TableCell>
      <TableCell>{member.mobile ? member.mobile : '--'}</TableCell>
      <TableCell>
        {member.remarks && member.remarks?.length > 200 ? (
          <div style={{ maxWidth: '400px' }}>
            {showMore[member?.id] && showMore[member?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: member.remarks.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(member.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: member.remarks.substring(0, 200).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(member.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
            <span dangerouslySetInnerHTML={{ __html: member.remarks.replace(/\n/g, '<br />') }} />
          </div>
        )}
      </TableCell>
      <TableCell> <MemberCRU id={member.id} groupId={groupId} getResponseBack={getResponseBack} m="V" contractInfo={contractInfo} /></TableCell>
    </TableRow>
  )
}
