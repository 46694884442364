import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { CtrTableHd, ContractSideBar, Header, ContractBarLoader, EditSidebar } from '../../Elements';
import { InfoIcon, ListLoader } from '../../../../shared';
import { invoiceViewAc, InvoiceAssetViewAc, changeInvoiceStatusAc, detailInvExportAc, getShareInvoiceInfoAc, updateShareInvFnAc, deleteInvoiceViewAc } from '../actionCreators';
import { sExportContractsAc } from '../../../../shared/actionCreators';
import { INVOICE_SHARE } from '../actions';
import InvFields from '../components/InvFields';
import ChangeInvoiceStatus from '../components/ChangeInvoiceStatus';
import ShareInvoice from '../components/ShareInvoice';
import { invoiceStatusList, displayDateFormat, invoiceDescList, imgStoragePath, assetsType } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo, showCurrencyFormat } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import InvoiceInfo from '../components/InvoiceInfo'
import LessorInfo from '../components/LessorInfo'
import LesseeInfo from '../components/LesseeInfo'
import BankingInfo from '../components/BankingInfo'
import MaintenanceInvoiceItem from '../components/MaintenanceInvoiceItem'
import RentalInvoiceItem from '../components/RentalInvoiceItem'
import CreditDebitNote from '../components/CreditDebitNote'
import PenaltyInvoiceItem from '../components/PenaltyInvoiceItem'
import SecurityInvoiceItem from '../components/SecurityInvoiceItem'
import InsuranceInvoiceItem from '../components/InsuranceInvoiceItem'
import MRAdhocInvoiceItem from '../components/MRAdhocInvoiceItem'
import RentalAdhocInvoiceItem from '../components/RentalAdhocInvoiceItem'
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import emailIcon from '../../../../shared/assets/img/send_email_icon.svg';
import PartialPaymentsList from '../components/PartialPaymentsList';
import { getInvoiceStatusTimelineApi, getThirdPartyAppUrl } from '../apiServices';
import HorizontalTimeline from "react-horizontal-timeline";
import { Dialog, DialogContent, DialogTitle, Paper, Tooltip, Typography, Button as MuiButton } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TimelineOppositeContent } from '@material-ui/lab';
import { Close, Info, KeyboardArrowDown } from '@material-ui/icons';
import { contrHdlineInfoAc } from '../../HeadlinesInfo/actionCreators';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
class ViewInvoiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      delModal: false,
      deleteInvoice: false,
      dropdownOpen: false,
      data: {},
      statusTimeline: [],
      showStatusTimeLine: false,
      thirdPartyAppUrl: '',
      loader: false,
      syncAgainFlag:null
    };
    this.toggle = this.toggle.bind(this);
    this.getInvoiceStatusTimelineApi = getInvoiceStatusTimelineApi.bind(this);
    this.getThirdPartyAppUrl = getThirdPartyAppUrl.bind(this);
  }
  componentDidMount() {
    this.props.fetchContractInfo();
    this.props.fetchAssetInfo();
    this.props.fetchInvoiceView();
    this.getInvoiceStatusTimelineApi(this.props)
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  toggleDelModal(data) {
    this.setState({
      delModal: !this.state.delModal,
      data: data,
    });
  }

  toggleExport = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  updateInvStatus = (data) => {
    this.toggle();
    if (data.status == 3 || data.status == 4) {
      this.toggleDelModal(data);
    } else {
      this.props.updateInvStatus(data, 'view');
    }
  }
  getThirdPartyFn =(data)=>{
    const {invoiceInfo} = this.props 
    if(invoiceInfo?.qb_last_sync_by?.name && invoiceInfo?.qb_last_sync_time){
      this.setState({syncAgainFlag:{flag:true, data:data}})
    }
    else{
      this.getThirdPartyAppUrl(data)
    }
  }
  getHours = (units, unit_type, invoice_item_type) => { return units.toString() }

  render() {
    const { invoiceInfo, ctrShortInfo, params, invoiceLoader, shareInvoiceCrud, invoiceExportLoader, contractInfo } = this.props;
    const startDate = invoiceInfo && invoiceInfo.start_date ? invoiceInfo.start_date : null
    const endDate = invoiceInfo && invoiceInfo.contract_end_date ? invoiceInfo.contract_end_date : null
    const total = invoiceInfo && invoiceInfo.invoice_items ? (invoiceInfo.invoice_items.reduce((total, next) => total + next.billable_amount, 0) + invoiceInfo.invoice_items.reduce((total, next) => total + next.tax_amount, 0)) : 0
    const partialPaidAmount = invoiceInfo && invoiceInfo.partial_paid_amount ? invoiceInfo.partial_paid_amount : 0
    const diff = total - partialPaidAmount;
    const { showStatusTimeLine, statusTimeline } = this.state
    let isJac = getLocalStorageInfo().defaultLessor.id === 442 ? true : false
    return (
      <div className="mr-rate-wrapper  mr-rate-spacing" >
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        {
          invoiceInfo && Object.keys(invoiceInfo).length ?
            <ContractSideBar application={`Invoice View - ${invoiceInfo.invoice_number}`} type="brief" params={this.props.params} />
            : null
        }
        {invoiceLoader || this.state.loader ? <ListLoader /> : null}
        {invoiceExportLoader  || this?.props?.contractSummaryLoader? <ListLoader /> : null}
        {Object.keys(invoiceInfo).length ?
          <div className="add-edit-invoice-form view-block" >
            <Row className="company-form-block invoice-heading">
              <Col xs={6}>
                <Link className="back-link" to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/${this.props.params.contract_slug}/invoice`}>
                  <img width="15" src={imgStoragePath + "back_arw.png"} alt="img" />
                  <div className="inv-back-info">
                    <span>{invoiceDescList[invoiceInfo.invoice_type] + ' Invoice'}</span><br />
                    <h6>Invoice {'#' + invoiceInfo.invoice_number}</h6>
                  </div>
                </Link>
              </Col>
              <Col xs={6}>
                <ul className='list-inline flex-centered' style={{ float: 'right' }}>
                  {(invoiceInfo.status == '1' || invoiceInfo.status == '2') && checkPermission('contracts', 'invoices', 'U') && contractInfo?.hdlineInfo?.archived_asset === false && invoiceInfo.summary_invoice_enabled === false ?
                    <li className='list-inline-item'>
                      <Link to={'/' + this.props.params.type + '/' + this.props.params.aircraft_slug + '/contract/' + this.props.params.contract_slug + '/invoice/edit/' + this.props.params.id} className="edit-icon edit-icon-bar"><img width="16" src={editIcon} alt="img" /> Edit</Link>
                    </li> : null
                  }
                  {(invoiceInfo.status == '1' || invoiceInfo.status == '2') && checkPermission('contracts', 'invoices', 'U') && contractInfo?.hdlineInfo?.archived_asset === false ?
                    <li className='list-inline-item'>
                      <span onClick={this.props.getShareInvoiceInfo} style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '14px', float: 'right', borderRight: '1px solid #3f51b5', padding: '0 10px',  borderLeft:invoiceInfo.summary_invoice_enabled === false? '1px solid #3f51b5':'' }}><img style={{ width: "16px", paddingBottom: '3px' }} src={emailIcon} alt="export" /> Send Email</span>
                    </li> : null
                  }
                  {checkPermission('contracts', 'invoices', 'D') && (invoiceInfo.status == '1' || invoiceInfo.status == '2') && contractInfo?.hdlineInfo?.archived_asset === false  && invoiceInfo.summary_invoice_enabled === false ?
                    <li className='list-inline-item'>
                      <span style={{ fontSize: '14px', paddingRight: '10px', color: '#ff0000', cursor: 'pointer', fontFamily: '$ibmplexRegular', borderRight: '1px solid #3f51b5' }} onClick={() => this.setState({ deleteInvoice: true })} >Delete</span>
                    </li> : null
                  }
                  {
                    invoiceInfo?.status == 2 && invoiceInfo?.qb_enabled && invoiceInfo?.qb_enabled == true && [13, 442].includes(getLocalStorageInfo().defaultLessor?.id) ?
                      <li>
                        <span onClick={() => {this.getThirdPartyFn({ invoice_id: this.props?.params?.id })}} style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '14px', float: 'right', borderRight: '1px solid #3f51b5', padding: '0 10px' }}>Sync to QB
                        {/* <span onClick={() => this.getThirdPartyAppUrl({ invoice_id: this.props?.params?.id })} style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '14px', float: 'right', borderRight: '1px solid #3f51b5', padding: '0 10px' }}>Sync to QB */}
                          <Tooltip title={<table>
                            <tbody className='qb-status-tooltip'>
                              <tr >
                                <td>Last Updated By:&nbsp; </td>
                                <td>{invoiceInfo?.qb_last_sync_by?.name || '--'} {' '}({invoiceInfo?.qb_last_sync_time ? moment(invoiceInfo?.qb_last_sync_time).format('MMM DD, YYYY') : '--'})</td>
                              </tr>
                            </tbody>
                          </table>} arrow placement='top'>
                            <span >
                              <InfoIcon />
                            </span>
                          </Tooltip>
                        </span>
                      </li>
                      :
                      (invoiceInfo?.qb_invoice !== null && invoiceInfo?.status !== 2 && invoiceInfo?.status !== 1 ?
                      <li className='list-inline-item'>
                                        <Tooltip disableInteractive title={<table>
                                            <tbody className='qb-status-tooltip' >
                                                <tr>
                                                    <td>Sync Status(QB):</td>
                                                    <td>Already Synchronized on QB</td>
                                                </tr>
                                                <tr>
                                                    <td>Last Updated By:&nbsp; </td>
                                                    <td>{invoiceInfo?.qb_last_sync_by?.name || '--'} {' '}({invoiceInfo?.qb_last_sync_time ? moment(invoiceInfo?.qb_last_sync_time).format('MMM DD, YYYY') : '--'})</td>
                                                </tr>
                                            </tbody>
                                        </table>} arrow placement='top'>
                                            <span>
                                                <InfoRoundedIcon fontSize='small' style={{ color: 'rgb(174 172 172)',display:'flex'}} />
                                            </span>
                                        </Tooltip>
                                </li>:null)
                  }
                  {checkPermission('contracts', 'invoices', 'EXP') ?
                    <li className='list-inline-item'>
                      <div className="download-block">
                        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleExport}>
                          <DropdownToggle caret size="sm">
                            <span><img width="12" src={exportIcon} alt="export" /> Export </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number, 'pdf', invoiceInfo)}><img src={imgStoragePath + "pdf_icon.png"} alt="img" />PDF</DropdownItem>
                            <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number, 'xls', invoiceInfo)} ><img src={imgStoragePath + "excel_icon.png"} alt="img" />Excel</DropdownItem>
                            <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number, 'docx', invoiceInfo)} ><img src={imgStoragePath + "docs_icon.png"} alt="img" />Doc</DropdownItem>
                            <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number, 'csv', invoiceInfo)} ><img src={imgStoragePath + "excel_icon.png"} alt="img" />CSV</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </li> : null
                  }
                </ul>
              </Col>
            </Row>
            <form onSubmit={(e) => this.createInvoice(e)} style={{ padding: '1px', marginTop: '10px' }}>
              <InvoiceInfo
                viewTimeline={() => this.setState({ showStatusTimeLine: true })}
                type={'view'}
                contractInfo={contractInfo}
                invoiceInfo={invoiceInfo} toggleInvoiceState={() => this.toggle()}
                invoiceStatusList={invoiceStatusList}
                startDate={startDate}
                endDate={endDate}
              />
              <Row className="margin-0 company-form-block">
                <LessorInfo
                  invoiceInfo={invoiceInfo}
                  type="view"
                />
                <LesseeInfo
                  invoiceInfo={invoiceInfo}
                  type="view"
                />
              </Row>
              {invoiceInfo.invoice_type == 2 && invoiceInfo.rental_description && invoiceInfo.rental_description != '' ?
                <Row className="margin-0 company-form-block">
                  <Col md="12" className="padding-right-0">
                    <Row className="inner-wrap"
                      style={{ background: '#fafafa', padding: '0 15px', marginRight: '15px' }}
                    >
                      <InvFields label="Description" colSize="12" padding='padding-left-0' value={invoiceInfo.rental_description ? invoiceInfo.rental_description : '--'} />
                    </Row>
                  </Col>
                </Row> : null
              }
              <div className="charge-description-block " >
                {invoiceInfo.invoice_type === 9 ?
                  <div>
                    <div className="table-block">
                      <table className="contr-table invoice-table-style">
                        {
                          isJac ?
                            <CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Sub Total (' + invoiceInfo.currency + ')']} />
                            :
                            <CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                        }
                        <tbody>
                          {invoiceInfo.invoice_items.map((invoice_item, index) => (
                            invoiceInfo.parent_invoice_type == 1 ?
                              <MRAdhocInvoiceItem
                                isJac={isJac}
                                key={index}
                                type="view"
                                invoiceInfo={invoiceInfo}
                                invoice_item={invoice_item}
                                value={invoice_item}
                                index={index}
                                validationArray={this.state.validationArray}
                                updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                              />
                              : <RentalAdhocInvoiceItem
                                key={index}
                                isJac={isJac}
                                type="view"
                                invoiceInfo={invoiceInfo}
                                value={invoice_item}
                                invoice_item={invoice_item}
                                index={index}
                                validationArray={this.state.validationArray}
                                updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                              />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="sub-total-block">
                      <h5 className="para-ui">Billable Amount:
                        <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                          {
                            invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(next.billable_amount), 0)
                          }
                        </NumericLabel>
                      </h5>
                      {
                        invoiceInfo.status == '5' ?
                          <h5 className="para-ui">Paid Amount :
                            <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                              {invoiceInfo.partial_paid_amount}
                            </NumericLabel>
                          </h5> : null
                      }
                      {
                        isJac ?
                          null :
                          <h5 className="para-ui">Tax:
                            <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                              {
                                invoiceInfo.invoice_items.reduce((total, next) => total + next.tax_amount, 0)
                              }
                            </NumericLabel>
                          </h5>}
                      <h4 className="para">Total:
                        <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                          {invoiceInfo.status == "5" ? diff : total}
                        </NumericLabel>
                      </h4>
                    </div>
                  </div>
                  : (invoiceInfo.invoice_type == 1 || invoiceInfo.invoice_type == 2) ?
                    <div>
                      {invoiceInfo.invoice_type == 2 ? <h5 className="para currency-break">{invoiceInfo.currency} Rental Receivable</h5> : null}
                      <div className="table-block">
                        <table className="contr-table invoice-table-style">
                          {
                            isJac ?
                              <CtrTableHd theads={invoiceInfo.interval === 5 ? ['Item', 'Description', 'Unit Description', 'No. Of Days', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Sub Total (' + invoiceInfo.currency + ')'] : ['Item', 'Description', 'Unit Description', 'No. Of Units', 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Sub Total (' + invoiceInfo.currency + ')']} />
                              :
                              <CtrTableHd theads={invoiceInfo.interval === 5 ? ['Item', 'Description', 'Unit Description', 'No. Of Days', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + invoiceInfo.currency + ')'] : ['Item', 'Description', 'Unit Description', 'No. Of Units', 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                          }
                          <tbody>
                            {invoiceInfo.invoice_items.map((value, index) => (
                              invoiceInfo.invoice_type === 1 ?
                                <MaintenanceInvoiceItem
                                  isJac={isJac}
                                  key={index}
                                  type="view"
                                  value={value}
                                  invoiceInfo={invoiceInfo}
                                />
                                : <RentalInvoiceItem
                                  isJac={isJac}
                                  key={index}
                                  type="view"
                                  invoice_item={value}
                                  invoiceInfo={invoiceInfo}
                                  validationArray={[]}
                                />
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="sub-total-block">
                        <h5 className="para-ui">Billable Amount :
                          <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                            {invoiceInfo.invoice_items.reduce((total, next) =>
                              total + parseFloat(parseFloat(next.billable_amount).toFixed(2)), 0
                            )}
                          </NumericLabel>
                        </h5>
                        {
                          invoiceInfo.status == '5' ?
                            <h5 className="para-ui">Paid Amount :
                              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                                {invoiceInfo.partial_paid_amount}
                              </NumericLabel>
                            </h5> : null
                        }
                        {
                          isJac ?
                            null :
                            <h5 className="para-ui">Tax:
                              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                                {invoiceInfo.invoice_items.reduce((total, next) =>
                                  total + parseFloat(parseFloat(next.tax_amount).toFixed(2)) , 0
                                )}
                              </NumericLabel>
                            </h5>}
                        <h4 className="para">Total:
                          <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                            {invoiceInfo.total_amount ? invoiceInfo.total_amount : invoiceInfo.sub_total !== undefined && invoiceInfo.sub_total !== null && invoiceInfo.sub_total !== '' ? invoiceInfo.sub_total : invoiceInfo.status == "5" ? diff : total}
                          </NumericLabel>
                        </h4>
                      </div>
                    </div>
                    : (invoiceInfo.invoice_type == 4 || invoiceInfo.invoice_type == 5) ?
                      <div className="table-block">
                        <table className="contr-table invoice-table-style">
                          <CtrTableHd theads={['Description', 'Amount', 'Tax Rate (%)', 'Tax Amount', 'Total (USD)']} />
                          <tbody>
                            <CreditDebitNote
                              type="view"
                              invoice_item={invoiceInfo?.invoice_items?.length ? invoiceInfo.invoice_items[0] : []}
                              invoiceInfo={invoiceInfo}
                            />
                          </tbody>
                        </table>
                      </div> :
                      invoiceInfo.invoice_type === 6 ?
                        <div className="table-block">
                          <table className="contr-table invoice-table-style">
                            <CtrTableHd theads={['Item', 'Security Against', 'Security Deposit Type', ' Security Deposit Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                            <tbody>
                              <SecurityInvoiceItem
                                type="view"
                                invoice_item={invoiceInfo?.invoice_items?.length ? invoiceInfo.invoice_items[0] : []}
                                invoiceInfo={invoiceInfo}
                              />
                            </tbody>
                          </table>
                        </div> :
                        invoiceInfo.invoice_type === 7 ?
                          <div className="table-block">
                            <table className="contr-table invoice-table-style">
                              <CtrTableHd theads={['Item', 'Description', 'Insurance Type', 'Insurance Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                              <tbody>
                                <InsuranceInvoiceItem
                                  type="view"
                                  invoice_item={invoiceInfo?.invoice_items?.length ? invoiceInfo.invoice_items[0] : []}
                                  invoiceInfo={invoiceInfo}
                                />
                              </tbody>
                            </table>
                          </div> : invoiceInfo.invoice_type === 8 ?
                            <div className="table-block">
                              <table className="contr-table invoice-table-style">
                                <CtrTableHd theads={['Item', 'Description', 'Insurance Type', 'Insurance Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                                <tbody>
                                  <InsuranceInvoiceItem
                                    type="view"
                                    invoice_item={invoiceInfo?.invoice_items?.length ? invoiceInfo.invoice_items[0] : []}
                                    invoiceInfo={invoiceInfo}
                                  />
                                </tbody>
                              </table>
                            </div> :
                            <div className="table-block">
                              <table className="contr-table invoice-table-style">
                                <CtrTableHd theads={['Over Due Amount', 'Description', 'Due Date', 'Payment Date', 'No Of Days Over Due', '6 Months USD LIBOR  ' + (invoiceInfo.libor_date ? '(' + moment(invoiceInfo.libor_date).format(displayDateFormat) + ')' : ''), 'Additional Rate', 'Total Penal Interest Rate', 'Interest On Over Due Amount']} />
                                <tbody>
                                  <PenaltyInvoiceItem
                                    type="view"
                                    invoiceInfo={invoiceInfo}
                                  />
                                </tbody>
                              </table>
                            </div>
                }
              </div>
              {
                invoiceInfo && invoiceInfo.partial_payments ?
                  <div className="partial-payment-block">
                    <h5>Partial-Payment Details</h5>
                    <div className="table-block">
                      <table className="contr-table partial-payment-table-style">
                        <CtrTableHd theads={['Description', 'Amount Paid', 'Date of Payment', 'Remaining Amount', 'User', 'Last Updated At']} />
                        <tbody>
                          {invoiceInfo.partial_payments.map((item, index) => (
                            <PartialPaymentsList
                              key={index}
                              value={item}
                              invoiceInfo={invoiceInfo}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div> : null
              }
              <h5 className="para invoice-heading">Bank Detail {invoiceInfo.invoice_type == 2 ? 'For ' + invoiceInfo.currency + ' Rental Receivable' : ''}</h5>
              <BankingInfo
                type="view"
                invoiceInfo={invoiceInfo}
              />

              {invoiceInfo.invoice_type == 2 && invoiceInfo.children ?
                <div>
                  {invoiceInfo.children.length > 0 ?
                    invoiceInfo.children.map((children, index) =>
                      <div>
                        <div className="charge-description-block ">
                          <div className="table-block">
                            <h5 className="para currency-break">{children.currency} Rental Receivable</h5>
                            <table className="contr-table invoice-table-style">
                              {
                                isJac ?
                                  <CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', children.rental_type === 1 ? 'Fixed Rate (' + children.currency + ')' : 'Fixed / Per Unit Rate (' + children.currency + ')', 'Sub Total (' + children.currency + ')']} /> :
                                  <CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', children.rental_type === 1 ? 'Fixed Rate (' + children.currency + ')' : 'Fixed / Per Unit Rate (' + children.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + children.currency + ')']} />
                              }
                              <tbody>
                                {children.invoice_items.map((value, index) => (
                                  <RentalInvoiceItem
                                    isJac={isJac}
                                    key={index}
                                    type="view"
                                    invoice_item={value}
                                    invoiceInfo={invoiceInfo}
                                    validationArray={[]}
                                  />
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="sub-total-block">
                            <h5 className="para-ui">Billable Amount :
                              <NumericLabel params={showCurrencyFormat(children.currency)}>
                                {children.invoice_items.reduce((total, next) =>
                                  total + next.billable_amount, 0
                                )}
                              </NumericLabel>
                            </h5>
                            {
                              invoiceInfo.status == '5' ?
                                <h5 className="para-ui">Paid Amount :
                                  <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                                    {invoiceInfo.partial_paid_amount}
                                  </NumericLabel>
                                </h5> : null
                            }
                            {
                              isJac ? null :
                                <h5 className="para-ui">Tax :
                                  <NumericLabel params={showCurrencyFormat(children.currency)}>
                                    {children.invoice_items.reduce((total, next) =>
                                      total + parseFloat(parseFloat(next.tax_amount).toFixed(2)) , 0
                                    )}
                                  </NumericLabel>
                                </h5>
                            }
                            <h4 className="para">Total:
                              <NumericLabel params={showCurrencyFormat(children.currency)}>
                                {children.invoice_items ? children.invoice_items.reduce((total, next) =>
                                  total + next.sub_total, 0
                                ) : invoiceInfo.sub_total !== undefined && invoiceInfo.sub_total !== null && invoiceInfo.sub_total !== '' ? invoiceInfo.sub_total : 0}
                              </NumericLabel>
                            </h4>
                          </div>
                        </div>
                        <h5 className="para invoice-heading">Bank Detail {invoiceInfo.invoice_type == 2 ? 'For ' + children.currency + ' Rental Receivable' : ''}</h5>
                        <BankingInfo
                          type="view"
                          invoiceInfo={children}
                        />
                      </div>
                    ) : null
                  }
                </div> : null
              }
            </form>
            <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={"Change Status"} className="modal-tform-delete-pictures modal-dialog-centered">
              <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to mark this invoice as {this.state.data && this.state.data.status == 3 ? "'paid' ?" : "'cancelled' ?"}</p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button type="button" className="btn btn-danger" onClick={() => { this.toggleDelModal(''); this.props.updateInvStatus(this.state.data, 'view'); }}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
                </li>
              </ul>
            </DeleteModal>
            <DeleteModal isOpen={this.state.deleteInvoice} toggle={() => this.setState({ deleteInvoice: false })} title={"Delete Invoice - '" + invoiceInfo.invoice_number + "'"} className="modal-tform-delete-pictures modal-dialog-centered">
              <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete ?</p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button type="button" className="btn btn-danger" onClick={() => { this.setState({ deleteInvoice: false }); this.props.deleteInvoice(invoiceInfo.id) }}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button type="button" className="btn btn-primary" onClick={() => this.setState({ deleteInvoice: false })}>No</button>
                </li>
              </ul>
            </DeleteModal>
            <DeleteModal isOpen={this.state.syncAgainFlag?.flag} toggle={() => this.setState({ syncAgainFlag: false })} title={" Sync to QB"} className="modal-tform-delete-pictures modal-dialog-centered">
              <p style={{ textAlign: 'center', }}>Are you sure you want to sync the invoice with Quickbooks?</p>
              <p style={{ textAlign: 'center', marginBottom: '25px' }}>Last synced by: {invoiceInfo?.qb_last_sync_by?.name || '--'} {' '}({invoiceInfo?.qb_last_sync_time ? moment(invoiceInfo?.qb_last_sync_time).format('MMM DD, YYYY') : '--'})</p>
              <ul className="list-inline">
                <MuiButton style={{ marginRight: '10px' }} variant='contained' size='small' color='primary' onClick={() => { this.setState({ syncAgainFlag: false }); this.getThirdPartyAppUrl(this.state.syncAgainFlag?.data) }}>
                  Proceed
                </MuiButton>
                <MuiButton color='primary' size='small' onClick={() => this.setState({ syncAgainFlag: false })}>
                  Cancel
                </MuiButton>
              </ul>
            </DeleteModal>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="invoice-status-modal">
              <ModalHeader toggle={this.toggle}>Change Invoice Status</ModalHeader>
              <ModalBody>
                {
                  this.state.modal ?
                    <ChangeInvoiceStatus
                      invoiceInfo={invoiceInfo}
                      status={invoiceInfo.status}
                      updateInvStatus={this.updateInvStatus}
                      invoiceType={invoiceInfo.invoice_type}
                      invoiceStartDate={startDate}
                      invoiceEndDate={endDate}
                      totalAmount={total}
                      diff={diff}
                    /> : null
                }
              </ModalBody>
            </Modal>
          </div> : <ListLoader />
        }
        <EditSidebar
          title="Share Invoice"
          editSideBar={shareInvoiceCrud.flag}
          toggleEditSideBar={() => this.props.closeShareSidebar({ data: {}, flag: false })}
        >
          {shareInvoiceCrud.flag ?
            <ShareInvoice
              shareInv={shareInvoiceCrud.data}
              shareInvoiceFn={this.props.updateShareInvFn}
              toggleEditSideBar={() => this.props.closeShareSidebar({ data: {}, flag: false })}
              invoiceLoader={invoiceLoader}
            /> : null
          }
        </EditSidebar>

        {
          showStatusTimeLine ?
            <Dialog
              maxWidth='md'
              style={{ height: '600px' }}
              open={true}
              onClose={() => this.setState({ showStatusTimeLine: !showStatusTimeLine })}
              aria-labelledby="scroll-dialog-title"
              className='invoice-timeline-modal'
            >
              <DialogTitle id="scroll-dialog-title">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h5>
                    Invoice Status Timeline
                  </h5>
                  <span style={{ cursor: 'pointer' }}>
                    <Close onClick={() => this.setState({ showStatusTimeLine: !showStatusTimeLine })} />
                  </span>
                </div>
              </DialogTitle>
              <DialogContent dividers={true} style={{ height: '600px' }}>
                <div>
                  <Timeline align="left" >
                    {this.state.statusTimeline.map((item, index) =>
                      <TimelineItem>
                        <TimelineOppositeContent > </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className={item.current_status} />
                          {
                            (statusTimeline.length - 1) > index ?
                              <>
                                <TimelineConnector />
                                <span className='arrow-corner-block-div' > {'>'}</span>
                              </>
                              : null}
                        </TimelineSeparator>
                        <TimelineContent>

                          <div style={{ display: "flex", alignItems: 'center' }}>
                            <span
                              className={item.current_status}
                              style={{ padding: '1px 20px', width: 'max-content' }}
                            >
                              {item.current_status}
                            </span>
                            {
                              item?.status_change_reason ?
                                <Tooltip style={{ maxWidth: '10px' }} title={`Reason: ${item?.status_change_reason ? item?.status_change_reason : '--'}`} arrow placement='right'>
                                  <img style={{ width: '13px', marginLeft: '4px', cursor: 'pointer' }} src={imgStoragePath + "inv_info_icon.png"} alt="info" />
                                </Tooltip>
                                : null
                            }
                          </div>
                          <div style={{ marginTop: '2px' }}> {item.user} ({item.date}) </div></TimelineContent>
                      </TimelineItem>
                    )}
                  </Timeline>
                </div>
              </DialogContent>
            </Dialog>
            : null
        }

      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader: state.sharedReducers.contractSummaryLoader,
  invoiceInfo: state.InvoiceReducer.invoiceView,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  invoiceLoader: state.InvoiceReducer.invoiceLoader,
  shareInvoiceCrud: state.InvoiceReducer.shareInvoiceCrud,
  invoiceExportLoader: state.InvoiceReducer.invoiceExportLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchAssetInfo: () => dispatch(InvoiceAssetViewAc(ownProps.params)),
    fetchInvoiceView: () => dispatch(invoiceViewAc(ownProps.params)),
    deleteInvoice: (invoiceId) => dispatch(deleteInvoiceViewAc(ownProps.params, invoiceId)),
    updateInvStatus: (data, type) => dispatch(changeInvoiceStatusAc(ownProps.params, data, ownProps.params.contract_slug, ownProps.params.id, type)),
    exportInvoice: (ctrShortInfo, invoiceNumber, fileType, invoiceInfo) => dispatch(detailInvExportAc(ownProps.params, ctrShortInfo, invoiceNumber, fileType, invoiceInfo)),
    getShareInvoiceInfo: () => dispatch(getShareInvoiceInfoAc(ownProps.params)),
    updateShareInvFn: (data) => dispatch(updateShareInvFnAc(ownProps.params, data)),
    closeShareSidebar: (data) => dispatch({
      type: INVOICE_SHARE,
      payload: data
    }),
    exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    // updateQbSyncState: () => dispatch({type: 'QB_SYNC_STATE',payload:'invoice_to_qb'})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ViewInvoiceForm, ['contracts', 'invoices', 'R']));
