import { globalGetService, globalPostService, globalDeleteService } from "../../../globalServices";
import { checkApiStatus, toastFlashMessage } from "../../../utils_v2";

export function getInvoiceAttachment(slug) {
  this.setState({ pageLoader: true })
  globalGetService(`/contracts/attachments/?contract_slug=${slug}`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (response?.data?.statusCode == 200) {
        this.setState({
          attachedDocList: response.data.list.data
        })
      } else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
}

export function addInvoiceAttachmentApi(data) {
  return new Promise(
    function (resolve, reject) {
      globalPostService(`contracts/attachments/`, data)
        .then(response => {
          resolve(response);
        })
    }
  )
}
export function deleteInvoiceAttachmentApi(data) {
  return new Promise(
    function (resolve, reject) {
      globalDeleteService(`contracts/attachments/${data.uuid}/`)
        .then(response => {
          resolve(response);
        })
    }
  )
}
export function getInvoiceStatusTimelineApi(props) {
  globalGetService(`contracts/${props?.params?.contract_slug}/invoices/${props?.params?.id}/invoice-transition/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ statusTimeline: response?.data?.data?.list })
      }
    })
}
export function getThirdPartyAppUrl(query) {
  this.setState({ loader: true })
  globalGetService(`contracts/app-plugin/quickbooks/authenticate/`, query)
    .then(response => {
      this.setState({ loader: false })
      if (checkApiStatus(response)) {
        window.open(response?.data?.data?.url, '_blank')
        this.setState({ thirdPartyAppUrl: response?.data?.data.url })
      }
    })
}
export function sendCredsToDb(query) {
  return new Promise(
    function (resolve, reject) {
      globalPostService(`contracts/app-plugin/quickbooks/access-refresh-token/`, query)
        .then(response => {
          resolve(response)
        })
    }
  )
}



export function getPartyInfo(slug) {
  this.setState({ pageLoader: true })
  globalGetService(`/contracts/${slug}/party/list/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (response?.data?.statusCode == 200) {
        this.setState({
          partyList: response?.data?.data
        })
      } else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
}
export function sendCredsFromDb(query) {
  return new Promise(
    function (resolve, reject) {
      globalPostService(`contracts/app-plugin/quickbooks/quickbooks-invoice-update/`, query)
        .then(response => {
          resolve(response)
        })
    }
  )
}
