import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { CtrTableHd, ContractSideBar, Header, ContractBarLoader } from '../../Elements';
import { invoiceViewAc, invoiceEditAc, InvoiceAssetViewAc } from '../actionCreators';
import { sExportContractsAc } from '../../../../shared/actionCreators';
import { UPDATE_INVOICE_FIELD, UPDATE_INVOICE_ITEM_FIELD, UPDATE_INSURANCE_INVOICE_ITEM_FIELD, ADD_INVOICE_ITEM_FIELD, UPDATE_PENALETY_INVOICE_FIELD, UPDATE_CHILDREN_ITEM_FIELD } from '../actions';
import { invoiceStatusDrpdwn, invoiceStatusList, fieldDateFormat, displayDateFormat, backendDateFormat, invoiceDescDrpdwn, invoiceDescList, imgStoragePath, assetsType, numberRegx } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { checkString, checkValidDate, checkIntNumber, checkNumber, checkFloat } from '../../../../formValidator';
import { toastFlashMessage, showCurrencyFormat, getLocalStorageInfo } from '../../../../utils';
import InvoiceInfo from '../components/InvoiceInfo'
import LessorInfo from '../components/LessorInfo'
import LesseeInfo from '../components/LesseeInfo'
import BankingInfo from '../components/BankingInfo'
import MaintenanceInvoiceItem from '../components/MaintenanceInvoiceItem'
import RentalInvoiceItem from '../components/RentalInvoiceItem'
import CreditDebitNote from '../components/CreditDebitNote'
import PenaltyInvoiceItem from '../components/PenaltyInvoiceItem'
import SecurityInvoiceItem from '../components/SecurityInvoiceItem'
import InsuranceInvoiceItem from '../components/InsuranceInvoiceItem'
import MRAdhocInvoiceItem from '../components/MRAdhocInvoiceItem'
import RentalAdhocInvoiceItem from '../components/RentalAdhocInvoiceItem'
import { contrHdlineInfoAc } from '../../HeadlinesInfo/actionCreators';

class EditInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      validationArray: [],
      validationChildArray: [],
      validationBankArray: [],
      delModal: false
    }
  }
  componentDidMount() {
    this.props.fetchContractInfo();
    this.props.fetchInvoiceView();
    this.props.fetchAssetInfo();
  }
  updateInvoice = (e) => {
    let validArray = [];
    let validBankArray = [];
    let validChildArray = [];
    e.preventDefault();
    // if(this.props.invoiceInfo.invoice_type==3){
    //   if(this.props.invoiceInfo.total_amount==0){
    //     toastFlashMessage('Invoice amount should be greater than 0','error');
    //     return;
    //   }
    // }
    let validateNewInput = {
      invoice_number: checkString({ value: this.props.invoiceInfo.invoice_number, required: true, minLength: '', maxLength: '', message: 'Please Enter Invoice Number' }),
      bank_name: checkString({ value: this.props.invoiceInfo.bank_name, required: true, minLength: '', maxLength: '', message: 'Please Enter Bank Name' }),
      bank_account_name: checkString({ value: this.props.invoiceInfo.bank_account_name, required: true, minLength: '', maxLength: '', message: 'Please Enter Bank Account Name' }),
      bank_account_number: checkString({ value: this.props.invoiceInfo.bank_account_number, required: true, minLength: '', maxLength: '', message: 'Please Enter Bank Account Number' }),
      date: checkValidDate({ value: this.props.invoiceInfo.date, required: true, minLength: '', maxLength: '', message: 'Please Enter Invoice Date' }),
      due_date: checkValidDate({ value: this.props.invoiceInfo.due_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Due Date' }),
      start_date: checkValidDate({ value: this.props.invoiceInfo.start_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Start Date' }),
      end_date: checkValidDate({ value: this.props.invoiceInfo.end_date, required: true, minLength: '', maxLength: '', message: 'Please Enter End Date' }),
    };
    if (this.props.invoiceInfo.partial_paid_amount && this.props.invoiceInfo.partial_paid_amount >= this.props.invoiceInfo.total_amount) {
      validateNewInput = {
        ...validateNewInput,
        partial_paid_amount: 'Please enter Partial Payment Amount less than Total Amount'
      }
    }
    if ((moment(this.props.invoiceInfo.start_date).diff(moment(this.props.invoiceInfo.end_date)) > 0)) {
      validateNewInput = {
        ...validateNewInput,
        start_date: 'Please Enter valid Start Date',
        end_date: 'Please Enter Valid End Date',
      }
    }
    if (this.props.invoiceInfo.invoice_type == 3) {
      validateNewInput = {
        ...validateNewInput,
        libor_value: checkFloat({ value: this.props.invoiceInfo.libor_value, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid  value' }),
        additional_rate: checkFloat({ value: this.props.invoiceInfo.additional_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid value' }),

      }
    } else if (this.props.invoiceInfo.invoice_type == 4 || this.props.invoiceInfo.invoice_type == 5) {
      validateNewInput = {
        ...validateNewInput,
        rate_per_unit: checkFloat({ value: parseFloat(this.props.invoiceInfo.invoice_items[0].rate_per_unit), required: true, minLength: '', maxLength: '', message: 'Please Enter Valid  value' }),
        tax_rate: checkFloat({ value: this.props.invoiceInfo.invoice_items[0].tax_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid value' }),
      }
    } else if (this.props.invoiceInfo.invoice_type == 1 || this.props.invoiceInfo.invoice_type == 2) {
      validArray = this.checkmap();
      if (this.props.invoiceInfo.invoice_type == 2 && this.props.invoiceInfo.children.length > 0) {
        validChildArray = this.checkChildMap();
        validBankArray = this.checkChildBankMap();
      }
    }
    let data = this.props.invoiceInfo
    data = {
      ...data, invoice_items: data.invoice_items.map(invoice => {
        let invItem = invoice
        if (invoice.sub_total === '' || invoice.sub_total === null) {
          invItem = { ...invItem, sub_total: 0 }
        }
        return invItem
      })
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if (this.props.invoiceInfo.invoice_type == 1 || this.props.invoiceInfo.invoice_type == 2) {
        if ((validArray.filter(item => !(item.rate_per_unit && item.tax_rate && item.no_of_unit && item.libor_value))).length == 0) {
          if (this.props.invoiceInfo.invoice_type == 2) {
            if ((validChildArray.filter(children => (children.filter(item => !(item.rate_per_unit && item.tax_rate && item.no_of_unit && item.libor_value))).length)).length == 0) {
              if ((validBankArray.filter(item => !(item.bank_name && item.bank_account_name && item.bank_account_number))).length == 0) {
                this.props.updateInvoice(this.props.params, data, this.props.assetViewId);
              } else {
                this.setState({
                  validationBankArray: validBankArray
                });
              }
            } else {
              if ((validBankArray.filter(item => !(item.bank_name && item.bank_account_name && item.bank_account_number))).length > 0) {
                this.setState({
                  validationBankArray: validBankArray
                });
              }
              this.setState({
                validationChildArray: validChildArray
              });
            }
          } else {
            this.props.updateInvoice(this.props.params, data, this.props.assetViewId);
          }
        } else {
          if (this.props.invoiceInfo.invoice_type == 2) {
            if ((validChildArray.filter(children => (children.filter(item => !(item.rate_per_unit && item.tax_rate && item.no_of_unit && item.libor_value))).length)).length > 0) {
              this.setState({
                validationChildArray: validChildArray
              });
            }
            if ((validBankArray.filter(item => !(item.bank_name && item.bank_account_name && item.bank_account_number))).length > 0) {
              this.setState({
                validationBankArray: validBankArray
              });
            }
          }
          this.setState({
            validationArray: validArray
          });
        }
      } else {
        this.props.updateInvoice(this.props.params, data, this.props.assetViewId);
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        error: validateNewInput
      }));
    }
  }
  checkmap = () => {
    let vaildArray = [];
    this.props.invoiceInfo.invoice_items.map((invoice_item, index) => {
      let obj = {};
      if (numberRegx.test(invoice_item.tax_rate)) {
        obj.tax_rate = true;
      } else {
        obj.tax_rate = false;
      }
      if (numberRegx.test(invoice_item.rate_per_unit)) {
        obj.rate_per_unit = true;
      } else {
        obj.rate_per_unit = false;
      }
      if (invoice_item.invoice_rental_type == '2' && invoice_item.is_additional_rate) {
        if (numberRegx.test(invoice_item.libor_value)) {
          obj.libor_value = true;
        } else {
          obj.libor_value = false;
        }
      } else {
        obj.libor_value = true;
      }
      if (numberRegx.test(invoice_item.no_of_unit)) {
        obj.no_of_unit = true;
      } else {
        obj.no_of_unit = false;
      }
      vaildArray.push(obj)
    })
    return vaildArray;
  }
  checkChildMap = () => {
    let vaildMainArray = [];
    let vaildArray = [];
    this.props.invoiceInfo.children.map((children, index) => {
      children.invoice_items.map((invoice_item, index) => {
        let obj = {};
        if (numberRegx.test(invoice_item.tax_rate)) {
          obj.tax_rate = true;
        } else {
          obj.tax_rate = false;
        }
        if (numberRegx.test(invoice_item.rate_per_unit)) {
          obj.rate_per_unit = true;
        } else {
          obj.rate_per_unit = false;
        }
        if (invoice_item.invoice_rental_type == '2' && invoice_item.is_additional_rate) {
          if (numberRegx.test(invoice_item.libor_value)) {
            obj.libor_value = true;
          } else {
            obj.libor_value = false;
          }
        } else {
          obj.libor_value = true;
        }
        if (numberRegx.test(invoice_item.no_of_unit)) {
          obj.no_of_unit = true;
        } else {
          obj.no_of_unit = false;
        }
        vaildArray.push(obj)
      })
      vaildMainArray.push(vaildArray)
    })
    return vaildMainArray;
  }
  checkChildBankMap = () => {
    let vaildArray = [];
    this.props.invoiceInfo.children.map((children, index) => {
      let obj = {};
      if ((checkString({ value: children.bank_name, required: true, minLength: '', maxLength: '', message: 'Please Enter Bank Name' })) == '') {
        obj.bank_name = true;
      } else {
        obj.bank_name = false;
      }
      if ((checkString({ value: children.bank_account_name, required: true, minLength: '', maxLength: '', message: 'Please Enter Bank Account Name' })) == '') {
        obj.bank_account_name = true;
      } else {
        obj.bank_account_name = false;
      }
      if ((checkString({ value: children.bank_account_number, required: true, minLength: '', maxLength: '', message: 'Please Enter Bank Account Number' })) == '') {
        obj.bank_account_number = true;
      } else {
        obj.bank_account_number = false;
      }
      vaildArray.push(obj)
    })
    return vaildArray;
  }
  dateValidation(paidDate, dueDate, type) {
    let errMsg = '';
    if ((moment(paidDate).diff(moment(dueDate)) < 0)) {
      errMsg = (type == 'penalty_due_date' ? 'Please Enter Valid Due Date' : 'Please Enter Valid Payment Date');
    } else if (type == 'paid_date') {
      errMsg = checkValidDate({ value: this.props.invoiceInfo[type], required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Payment Date' });
    } else if (type == 'parent_paid_date') {
      errMsg = checkValidDate({ value: this.props.invoiceInfo[type], required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Payment Date' });
    } else if (type == 'penalty_due_date') {
      errMsg = checkValidDate({ value: this.props.invoiceInfo[type], required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Due Date' });
    }
    if (errMsg.length) {
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          [type]: errMsg
        }
      }))
    }
  }
  toggleDelModal = () => {
    this.setState({
      delModal: !this.state.delModal,
    });
  }
  validationClear = (type) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }

  getHours = (units, unit_type, invoice_item_type) => {
    // let unit = units
    // if(invoice_item_type.toLowerCase() != 'apu' && unit_type.toLowerCase().includes('flight hour') && units.toString().indexOf('.') != -1 && parseInt(units.toString().substr(units.toString().indexOf('.') + 1)) > 0){
    //   if((parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60) < 10){
    //     if(units.toString().substr(units.toString().indexOf('.'), units.toString().length).length > 1){
    //       unit = parseFloat(units.toString().substr(0,units.toString().indexOf('.') + 1)) + parseFloat(Math.round(parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60) / 100)
    //     }else{
    //       unit = parseFloat(units.toString().substr(0,units.toString().indexOf('.') + 1)) + parseFloat(Math.round(parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60))
    //     }
    //   }else{
    //     unit =units.toString().substr(0,units.toString().indexOf('.') + 1) + Math.round(parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60)
    //   }
    // }
    return units.toString()
  }

  updateInvoiceItemField = (value, no_of_unit, index, invoice_item_type, unit_type) => {
    let units = value
    if (invoice_item_type.toLowerCase() != 'apu' && unit_type.toLowerCase().includes('flight hour') && units.toString().indexOf('.') != -1 && parseInt(units.toString().substr(units.toString().indexOf('.') + 1)) > 0) {
      let decimal = (parseFloat(units.toString().substr(units.toString().indexOf('.') + 1, units.toString().length)) / 60).toString()
      if (parseInt(units.toString().substr(units.toString().indexOf('.') + 1)) < 60) {
        if (getLocalStorageInfo().defaultLessor.id == 65) {
          units = parseFloat(units.toString().substr(0, units.toString().indexOf('.')) + decimal.substr(decimal.indexOf('.'))).toFixed(2)
        } else {
          units = parseFloat(units.toString().substr(0, units.toString().indexOf('.')) + decimal.substr(decimal.indexOf('.')))
        }
      } else {
        units = parseFloat(units.toString().substr(0, units.toString().indexOf('.'))) + parseFloat(decimal)
      }
    }
    this.props.updateInvoiceItemField(units, no_of_unit, index)
  }

  render() {
    const { invoiceInfo, ctrShortInfo } = this.props;
    const { error, validationArray, validationChildArray } = this.state;
    const total = invoiceInfo && invoiceInfo.invoice_items ? (invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(next.billable_amount !== '' ? next.billable_amount : 0), 0) + invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(next.tax_amount !== '' ? parseFloat(parseFloat(next.tax_amount).toFixed(2)) : 0), 0)) : 0
    const partialPaidAmount = invoiceInfo && invoiceInfo.partial_paid_amount ? parseFloat(invoiceInfo.partial_paid_amount) : 0
    const diff = total - partialPaidAmount
    let isJac= getLocalStorageInfo().defaultLessor.id === 442 ? true :false
    if (Object.keys(invoiceInfo).length) {
      if (invoiceInfo.status == '3' || invoiceInfo.status == '4') {
        return (
          <div className="mr-rate-wrapper">
            <ContractSideBar application={`Edit Invoice ${invoiceInfo.invoice_number}`} type="shorter" params={this.props.params} />
            <div className="text-center" style={{ padding: '200px 0' }}>
              <h4>Cannot Edit the Invoice</h4>
            </div>
          </div>
        )
      }
      return (
        <div className="mr-rate-wrapper mr-rate-spacing" >
          <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
          <ContractSideBar application={`Edit Invoice ${invoiceInfo.invoice_number}`} type="brief" params={this.props.params} />
          <div className="add-edit-invoice-form">
            <h5 className="para invoice-heading">
              <Link className="back-link" to={'/' + this.props.params.type + '/' + this.props.params.aircraft_slug + '/contract/' + this.props.params.contract_slug + '/invoice/view/' + this.props.params.id}>
                <img width="15" src={imgStoragePath + "back_arw.png"} alt="img" />
                <div className="inv-back-info">
                  <span>{invoiceDescList[invoiceInfo.invoice_type] + ' Invoice'}</span><br />
                  <h6>Invoice {'#' + invoiceInfo.invoice_number}</h6>
                </div>
              </Link>
            </h5>
            <form onSubmit={(e) => this.updateInvoice(e)} style={{ padding: '1px' }}>
              <InvoiceInfo
              contractInfo={this.props.contractInfo}
                type={'edit'}
                validationClear={(key) => this.validationClear(key)}
                updateInvoiceField={(value, key) => this.props.updateInvoiceField(value, key)}
                invoiceInfo={invoiceInfo} toggleInvoiceState={() => this.toggle()}
                invoiceStatusList={invoiceStatusList}
                startDate={ctrShortInfo && ctrShortInfo.contract ? ctrShortInfo.contract.contractual_start_date : null}
                endDate={ctrShortInfo && ctrShortInfo.contract ? ctrShortInfo.contract.contractual_end_date : null}
                error={error}
              />

              <Row className="margin-0 company-form-block">
                <LessorInfo
                  invoiceInfo={invoiceInfo}
                  type="edit"
                  updateInvoiceField={(value, key) => this.props.updateInvoiceField(value, key)}
                />
                <LesseeInfo
                  invoiceInfo={invoiceInfo}
                  type="edit"
                  updateInvoiceField={(value, key) => this.props.updateInvoiceField(value, key)}
                />
              </Row>
              {invoiceInfo.invoice_type == 2 && invoiceInfo.rental_description && invoiceInfo.rental_description != '' ?
                <Row className="margin-0 company-form-block">
                  <Col md={{ size: 12 }} className="padding-right-0">
                    <Row className="inner-wrap">
                      <Col md="12 " className="padding-left-0">
                        <div className="form-group">
                          <label className="label"> Description</label>
                          <input type="text" value={invoiceInfo.rental_description}
                            onChange={(e) => this.props.updateInvoiceField(e.target.value, 'rental_description')}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row> : null
              }

              <div className="charge-description-block ">
                {invoiceInfo.invoice_type === 9 ?
                  <div>
                    {invoiceInfo.invoice_type == 2 ? <h5 className="para currency-break">{invoiceInfo.currency} Rental Receivable</h5> : null}
                    <div className="table-block">
                      <table className="contr-table invoice-table-style">
                      {
                          isJac ?
                        <CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Sub Total (' + invoiceInfo.currency + ')']} />
                        :<CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                      }<tbody>
                          {invoiceInfo.invoice_items.map((invoice_item, index) => (
                            invoiceInfo.parent_invoice_type == 1 ?
                              <MRAdhocInvoiceItem
                              isJac={isJac}
                                key={index}
                                type="edit"
                                invoiceInfo={invoiceInfo}
                                invoice_item={invoice_item}
                                index={index}
                                validationArray={this.state.validationArray}
                                updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                                error={error}
                              />
                              : <RentalAdhocInvoiceItem
                              isJac={isJac}
                                key={index}
                                type="edit"
                                invoiceInfo={invoiceInfo}
                                invoice_item={invoice_item}
                                index={index}
                                validationArray={this.state.validationArray}
                                updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                                error={error}
                              />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="sub-total-block">
                      <h5 className="para-ui">Billable Amount:
                        <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                          {
                            invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(next.billable_amount !== '' ? next.billable_amount : 0), 0)
                          }
                        </NumericLabel>
                      </h5>
                      {
                        invoiceInfo.status == '5' ?
                          <h5 className="para-ui">Paid Amount :
                            <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                              {invoiceInfo.partial_paid_amount}
                            </NumericLabel>
                          </h5> : null
                      }
                        {
                         isJac? null :
                      <h5 className="para-ui">Tax:
                        <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                          {
                            invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(parseFloat(next.tax_amount).toFixed(2)), 0)
                          }
                        </NumericLabel>
                      </h5>}
                      <h4 className="para">Total :
                        <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                          {invoiceInfo.status == "5" ? diff : total}
                        </NumericLabel>
                      </h4>

                    </div>


                  </div>
                  : invoiceInfo.invoice_type == 1 || invoiceInfo.invoice_type == 2 ?
                    <div>
                      {invoiceInfo.invoice_type == 2 ? <h5 className="para currency-break">{invoiceInfo.currency} Rental Receivable</h5> : null}
                      <div className="table-block">
                        <table className="contr-table invoice-table-style">
                        {
                          isJac ?
                          <CtrTableHd theads={invoiceInfo.interval === 5 ? ['Item', 'Description', 'Unit Description', 'No. Of Days', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + invoiceInfo.currency + ')'] : ['Item', 'Description', 'Unit Description', 'No. Of Units', 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Sub Total (' + invoiceInfo.currency + ')']} />
                          :<CtrTableHd theads={invoiceInfo.interval === 5 ? ['Item', 'Description', 'Unit Description', 'No. Of Days', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + invoiceInfo.currency + ')'] : ['Item', 'Description', 'Unit Description', 'No. Of Units', 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />}
                          <tbody>
                            {invoiceInfo.invoice_items.map((invoice_item, index) => (
                              invoiceInfo.invoice_type == 1 ?
                                <MaintenanceInvoiceItem
                                isJac={isJac}
                                  key={index}
                                  type="edit"
                                  invoiceInfo={invoiceInfo}
                                  invoice_item={invoice_item}
                                  index={index}
                                  validationArray={this.state.validationArray}
                                  updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                                  error={error}
                                />
                                : <RentalInvoiceItem
                                isJac={isJac}
                                  key={index}
                                  type="edit"
                                  invoiceInfo={invoiceInfo}
                                  invoice_item={invoice_item}
                                  index={index}
                                  validationArray={this.state.validationArray}
                                  updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                                  error={error}
                                />
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="sub-total-block">
                        <h5 className="para-ui">Billable Amount:
                          <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                            {
                              invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(parseFloat(next.billable_amount !== '' ? next.billable_amount : 0).toFixed(2)), 0)
                            }
                          </NumericLabel>
                        </h5>
                        {
                          invoiceInfo.status == '5' ?
                            <h5 className="para-ui">Paid Amount :
                              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                                {invoiceInfo.partial_paid_amount}
                              </NumericLabel>
                            </h5> : null
                        }
                        {
                         isJac? null :
                        <h5 className="para-ui">Tax:
                          <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                            {
                              invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(parseFloat(next.tax_amount).toFixed(2)), 0)
                            }
                          </NumericLabel>
                        </h5>
                        }
                        <h4 className="para">Total:
                          <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                            {invoiceInfo.status == "5" ? diff : total}
                          </NumericLabel>
                        </h4>

                      </div>


                    </div>
                    : invoiceInfo.invoice_type == 4 || invoiceInfo.invoice_type == 5 ?
                      <div className="table-block">
                        <table className="contr-table invoice-table-style">
                        {
                          isJac ?
                          <CtrTableHd theads={['Description', 'Amount', 'Total (USD)']} />
                          :<CtrTableHd theads={['Description', 'Amount', 'Tax Rate (%)', 'Tax Amount', 'Total (USD)']} />}
                          <tbody>
                            <CreditDebitNote
                              isJac={isJac}
                              type="edit"
                              invoice_item={invoiceInfo.invoice_items[0]}
                              invoiceInfo={invoiceInfo}
                              error={this.state.error}
                              updateInvoiceItemField={(value, type) => this.props.updateInvoiceItemField(value, type, 0)}
                            />
                          </tbody>
                        </table>
                      </div> :
                      invoiceInfo.invoice_type === 6 ?
                        <div className="table-block">
                          <table className="contr-table invoice-table-style">
                            <CtrTableHd theads={['Item', 'Security Against', 'Security Deposit Type', 'Security Deposit Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                            <tbody>
                              <SecurityInvoiceItem
                                type="view"
                                invoice_item={invoiceInfo.invoice_items[0]}
                                invoiceInfo={invoiceInfo}
                                error={this.state.error}
                                updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                              />
                            </tbody>
                          </table>
                        </div> : invoiceInfo.invoice_type === 7 ?
                          <div className="table-block">
                            <table className="contr-table invoice-table-style">
                              <CtrTableHd theads={['Item', 'Description', 'Insurance Type', 'Insurance Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                              <tbody>
                                <InsuranceInvoiceItem
                                  type="edit"
                                  invoice_item={invoiceInfo.invoice_items[0]}
                                  invoiceInfo={invoiceInfo}
                                  updateInvoiceItemField={(value, type) => this.props.updateInsuranceInvoiceItemField(value, type, 0)}
                                />
                              </tbody>
                            </table>
                          </div> : invoiceInfo.invoice_type === 8 ?
                            <div className="table-block">
                              <table className="contr-table invoice-table-style">
                                <CtrTableHd theads={['Item', 'Description', 'Insurance Type', 'Insurance Amount', 'Sub Total (' + invoiceInfo.currency + ')']} />
                                <tbody>
                                  <InsuranceInvoiceItem
                                    type="view"
                                    invoice_item={invoiceInfo.invoice_items[0]}
                                    invoiceInfo={invoiceInfo}
                                  />
                                </tbody>
                              </table>
                            </div>
                            : <div className="table-block">
                              <table className="contr-table invoice-table-style">
                                <CtrTableHd theads={['Over Due Amount', 'Description', 'Due Date', 'Payment Date', 'No Of Days over due', '6 Months USD LIBOR (%) (' + (invoiceInfo.libor_date ? moment(invoiceInfo.libor_date).format(displayDateFormat) : moment().format(displayDateFormat)) + ')', 'Additional Rate(%)', 'Total Penal Interest Rate', 'Interest On Over Due Amount']} />
                                <tbody>
                                  <PenaltyInvoiceItem
                                    type="edit"
                                    error={this.state.error}
                                    invoiceInfo={invoiceInfo}
                                    dateValidation={(date, newDate, field) => this.dateValidation(date, newDate, field)}
                                    validationClear={(key) => this.validationClear(key)}
                                    updateInvoicePenaltyField={(value, type) => this.props.updateInvoicePenaltyField(value, type)}
                                    updateInvoiceField={(value, type) => this.props.updateInvoiceField(value, type)}
                                  />
                                </tbody>
                              </table>
                            </div>
                }
              </div>
              <h5 className="para invoice-heading">Bank Detail {invoiceInfo.invoice_type == 2 ? 'For ' + invoiceInfo.currency + ' Rental Receivable' : ''}</h5>
              <BankingInfo
                type="edit" 
                invoiceInfo={invoiceInfo}
                error={this.state.error}
                updateInvoiceField={(value, key) => this.props.updateInvoiceField(value, key)}
              />
              {invoiceInfo.invoice_type == 2 && invoiceInfo.children ?
                <div>
                  {invoiceInfo.children.length > 0 ?
                    invoiceInfo.children.map((children, childIndex) =>
                      <div>
                        <div className="charge-description-block ">
                          <div className="table-block">
                            <h5 className="para currency-break">{children.currency} Rental Receivable</h5>
                            <table className="contr-table invoice-table-style">
                            {
                          isJac ?<CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Sub Total (' + children.currency + ')']} />
                              :<CtrTableHd theads={['Item', 'Description', 'Unit Description', 'No. Of Units', invoiceInfo.rental_type === 1 ? 'Fixed Rate (' + invoiceInfo.currency + ')' : 'Fixed / Per Unit Rate (' + invoiceInfo.currency + ')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total (' + children.currency + ')']} />}
                              <tbody>
                                {children.invoice_items.map((invoice_item, index) => (
                                  <RentalInvoiceItem
                                  isJac={isJac}
                                    key={index}
                                    type="edit"
                                    invoiceInfo={invoiceInfo}
                                    invoice_item={invoice_item}
                                    index={index}
                                    validationArray={this.state.validationArray}
                                    multiCurrency={true}
                                    updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                                    updateChildrenItemField={(value, type) => this.props.updateChildrenItemField(value, type, index, childIndex)}
                                  />
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="sub-total-block">
                            <h5 className="para-ui">Billable Amount:
                              <NumericLabel params={showCurrencyFormat(children.currency)}>
                                {
                                  children.invoice_items.reduce((total, next) => total + parseFloat(next.billable_amount !== '' ? next.billable_amount : 0), 0)
                                }
                              </NumericLabel>
                            </h5>
                            <h5 className="para-ui">Paid Amount:
                              <NumericLabel params={showCurrencyFormat(children.currency)}>
                                {children.partial_paid_amount}
                              </NumericLabel>
                            </h5>
                            {
                         isJac? null :
                            <h5 className="para-ui">Tax:
                              <NumericLabel params={showCurrencyFormat(children.currency)}>
                                {
                                  children.invoice_items.reduce((total, next) => total + parseFloat(parseFloat(next.tax_amount).toFixed(2)), 0)
                                }
                              </NumericLabel>
                            </h5> }
                            <h4 className="para">Total:
                              <NumericLabel params={showCurrencyFormat(children.currency)}>
                                {
                                  children.invoice_items.reduce((total, next) => total + parseFloat(parseFloat(next.sub_total).toFixed(2)), 0)
                                }
                              </NumericLabel>
                            </h4>
                          </div>
                        </div>
                        <h5 className="para invoice-heading">Bank Detail {invoiceInfo.invoice_type == 2 ? 'For ' + children.currency + ' Rental Receivable' : ''}</h5>
                        <BankingInfo
                          type="edit"
                          invoiceInfo={children}
                          error={this.state.error}
                          updateInvoiceField={(value, key) => this.props.updateChildrenItemField(value, key, 0, childIndex)}
                        />
                      </div>
                    ) : null
                  }
                </div> : null
              }
              <div className="submit-block">
                <input type="submit" className="primary-btn" value="Save Changes" />
                <Link to={'/' + this.props.params.type + '/' + this.props.params.aircraft_slug + '/contract/' + this.props.params.contract_slug + '/invoice/view/' + this.props.params.id} className="edit-icon edit-icon-bar">Cancel</Link>
              </div>
            </form>
          </div>
        </div>
      )
    } else {
      return <h5 className="text-center">Loading...</h5>
    }

  }
}

const mapStateToProps = state => ({
  invoiceInfo: state.InvoiceReducer.invoiceView,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  assetViewId: state.userAssetReducer.assetViewId,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchInvoiceView: () => dispatch(invoiceViewAc(ownProps.params)),
    fetchAssetInfo: () => dispatch(InvoiceAssetViewAc(ownProps.params)),
    updateInvoiceItemField: (value, type, index) => dispatch({
      type: UPDATE_INVOICE_ITEM_FIELD,
      payload: { value, type, index }
    }),
    updateInsuranceInvoiceItemField: (value, type, index) => dispatch({
      type: UPDATE_INSURANCE_INVOICE_ITEM_FIELD,
      payload: { value, type, index }
    }),
    updateChildrenItemField: (value, type, index, childIndex) => dispatch({
      type: UPDATE_CHILDREN_ITEM_FIELD,
      payload: { value, type, index, childIndex }
    }),
    updateInvoiceField: (value, type) => dispatch({
      type: UPDATE_INVOICE_FIELD,
      payload: { value, type }
    }),
    updateInvoicePenaltyField: (value, type) => dispatch({
      type: UPDATE_PENALETY_INVOICE_FIELD,
      payload: { value, type }
    }),
    updateInvoice: (params, data, assetViewId) => dispatch(invoiceEditAc(params, data, assetViewId)),
    addInvoiceItem: () => dispatch({
      type: ADD_INVOICE_ITEM_FIELD,
      payload: {}
    }),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(EditInvoice, ['contracts', 'invoices', 'U']));
