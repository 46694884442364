
import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import DeliveryCondCRU from './DeliveryCondCRU';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
export default function DeliveryCndList({item, previewDetail, getResponseBack, contractClauseList, contractInfo,deleteDeliveryCond}){
  const [showMore, setShowMore] = useState({});
  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell style={{maxWidth:'150px'}}>{item.asset_conditions_for_contract.name}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>
      {item?.terms && item?.terms?.length > 200 ? (
          <div style={{ maxWidth: '400px' }}>
            {showMore[item?.id] && showMore[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.replace(/\n/g, '<br />') }} />
          </div>
        )}  
      </TableCell>
      <TableCell>{item.actual ? ( <span dangerouslySetInnerHTML={{ __html: item.exceptions_done.replace(/\n/g, '<br />')}}/>):'--'}</TableCell>
      <TableCell>{item.exceptions_done ? ( <span dangerouslySetInnerHTML={{ __html: item.exceptions_done.replace(/\n/g, '<br />')}}/>):'--'}</TableCell>
      <TableCell>
        <DeliveryCondCRU contractInfo={contractInfo} id={item.id} contractClauseList={contractClauseList} getResponseBack={getResponseBack} m="V" />
        {checkPermission('contracts','delivery_conditions', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteDeliveryCond(item.id)}/>: null}
        </TableCell>
    </TableRow>
  )
}
