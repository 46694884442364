import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { checkApiStatus, capitalizeFirstLetter, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { addEditLeaseOptionApi, getLeaseOptionApi } from '../apiServices';
import { errorCode, addLeaseOption } from '../';
class LeaseOptionCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader: false,
      mode:'',
      leaseOption:{},
      error:{}
    }
    this.addEditLeaseOptionApi = addEditLeaseOptionApi.bind(this);
    this.getLeaseOptionApi = getLeaseOptionApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      leaseOption: {
        ...prevState.leaseOption,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditLeaseOpFn = () => {
    const { leaseOption } = this.state;
    let validationInputs = {
      lease_option:errorCode['lease_option'][fieldValidation({...errorCode['lease_optionObj'], fieldval: leaseOption.lease_option })],
      details:errorCode['details'][fieldValidation({...errorCode['detailsObj'], fieldval: leaseOption.details})],
    };
    if(leaseOption.validity_start_date && leaseOption.validity_end_date){
      validationInputs = {
        ...validationInputs,
        validity_start_date: dateRangeValidation(leaseOption.validity_start_date, leaseOption.validity_end_date, 1)
      }
    }
    if(leaseOption.notice_period_start_date && leaseOption.notice_period_start_date){
      validationInputs = {
        ...validationInputs,
        notice_period_start_date: dateRangeValidation(leaseOption.notice_period_start_date, leaseOption.notice_period_end_date, 1)
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      let payload = Object.assign({}, leaseOption);
      payload = {
        ...payload,
        lease_option: leaseOption.lease_option.value
      }
      this.addEditLeaseOptionApi(this.props,payload)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', leaseOption:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error:validationInputs});
    }
  }
  render() {
    const { modal, mode, formSubmitLoader, leaseOption, error, pageLoader } = this.state;
    const { id, m, pltConstants, contractInfo } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        { id ?
          <span onClick={() => this.getLeaseOptionApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>:
          contractInfo?.hdlineInfo?.archived_asset ?
          null:
          <Button onClick={() => this.setState({modal:true, leaseOption:addLeaseOption, mode:'add'})} variant="contained" color="primary"><AddIcon fontSize='small'/>Add EOL Option</Button>
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', leaseOption:{}, error:{}})}
          >
          <div style={{width:'670px'}} className="drawer-container">
            <div className="drawer-header" alignItems='center'>
              <span className="left-blk">{`${capitalizeFirstLetter(mode)} Lease Option`}</span>
              <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
              { mode !== 'add' ?
                <ul className="list-inline right-blk">
                  <li className="list-inline-item">
                    <RevisionHistory
                      url='audit/contracts/ctleaseoptions/'
                      queryParams={{action:1, object_id:id}}
                      buttonType={false}
                    />
                  </li>
                  <li className="list-inline-item">
                    { contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts','eol_options', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>}
                    {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                  </li>
                </ul>:null
              }
            </div>
            <div className="drawer-body">
              <Grid container spacing={3}>
                { mode == 'view' ?
                  <LabelValueCard md={6} xs={12} label='Lease Option' value={leaseOption.lease_option && leaseOption.lease_option.label ? leaseOption.lease_option.label:'--'} /> :
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      options = {pltConstants.filter(item => item.type === 'lease_option_type')}
                      getOptionLabel={option => option.label}
                      value={leaseOption.lease_option ? leaseOption.lease_option:null}
                      id="lease_option"
                      onChange={(e, value) => {this.onFieldChange(e, 'lease_option', value); }}
                      renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('lease_option', '')} error={error.lease_option ? true:false} helperText={error.lease_option?error.lease_option:''} {...params} label="Lease Option" placeholder="Select Lease Option" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                }
                { mode == 'view' ?
                  <LabelValueCard md={6} xs={12} label='Validity	Start Date' value={leaseOption.validity_start_date ? moment(leaseOption.validity_start_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="validity_start_date"
                         label="Validity	Start Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         error={error.validity_start_date ? true:false }
                         helperText={error.validity_start_date ? error.validity_start_date: ''}
                         value={leaseOption.validity_start_date ? leaseOption.validity_start_date:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'validity_start_date', data?moment(data).format(backendDateFormat):data);this.updateErrorField('validity_start_date', '')}}
                       />
                      </MuiPickersUtilsProvider>
                  </Grid>
                }
                { mode == 'view' ?
                  <LabelValueCard md={6} xs={12} label='Validity End Date' value={leaseOption.validity_end_date ? moment(leaseOption.validity_end_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="validity_end_date"
                         label="Validity End Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         value={leaseOption.validity_end_date ? leaseOption.validity_end_date:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'validity_end_date', data ? moment(data).format(backendDateFormat):data);this.updateErrorField('validity_start_date', '')}}
                       />
                      </MuiPickersUtilsProvider>
                  </Grid>
                }
                { mode == 'view' ?
                  <LabelValueCard md={6} xs={12} label='Notice Period Start Date' value={leaseOption.notice_period_start_date ? moment(leaseOption.notice_period_start_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="notice_period_start_date"
                         label="Notice Period Start Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         error={error.notice_period_start_date ? true:false }
                         helperText={error.notice_period_start_date ? error.notice_period_start_date: ''}
                         value={leaseOption.notice_period_start_date ? leaseOption.notice_period_start_date:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'notice_period_start_date', data ? moment(data).format(backendDateFormat):data);this.updateErrorField('notice_period_start_date', '')}}
                       />
                      </MuiPickersUtilsProvider>
                  </Grid>
                }
                { mode == 'view' ?
                  <LabelValueCard md={6} label='Notice Period End Date' value={leaseOption.notice_period_end_date ? moment(leaseOption.notice_period_end_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="start_date"
                         label="Notice Period End Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         value={leaseOption.notice_period_end_date ? leaseOption.notice_period_end_date: null}
                         onChange={(data, value) => {this.onFieldChange(value, 'notice_period_end_date', data ? moment(data).format(backendDateFormat):data);this.updateErrorField('notice_period_start_date', '')}}
                       />
                      </MuiPickersUtilsProvider>
                  </Grid>
                }
                { mode == 'view' ?
                  <LabelValueCard md={12} label='Details' value={leaseOption.details ? ( <span dangerouslySetInnerHTML={{ __html: leaseOption.details.replace(/\n/g, '<br />')}}/>):'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="details"
                      label="Details"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="6"
                      value={leaseOption.details ? leaseOption.details:''}
                      InputLabelProps={{shrink: true}}
                      error={error.details ? true:false }
                      helperText={error.details ? error.details: ''}
                      onChange={(e) => this.onFieldChange(e, 'details', e.target.value)}
                      onFocus={(e) => this.updateErrorField('details', '')}
                    />
                  </Grid>
                }
                { mode == 'view' ?
                  <LabelValueCard md={12} label='Remarks' value={leaseOption.remarks ? ( <span dangerouslySetInnerHTML={{ __html: leaseOption.remarks.replace(/\n/g, '<br />')}}/>):'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      id="remarks"
                      label="Remarks"
                      fullWidth
                      margin="normal"
                      multiline
                      onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                      rows="6"
                      value={leaseOption.remarks ? leaseOption.remarks:''}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid>
                }
              </Grid>
            </div>
            <div className="drawer-footer">
              { mode !== 'view' ?
                <Button onClick={this.addEditLeaseOpFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                </Button>:null
              }
              <Button onClick={() => this.setState({modal:false, mode:'', leaseOption:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
            </div>
          </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(LeaseOptionCRU));
