import { globalGetService } from "../../../globalServices";
import checkApiStatus, { getLocalStorageInfo } from "../../../utils";

export function getAccountTypeApi() {
    globalGetService(`console/get-constants/?constant_type=reserve_account_type`)
        .then(response => {
            if (response.data.statusCode === 200) {
                let info = getLocalStorageInfo()
                if(info.defaultLessor.id == 442 || info.defaultLessor.id == 463){
                    
                    this.setState({
                        accType: response.data.data
                    })
                }
                else{
                    this.setState({
                        accType: response.data.data.filter(i=> i.value !== 4)
                    })
                }
            }
        })
}
export function getEngineThurstApi (enignetype) {
        globalGetService(`console/engine-types/`,{engine_family_engine_type:enignetype, for_thrust:true})
            .then(response => {
                if (checkApiStatus(response)) {
                    let filteredResp = response.data.data?.engineTypes.map(item=> ({engine_thrust_name:item?.engine_thrust_name , id: item?.id, name:item?.name}))
                    this.setState({engineTypes:filteredResp})
                }
            })
}