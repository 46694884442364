import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { addEditSubleaseApi, getSubleaseApi } from '../apiServices';
import { addSubLease, errorCode } from '../';
class SubleaseCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader:false,
      mode:'',
      sublease:{},
      error:{}
    }
    this.addEditSubleaseApi = addEditSubleaseApi.bind(this);
    this.getSubleaseApi = getSubleaseApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      sublease: {
        ...prevState.sublease,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditSubleaseFn = () => {
    const { sublease } = this.state;
    let validationInputs = {
      sublease_lessee:errorCode['sublease_lessee'][fieldValidation({...errorCode['sublease_lesseeObj'], fieldval: sublease.sublease_lessee })],
    };
    if(sublease.start_date && sublease.end_date){
      validationInputs = {
        ...validationInputs,
        start_date: dateRangeValidation(sublease.start_date, sublease.end_date, 30)
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.addEditSubleaseApi(this.props, sublease)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false,mode:'', sublease:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { modal, formSubmitLoader, mode, sublease, error, pageLoader } = this.state;
    const { id, m, contractInfo } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        { id ?
          <span onClick={() => this.getSubleaseApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>:
          contractInfo?.hdlineInfo?.archived_asset == false?
          <Button onClick={() => this.setState({modal:true, sublease:addSubLease, mode:'add'})} variant="contained" color="primary"><AddIcon fontSize='small'/>Add Sublease</Button>
          : null
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', sublease:{}, error:{}})}
          >
          <div style={{width:'670px'}} className="drawer-container">
            <div className="drawer-header" alignItems='center'>
              <span className="left-blk">{`${capitalizeFirstLetter(mode)} Sublease`}</span>
              <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
              { mode !== 'add' ?
                <ul className="list-inline right-blk">
                  <li className="list-inline-item">
                    <RevisionHistory
                      url='audit/contracts/ctsublease/'
                      queryParams={{action:1, object_id:id}}
                      buttonType={false}
                    />
                  </li>
                  <li className="list-inline-item">
                    {     contractInfo?.hdlineInfo?.archived_asset == false? checkPermission('contracts','sublease', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>: false}
                    {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                  </li>
                </ul>:null
              }
            </div>
            <div className="drawer-body">
              <Grid container spacing={3}>
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Name' value={sublease.sublease_lessee ? sublease.sublease_lessee:'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="sublease_lessee"
                      label="Name"
                      fullWidth
                      margin="normal"
                      value={sublease.sublease_lessee?sublease.sublease_lessee:''}
                      InputLabelProps={{shrink: true}}
                      error={error.sublease_lessee ? true:false }
                      helperText={error.sublease_lessee ? error.sublease_lessee: ''}
                      onChange={(e) => this.onFieldChange(e, 'sublease_lessee', e.target.value)}
                      onFocus={(e) => this.updateErrorField('sublease_lessee', '')}
                    />
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={12} label='Details' value={sublease.details ? ( <span dangerouslySetInnerHTML={{ __html: sublease.details.replace(/\n/g, '<br />')}}/>):'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      id="details"
                      label="Details"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      // inputProps={{ maxLength: 255 }}
                      value={sublease.details?sublease.details:''}
                      onChange={(e, value) => this.onFieldChange(e, 'details', e.target.value)}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={6} label='Start Date' value={sublease.start_date ? moment(sublease.start_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="start_date"
                         label="Start Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         value={sublease.start_date ? moment(sublease.start_date):null}
                         onChange={(data, value) => this.onFieldChange(value, 'start_date', moment(data).format(backendDateFormat))}
                         error={error.start_date ? true:false }
                         helperText={error.start_date ? error.start_date: ''}
                       />
                      </MuiPickersUtilsProvider>
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={6} label='End Date' value={sublease.end_date ? moment(sublease.end_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="end_date"
                         label="End Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         value={sublease.end_date ? moment(sublease.end_date):null}
                         onChange={(data, value) => this.onFieldChange(value, 'end_date', moment(data).format(backendDateFormat))}
                       />
                      </MuiPickersUtilsProvider>
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={12} label='Remarks' value={sublease.remarks ? ( <span dangerouslySetInnerHTML={{ __html: sublease.remarks.replace(/\n/g, '<br />')}}/>):'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      id="remarks"
                      label="Remarks"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      // inputProps={{ maxLength: 255 }}
                      value={sublease.remarks ? sublease.remarks:''}
                      onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid>
                }
              </Grid>
            </div>
            <div className="drawer-footer">
              { mode !== 'view' ?
                <Button onClick={this.addEditSubleaseFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                </Button>:null
              }
              <Button onClick={() => this.setState({modal:false, mode:'', sublease:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
            </div>
          </div>
        </Drawer>:null
      }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(SubleaseCRU));
