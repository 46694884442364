import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import HistoryIcon from '@material-ui/icons/History';
import InductionCondCRU from './InductionCondCRU';
export default function InductionCndList({item, previewDetail, contractClauseList, getResponseBack, contractInfo,deleteInductionCond}){
  const [showMore, setShowMore] = useState({});
  const [more, setMore] = useState({});

  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  const toggleRemark = (id) => {
    setMore({
      ...more,
      [id]: !more[id],
    });
  };
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell style={{maxWidth:'150px'}}>{item.asset_conditions_for_contract.name}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>
      {item?.terms && item?.terms?.length > 200 ? (
          <div style={{ maxWidth: '400px' }}>
            {showMore[item?.id] && showMore[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.replace(/\n/g, '<br />') }} />
          </div>
        )}
      </TableCell>
      <TableCell style={{maxWidth:'200px'}}>
      {item?.remarks && item?.remarks?.length > 200 ? (
          <div style={{ maxWidth: '400px' }}>
            {more[item?.id] && more[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.remarks.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleRemark(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.remarks.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleRemark(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.remarks.replace(/\n/g, '<br />') }} />
          </div>
        )}
      </TableCell>
      <TableCell>
        <InductionCondCRU contractInfo={contractInfo} id={item.id} contractClauseList={contractClauseList} getResponseBack={getResponseBack} m="V" />
        {checkPermission('contracts','induction_conditions', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteInductionCond(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
