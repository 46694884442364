import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { checkApiStatus } from '../../../utils_v2';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { monthsObj } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { getInvoiceInitiateApi, genInvoiceStrApi } from '../apiServices';
class GenerateInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      error:{},
      formSubmitLoader:false,
      genInvoiceCrud:{}, // invoiceType, bank_details_id, selectedDate, security_deposit_type
      dates:[],
      bankList:[],
      securityDepositOptions:[],
      insurancesOptions:[],
      claimOptions:[]
    }
    this.getInvoiceInitiateApi = getInvoiceInitiateApi.bind(this);
    this.genInvoiceStrApi = getInvoiceInitiateApi.bind(this);
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      genInvoiceCrud:{
        ...prevState.genInvoiceCrud,
        [keyParam]:value
      }
    }));
    if(keyParam === 'invoiceType'){
      this.getInvoiceInitiateApi(this.props, {invoice_type:value.value});
    }
  }
  updateErrorField = (key) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:''
      }
    }))
  }
  openGenInvoice = () => {
    this.setState({modal:true});
    this.props.getPlatformConstants()
  }
  genInvoiceStr = () => {
    // { id:'', invoice_type:'', bank_details_id:''}
    // { month:'', invoice_type:'', bank_details_id:''}
    
    let invoiceStr = {}
    const { genInvoiceCrud } = this.state;
    if(genInvoiceCrud.invoiceType === 9){
      invoiceStr = { id:'', invoice_type:'', bank_details_id:''}
    }else if ([7,8].includes(genInvoiceCrud.invoiceType)) {
      invoiceStr = { id:'', invoice_type:'', bank_details_id:''}
    }else{
      invoiceStr = { id:'', invoice_type:'', bank_details_id:''}
    }
    this.genInvoiceStrApi(this.props,invoiceStr);
  }
  render(){
    const { modal, formSubmitLoader, error, genInvoiceCrud, dates, claimOptions, insurancesOptions, securityDepositOptions, bankList } = this.state;
    const { pltConstants } = this.props;
    return(
      <Fragment>
        <Button onClick={() => this.openGenInvoice('Add')} variant="contained" color="primary">Generate Invoice</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, mode:'', rentalUtilsCrud:{}, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              Generate Invoice
            </DialogTitle>
            <DialogContent dividers={true}>
              <div style={{minWidth:'420px'}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      options={[{label: 'Reserve Invoice', value: 1}, {label: 'Rental Invoice', value: 2}, {label: 'Penalty Invoice', value: 3},{label: 'Debit Note Invoice', value: 4},{label: 'Credit Note Invoice', value: 5},{label: 'Security Deposit Invoice', value: 6},{label: 'Insurance Invoice', value: 7},{label: 'Insurance Claim Invoice', value: 8}, {label: 'Ad Hoc Invoice', value: 9}]}
                      getOptionLabel={option => option.label}
                      value={genInvoiceCrud.invoiceType}
                      id="invoiceType"
                      onChange={(e, value) => this.onFieldChange(e, 'invoiceType', value)}
                      renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('invoiceType')} error={error.invoiceType ? true:false} helperText={error.invoiceType?error.invoiceType:''} {...params} label="Invoice Type" placeholder="Select Invoice Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  { genInvoiceCrud.invoiceType && genInvoiceCrud.invoiceType.value  ?
                    <Fragment>
                      { genInvoiceCrud.invoiceType.value !== 6 ?
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            options = {dates}
                            getOptionLabel={option => option.label}
                            value={genInvoiceCrud.selectedDate}
                            id="selectedDate"
                            onChange={(e, value) => this.onFieldChange(e, 'selectedDate', value)}
                            renderInput={params =>
                              <TextField
                                required
                                onFocus={(e) => this.updateErrorField('selectedDate')}
                                error={error.selectedDate ? true:false}
                                helperText={error.selectedDate?error.selectedDate:''}
                                {...params}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                label={ genInvoiceCrud.invoiceType.value === 1 ? 'Utilization month to be Invoiced': genInvoiceCrud.invoiceType.value === 2 ? 'Rental month to be Invoiced':'Invoice Number'}
                                placeholder={genInvoiceCrud.invoiceType && [1,2].includes(genInvoiceCrud.invoiceType) ? 'Select Month':'Select Invoice Number' }
                              />
                            }
                          />
                        </Grid>:null
                      }
                      { genInvoiceCrud.invoiceType && genInvoiceCrud.invoiceType.value === 6 ?
                        <Fragment>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              options = {pltConstants.filter(item => item.type === 'security_deposit_type')}
                              getOptionLabel={option => option.label}
                              value={genInvoiceCrud.security_deposit_type}
                              id="security_deposit_type"
                              onChange={(e, value) => {this.onFieldChange(e, 'security_deposit_type', value); }}
                              renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('security_deposit_type')} error={error.security_deposit_type ? true:false} helperText={error.security_deposit_type?error.security_deposit_type:''} {...params} label="Security Deposit Type" placeholder="Select Security Deposit Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              options = {securityDepositOptions}
                              getOptionLabel={option => option.label}
                              value={genInvoiceCrud.selectedDate}
                              id="selectedDate"
                              onChange={(e, value) => {this.onFieldChange(e, 'selectedDate', value); }}
                              renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('selectedDate')} error={error.selectedDate ? true:false} helperText={error.selectedDate?error.selectedDate:''} {...params} label="Security Deposit Against (Amount)" placeholder="Select Security Deposit Against" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                            />
                          </Grid>
                        </Fragment>:null
                      }
                      { genInvoiceCrud.invoiceType && genInvoiceCrud.invoiceType.value === 7 ?
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            options = {insurancesOptions}
                            getOptionLabel={option => option.type}
                            value={genInvoiceCrud.selectedDate}
                            id="selectedDate"
                            onChange={(e, value) => this.onFieldChange(e, 'selectedDate', value)}
                            renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('selectedDate')} error={error.selectedDate ? true:false} helperText={error.selectedDate?error.selectedDate:''} {...params} label="Insurances" placeholder="Select Insurances" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                          />
                        </Grid>:null
                      }
                      { genInvoiceCrud.invoiceType && genInvoiceCrud.invoiceType.value === 8 ?
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            options = {claimOptions}
                            getOptionLabel={option => option.type}
                            value={genInvoiceCrud.selectedDate}
                            id="selectedDate"
                            onChange={(e, value) => this.onFieldChange(e, 'selectedDate', value)}
                            renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('selectedDate')} error={error.selectedDate ? true:false} helperText={error.selectedDate?error.selectedDate:''} {...params} label="Insurance Claims" placeholder="Select Insurance Claims" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                          />
                        </Grid>:null
                      }
                      <Grid item xs={12} md={12}>
                        <Autocomplete
                          options = {bankList}
                          getOptionLabel={option => option.name}
                          value={genInvoiceCrud.bank_details_id}
                          id="bank_details_id"
                          onChange={(e, value) => this.onFieldChange(e, 'bank_details_id', value)}
                          renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('bank_details_id')} error={error.bank_details_id ? true:false} helperText={error.bank_details_id?error.bank_details_id:''} {...params} label="Bank" placeholder="Select Bank" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                        />
                      </Grid>
                    </Fragment>:null
                  }
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false, error:{}})} color="primary">Cancel</Button>
              <Button onClick={this.genInvoiceStr} variant="contained" color="primary" disabled={formSubmitLoader}>
                { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Proceed' }
              </Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:["security_deposit_type"]})),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateInvoice));
