import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core'
import { globalGetService } from '../../../../globalServices'
import { Autocomplete } from '@material-ui/lab'
import { checkApiStatus } from '../../../../utils_v2'
import { useSnackbar } from 'notistack'
import { EmptyCollection, TableListComp } from '../../../../shared_elements'
import DeleteIcon from '@material-ui/icons/Delete';
import { regexConstants } from '../../../../constants/regEx'
import { ListLoader } from '../../../../shared'

export default function LLpSplit({ onClose, saveLLpSplit, data , lLpSplit}) {
    const emptyObject = {
        engine_thrust_type: '',
        cycles: '',
        is_current_thrust: false
    }
    const llpSplitHd = [
        { id: 'engine_thrust_type', label:<div>Engine Thrust Type <sup className="reqStar"> * </sup> </div>, sortOption: false },
        { id: 'hours', label:<div>Hours <sup className="reqStar"> * </sup> </div>, sortOption: false },
        { id: 'cycles', label:<div>Cycles <sup className="reqStar"> * </sup> </div>, sortOption: false },
        { id: 'is_current_thrust', label: 'Current Thrust', sortOption: false },
        { id: 'action', label: 'Action', sortOption: false, actionCell: true },
    ]
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [engineType, setEngineTypes] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [llpRowData, setLLpRowData] = useState(data?.thrust_split?.length ?data?.thrust_split: [{...emptyObject,is_current_thrust:true}])
    useEffect(() => {
        getEngineThurst()
    }, [])
    const getEngineThurst = () => {
        setLoading(true)
        globalGetService(`console/engine-types/`,{engine_family_engine_type:data?.engine_type_id, for_thrust:true})
        .then(response => {
            setLoading(false)
            if (checkApiStatus) {
                let filteredResp = response.data.data?.engineTypes.map(item=> ({engine_thrust_name:item?.engine_thrust_name , id: item?.id, name:item?.name}))
                setEngineTypes(filteredResp)
                    if(lLpSplit?.data?.operating_thrust?.id && lLpSplit?.viewType =='add' && !data?.thrust_split?.length){
                        setLLpRowData([{is_current_thrust:true, engine_thrust_type: filteredResp.find(item=> item?.id == lLpSplit?.data?.operating_thrust?.id)}])
                    }
                }
                else {
                    setEngineTypes([])
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
    }
    const onFieldChange = (key, value, index) => {
        let val = value
        if(key == 'engine_thrust_type' || key == 'cycles' || key =='hours'){
            val = (key == 'engine_thrust_type' && value?.id) ?{
                name:value?.name,
                id: value?.id,
                engine_thrust_name: value?.engine_thrust_name,
                engine_thrust_value: value?.engine_thrust_value
            }:value
            setLLpRowData(llpRowData.map((rowItem, rowIndex) => (
                rowIndex == index ? {
                    ...rowItem,
                    [key]: val,
                } : rowItem)
            ))
        }
        else{
            setLLpRowData(llpRowData.map((rowItem, rowIndex) => (
                rowIndex == index ? {
                    ...rowItem,
                    [key]: true
                } : {...rowItem, [key]:false})
            ))
        }
    }
    const deleteRowData = (index) => {
            setLLpRowData(llpRowData.filter((item, rowIndex) => index != rowIndex))
    }
    const onAddNewRow = () => {
        let data = [...llpRowData, llpRowData?.length ? emptyObject :{...emptyObject,is_current_thrust:true}]
        setLLpRowData(data)
    }
    const checkForErrors = (validateNewInput) => {
        let check = validateNewInput.map((item, index) => {
            if (item?.hours || item?.cycles || item?.engine_thrust_type) {
                return true
            }
            else {
                return false
            }
        })
        return check
    }
    const onSubmit = () => {
        let validateNewInput = llpRowData
        validateNewInput = validateNewInput.map((item, index) => ({
            engine_thrust_type: item?.engine_thrust_type ? '' : "Please select Thrust type",
            cycles:  item?.cycles ? '' : "Please enter cycles",
            hours: item?.hours ? '' : "Please enter hours",
        }))
        if(llpRowData?.length == 0){
            enqueueSnackbar('Please add atleast 1 row item to proceed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        else if (checkForErrors(validateNewInput).filter(item => item == true)?.length == 0) {
            if(llpRowData?.filter(item=> item.is_current_thrust).length == 0){
                enqueueSnackbar('Please select Current thrust to proceed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
            else{
                let totalHours= llpRowData.map(item=>  parseFloat(item?.hours)).reduce((prevVal, val)=> prevVal+val)
                let totalCycles= llpRowData.map(item=>  parseFloat(item?.cycles)).reduce((prevVal, val)=> prevVal+val)
                saveLLpSplit(llpRowData, totalHours, totalCycles)
            }
        }
        else{
            setError(validateNewInput)
        }
    }
    const onFocus = (key, index) => {
        setError(error?.map((item, errIndex) => (
            index == errIndex ? { ...item, [key]: '' } : item
        )))
    }
    return (
        <Dialog open='true'
            maxWidth={'md'}
            className='mou-dialog'>
            <DialogTitle>Thrust Split
                {lLpSplit?.viewType == 'view' ? null:
                <Button style={{ marginRight: '10px', float: 'right' }} color='primary' variant='outlined' size='small' onClick={() => onAddNewRow()} >
                    +Add new Row
                </Button>
                }
            </DialogTitle>
            <DialogContent dividers style={{ width: "950px", marginLeft:'0px' }} className='fleet-lease-management-sec'>
                {isLoading? <ListLoader />:null}
                <TableListComp
                    maxheight='500px'
                    heads={llpSplitHd}
                    data={llpRowData?.map((item, index) => (
                        <TableRow hover tabIndex={-1} style={{ padding: '4px 0px' }}  >
                            <TableCell className="asset-info" style={{ cursor: 'pointer' }} >
                                {(()=>{
                                    let thrustSelected= llpRowData?.map(item=> item?.engine_thrust_type?.name)
                                    let options= engineType?.length ? engineType?.filter(engine => !thrustSelected.includes(engine.name)):[];
                           return(
                                <Autocomplete
                                    disabled={lLpSplit?.viewType == 'view'}
                                    options={options}
                                    getOptionLabel={option => option?.name || '--'}
                                    value={llpRowData[index]?.engine_thrust_type || null}
                                    id="engine_thrust_type"
                                    style={{marginBottom:'4px', marginTop:'4px'}}
                                    onChange={(e, value) => onFieldChange('engine_thrust_type', value, index)}
                                    renderInput={params => <TextField onFocus={() => onFocus('engine_thrust_type', index)} placeholder={isLoading?'Fetching the list..':''} error={error[index]?.engine_thrust_type ? true : false} helperText={error[index]?.engine_thrust_type || ''} style={{ padding: '0px' }} {...params} InputLabelProps={{ shrink: true }} variant="outlined" />}
                                />
                           )
                            })()}
                            </TableCell>
                            <TableCell className="asset-info" style={{ cursor: 'pointer' }} >
                                <TextField
                                    disabled={lLpSplit?.viewType == 'view'}
                                    required
                                    style={{marginBottom:'4px', marginTop:'4px'}}
                                    id="hours"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    inputProps={{ maxLength: 20 }}
                                    margin="normal"
                                    error={error[index]?.hours ? true : false}
                                    helperText={error[index]?.hours || ''}
                                    value={llpRowData[index]?.hours || ''}
                                    variant='outlined'
                                    onFocus={() => onFocus('hours', index)}
                                    onChange={(e) => { regexConstants?.numberWithDot.test(e.target.value) ? onFieldChange('hours', e.target.value, index) : e.preventDefault() }}
                                />
                            </TableCell>
                            <TableCell className="asset-info" style={{ cursor: 'pointer' }} >
                                <TextField
                                    required
                                    disabled={lLpSplit?.viewType == 'view'}
                                    style={{marginBottom:'4px', marginTop:'4px'}}
                                    id="cycles"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    inputProps={{ maxLength: 20 }}
                                    margin="normal"
                                    error={error[index]?.cycles ? true : false}
                                    helperText={error[index]?.cycles || ''}
                                    value={llpRowData[index]?.cycles || ''}
                                    variant='outlined'
                                    onFocus={() => onFocus('cycles', index)}
                                    onChange={(e) => { regexConstants?.numberWithDot.test(e.target.value) ? onFieldChange('cycles', e.target.value, index) : e.preventDefault() }}
                                />
                            </TableCell>
                            <TableCell className="" style={{ cursor: 'pointer' }} >
                                <Checkbox
                                    style={{ marginLeft: "15px", fontSize: "12px" }}
                                    color="primary"
                                    size='small'
                                    disabled={lLpSplit?.viewType == 'view'}
                                    checked={llpRowData[index]?.is_current_thrust || false}
                                    onChange={(e) => onFieldChange('is_current_thrust', e.target.checked, index)}
                                />
                            </TableCell>
                            <TableCell align='right' className="" style={{ cursor: 'pointer', width:'50px' }} >
                                {lLpSplit?.viewType == 'view'? null:
                                <DeleteIcon fontSize='small' color='secondary' style={{ cursor: 'pointer' }} onClick={() => deleteRowData(index)} />}
                            </TableCell>
                        </TableRow>
                    ))
                    }
                    noRecord={llpRowData?.length ? null :
                        isLoading ? null :
                            <EmptyCollection
                                title="No records found"
                            />
                    }

                />
            </DialogContent>
            <DialogActions>
                <Button style={{ marginRight: '10px' }} color='primary' variant='outlined' size='small' onClick={() => onClose()} >
                {lLpSplit?.viewType == 'view' ? 'Okay': "Cancel"}
                </Button>
                {lLpSplit?.viewType == 'view' ? null:
                <Button color='primary' variant='contained' size='small' onClick={() => onSubmit()} >
                    Save
                </Button>}
            </DialogActions>
        </Dialog>
    )
}
