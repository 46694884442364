import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { addEditDeliveryCondApi, getDeliveryCondApi } from '../apiServices';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { errorCodeDeliveryCondition, addDeliveryCondition } from '../../';
class DeliveryCondCRU extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader: false,
      mode:'',
      deliveryCond: {},
      error:{}
    }
    this.addEditDeliveryCondApi = addEditDeliveryCondApi.bind(this);
    this.getDeliveryCondApi = getDeliveryCondApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      deliveryCond: {
        ...prevState.deliveryCond,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditDeliveyFn = () => {
    const { deliveryCond } = this.state;
    let validationInputs = {
      asset_conditions_for_contract:errorCodeDeliveryCondition['asset_conditions_for_contract'][fieldValidation({...errorCodeDeliveryCondition['asset_conditions_for_contractObj'], fieldval: deliveryCond.asset_conditions_for_contract && deliveryCond.asset_conditions_for_contract.id ? deliveryCond.asset_conditions_for_contract.id.toString():'' })],
      terms:errorCodeDeliveryCondition['terms'][fieldValidation({...errorCodeDeliveryCondition['termsObj'], fieldval: deliveryCond.terms})],
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      let payload = Object.assign({}, deliveryCond);
      payload = {
        ...payload,
        asset_conditions_for_contract_id: deliveryCond.asset_conditions_for_contract.id
      }
      this.addEditDeliveryCondApi(this.props, payload)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', deliveryCond:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { formSubmitLoader, modal, mode, deliveryCond, error, pageLoader } = this.state;
    const { id, m, contractClauseList, contractInfo } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        { id ?
          <span onClick={() => this.getDeliveryCondApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset ?
          null:
          <Button onClick={() => this.setState({modal:true, deliveryCond:addDeliveryCondition, mode:'add'})} variant="contained" color="primary"><AddIcon fontSize='small'/>Add Delivery Condition</Button>
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', deliveryCond:{}, error:{}})}
          >
            <div style={{width:'670px'}} className="drawer-container">
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Delivery Condition`}</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                { mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctdeliveryconditionitems/'
                        queryParams={{action:1, object_id:id}}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      {  contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts','delivery_conditions', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                    </li>
                  </ul>:null
                }
              </div>

              <div className="drawer-body">
                <Grid container spacing={2}>
                  { mode ===  'view' ?
                    <LabelValueCard md={6} label='Clause' value={deliveryCond.asset_conditions_for_contract && deliveryCond.asset_conditions_for_contract.name ? deliveryCond.asset_conditions_for_contract.name:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        options = {contractClauseList}
                        getOptionLabel={option => option.name}
                        value={deliveryCond.asset_conditions_for_contract ? deliveryCond.asset_conditions_for_contract:null}
                        id="asset_conditions_for_contract"
                        onChange={(e, value) => {this.onFieldChange(e, 'asset_conditions_for_contract', value); }}
                        renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('asset_conditions_for_contract', '')} error={error.asset_conditions_for_contract ? true:false} helperText={error.asset_conditions_for_contract?error.asset_conditions_for_contract:''} {...params} label="Clause" placeholder="Select Clause" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Terms' value={ deliveryCond.terms? ( <span dangerouslySetInnerHTML={{ __html: deliveryCond.terms.replace(/\n/g, '<br />')}}/>): '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="terms"
                        label="Terms"
                        fullWidth
                        margin="normal"
                        multiline
                        // inputProps={{ maxLength: 500 }}
                        value={deliveryCond.terms ? deliveryCond.terms:''}
                        error={error.terms ? true:false }
                        InputLabelProps={{shrink: true}}
                        helperText={error.terms ? error.terms: ''}
                        onChange={(e) => this.onFieldChange(e, 'terms', e.target.value)}

                        onFocus={(e) => this.updateErrorField('terms', '')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Post Delivery' value={deliveryCond.actual ? ( <span dangerouslySetInnerHTML={{ __html: deliveryCond.actual.replace(/\n/g, '<br />')}}/>):'--' } /> :
                    <Grid item xs={12}>
                      <TextField
                        id="actual"
                        label="Post Delivery"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 255 }}
                        value={deliveryCond.actual ? deliveryCond.actual:''}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'actual', e.target.value)}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Exceptions	Done' value={deliveryCond.exceptions_done ? ( <span dangerouslySetInnerHTML={{ __html: deliveryCond.exceptions_done.replace(/\n/g, '<br />')}}/>):'--' } /> :
                    <Grid item xs={12}>
                      <TextField
                        id="exceptions_done"
                        label="Exceptions	Done"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={deliveryCond.exceptions_done ? deliveryCond.exceptions_done:''}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'exceptions_done', e.target.value)}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={ deliveryCond.remarks? ( <span dangerouslySetInnerHTML={{ __html: deliveryCond.remarks.replace(/\n/g, '<br />')}}/>): '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        // inputProps={{ maxLength: 255 }}
                        value={deliveryCond.remarks ? deliveryCond.remarks:''}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                      />
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEditDeliveyFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', deliveryCond:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(DeliveryCondCRU));
