import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col, Progress, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip, UncontrolledTooltip } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { EditSidebar, Header, ContractSideBar, ContrSelectField, WidgetCard, WidgetTitle, ContractBarLoader } from '../../Elements';
import { removeEmptyKey } from '../../../../utils';
import { displayDateFormat, imgStoragePath, assetsType } from '../../../../constants';
import { ListLoader, NoRecordFound, InfoIcon } from '../../../../shared';
import { sGlobalConstantAc, sExportContractsAc } from '../../../../shared/actionCreators';
import FilterBar from '../../../../shared/FilterBar';
import InvoiceList from '../components/InvoiceList';
import InvoiceFilter from '../components/InvoiceFilter';
import InvoiceTotal from '../components/InvoiceTotal';
import Pagination from "react-js-pagination";
import ChangeInvoiceStatus from '../components/ChangeInvoiceStatus';
import { InvoiceAssetViewAc, invoiceDatesAc, genInvoiceStrAc, invoiceListAc, exportContractCashflowAc, changeInvoiceStatusAc, deleteInvoiceViewAc } from '../actionCreators';
import { GEN_INVOICE } from '../actions';
import moment from 'moment';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo, showCurrencyFormat } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import { getInvoiceAttachment, getPartyInfo } from '../apiServices'
import { contrHdlineInfoAc } from '../../HeadlinesInfo/actionCreators';
import { titanInstance } from '../../../../shared_elements/components';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      appliedFilter: {},
      page: 1,
      dropdownOpen: false,
      tooltipOpen: false,
      attachedDocList: [],
      partyList: [],
    }
    this.getInvoiceAttachment = getInvoiceAttachment.bind(this)
    this.getPartyInfo = getPartyInfo.bind(this)
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  toggleTip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }

  componentDidMount() {
    this.props.fetchContractInfo();
    this.props.fetchAssetInfo();
    this.props.fetchTechConstant({ constant_types: ['security_deposit_type'] });
    this.getInvoiceAttachment(this.props?.params?.contract_slug ? this.props.params.contract_slug : null);
     if(titanInstance){
    this.getPartyInfo(this.props?.params?.contract_slug ? this.props.params.contract_slug : null);
     }
  }

  updateListInvStatus = (data, contractSlug, invoiceId, type) => {
    this.props.updateInvStatus(data, contractSlug, invoiceId, type);
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }

  updateForm = (type, value) => {
    if (type == 'invoiceType' && value !== '6') {
      this.props.fetchDates(this.props.params.contract_slug, value);
    } else if (type === 'security_deposit_type') {
      this.props.fetchDates(this.props.params.contract_slug, this.props.genInvoice.invoiceType, value, 'security_deposit_type');
    }
    this.props.updateGenInvoice(type, value);
  }
  getInvoiceStr = (e) => {
    e.preventDefault();

    const { contract_slug } = this.props.params;
    const { invoiceType, selectedDate, bank_details_id, party_id } = this.props.genInvoice;
    const { assetViewId } = this.props;

    const invoiceData = {
      invoice_type: invoiceType,
      bank_details_id: bank_details_id,
    };
    const isSpecialInvoiceType = (invoiceType === '9' || invoiceType === '8' || invoiceType === '7');
    const invoiceParams = {
      ...invoiceData,
      ...(isSpecialInvoiceType ? { id: selectedDate } : { month: selectedDate }),
      ...(titanInstance ? { party_id: party_id } : {}),
    };
    this.props.genInvoiceStr(contract_slug, invoiceParams, assetViewId);
    // if(titanInstance){
    //   if(this.props.genInvoice.invoiceType ==='9'){
    //     this.props.genInvoiceStr(this.props.params.contract_slug, {invoice_type: this.props.genInvoice.invoiceType,id: this.props.genInvoice.selectedDate, bank_details_id: this.props.genInvoice.bank_details_id,party_id :this.props.genInvoice.party_id}, this.props.assetViewId)
    //   }else if(this.props.genInvoice.invoiceType !== '7' && this.props.genInvoice.invoiceType !== '8'){
    //     this.props.genInvoiceStr(this.props.params.contract_slug, {invoice_type: this.props.genInvoice.invoiceType,month: this.props.genInvoice.selectedDate, bank_details_id: this.props.genInvoice.bank_details_id,party_id :this.props.genInvoice.party_id}, this.props.assetViewId)
    //   }
    //   else{
    //     this.props.genInvoiceStr(this.props.params.contract_slug, {invoice_type: this.props.genInvoice.invoiceType,id: this.props.genInvoice.selectedDate, bank_details_id: this.props.genInvoice.bank_details_id,party_id :this.props.genInvoice.party_id}, this.props.assetViewId)
    //   }
    // }else{
    //   if(this.props.genInvoice.invoiceType ==='9'){
    //     this.props.genInvoiceStr(this.props.params.contract_slug, {invoice_type: this.props.genInvoice.invoiceType,id: this.props.genInvoice.selectedDate, bank_details_id: this.props.genInvoice.bank_details_id}, this.props.assetViewId)
    //   }else if(this.props.genInvoice.invoiceType !== '7' && this.props.genInvoice.invoiceType !== '8'){
    //     this.props.genInvoiceStr(this.props.params.contract_slug, {invoice_type: this.props.genInvoice.invoiceType,month: this.props.genInvoice.selectedDate, bank_details_id: this.props.genInvoice.bank_details_id}, this.props.assetViewId)
    //   }
    //   else{
    //     this.props.genInvoiceStr(this.props.params.contract_slug, {invoice_type: this.props.genInvoice.invoiceType,id: this.props.genInvoice.selectedDate, bank_details_id: this.props.genInvoice.bank_details_id}, this.props.assetViewId)
    //   }
    // }




  }

  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.fetchInvList(this.props.params.contract_slug, getQueryStr);
    this.setState({
      appliedFilter: filterParams,
      selectedFilterTitle: '',
      page: 1
    });
  }

  changeFilterFn = (...args) => {

    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'invoice_type':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Invoice Type ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'status':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Invoice Status ',
              value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'dueDate':
        filterParams = {
          ...filterParams,
          'dueDate': {
            name: 'Invoice Due Date',
            value: (args[1] ? args[1] : '--') + ' To ' + (args[2] ? args[2] : '--'),
            dueStartDate: args[1] ? args[1] : '',
            dueEndDate: args[2] ? args[2] : ''
          }
        }
        break;
      case 'transactionDate':
        filterParams = {
          ...filterParams,
          'transactionDate': {
            name: 'Invoice Date',
            value: (args[1] ? args[1] : '--') + ' To ' + (args[2] ? args[2] : '--'),
            startDate: args[1] ? args[1] : '',
            endDate: args[2] ? args[2] : ''
          }
        }
        break;
      case 'overdue_days':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Invoice Overdue by Days ', value: args[1],
              filterData: args[1]
            }
          }
        }
        break;
      case 'invoice_number':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Invoice Number ', value: args[1],
              filterData: args[1]
            }
          }
        }
        break;
      case 'currency':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Currency Type ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
      default:
        break;
    }
    this.setState({
      appliedFilter: filterParams,
      selectedFilterTitle: '',
      page: 1
    });

    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.fetchInvList(this.props.params.contract_slug, getQueryStr);
  }
  getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if (key == 'transactionDate') {
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      } else if (key == 'dueDate') {
        queryFilter['dueStartDate'] = params[key].dueStartDate
        queryFilter['dueEndDate'] = params[key].dueEndDate
      } else {
        queryFilter[key] = params[key].filterData
      }
    });
    return queryFilter;
  }
  handlePageChange = (page) => {
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter));
    getQueryStr['page'] = page;
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
    this.props.fetchInvList(this.props.params.contract_slug, getQueryStr);
  }
  render() {
    const { invoiceList, techConstants, invoicePagination, ctrShortInfo, genInvoice, invoiceWidget, invoiceLoader, invStrLoader, contractInfo } = this.props;
    const filterMenu = {
      'Invoice Type': {
        'inputType': 'dropdown',
        'keyParam': 'invoice_type',
        'placeholder': 'Select Invoice Type',
        'options': [{ label: 'Reserve Invoice', value: '1' }, { label: 'Rental Invoice', value: '2' }, { label: 'Ad Hoc Invoice', value: '9' }, { label: 'Penalty Invoice', value: '3' }, { label: 'Debit Note Invoice', value: '4' }, { label: 'Credit Note Invoice', value: '5' }, { label: 'Security Deposit Invoice', value: '6' }, { label: 'Insurance Invoice', value: '7' }, { label: 'Insurance Claim Invoice', value: '8' }],
        'labelKey': 'label',
        'valueKey': 'name',
      },
      'Invoice Number': {
        'inputType': 'text',
        'keyParam': 'invoice_number'
      },
      'Invoice Due Date': {
        'inputType': 'date',
        'keyParam': 'dueDate'
      },
      'Invoice Date': {
        'inputType': 'date',
        'keyParam': 'transactionDate'
      },
      'Invoice Overdue by Days': {
        'inputType': 'number',
        'keyParam': 'overdue_days'
      },
      'Invoice Status': {
        'inputType': 'dropdown',
        'keyParam': 'status',
        'placeholder': 'Select Status',
        'options': [{ label: 'Draft', value: '1' }, { label: 'Sent', value: '2' }, { label: 'Paid', value: '3' }, { label: 'Cancelled', value: '4' }, { label: "Partially Paid", value: '5' }],
        'labelKey': 'label',
        'valueKey': 'name',
      }
    };


    var bank_details = []
    if (genInvoice.bank_details) {
      genInvoice.bank_details.map(item => {
        bank_details.push({ id: item.id, name: item.name + ' (' + item.account_number + ')' })
      })
    }

    const party_list = []
    if (this.state.partyList.length > 0) {
      this.state.partyList.map(item => {
        party_list.push({ id: item.id, name: item.name })
      })
    }

    return (
      <div className="contract-container">
        <div className="mr-rate-wrapper invoice-wrapper" style={{ marginLeft: '60px' }}>
          <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
          <ContractSideBar application="Invoice List" type="brief" params={this.props.params} />
          <div>
            {
              invoiceLoader || this?.props?.contractSummaryLoader? <ListLoader /> :null
            }
            <Row className="invoice-cards flex-not-centered">
              <WidgetCard md="4" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para">Total Paid Invoices</WidgetTitle>
                <Row>
                  <Col md="6">
                    <h6>Reserve Invoice</h6>
                    <p>
                      {
                        invoiceWidget.mr_total ?
                          <NumericLabel params={showCurrencyFormat()}>
                            {invoiceWidget.mr_total}
                          </NumericLabel> : '--'
                      }

                      <span style={{ opacity: "0.5" }} className="as-of-date">
                        As of {
                          invoiceWidget.mr_detail_as_of_date ?
                            moment(invoiceWidget.mr_detail_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </Col>
                  <Col md="6">
                    <h6>Monthly Rentals</h6>
                    <p>
                      {invoiceWidget.rental_total_new ?
                        invoiceWidget.rental_total_new.length ?
                          invoiceWidget.rental_total_new.map((invoice, index) =>
                            <div>
                              <NumericLabel params={showCurrencyFormat(invoice.currency)}>
                                {invoice.total_amount}
                              </NumericLabel></div>) : '--' : '--'
                      }

                      <span style={{ opacity: "0.5" }} className="as-of-date">
                        As of {
                          invoiceWidget.rental_detail_as_of_date ?
                            moment(invoiceWidget.rental_detail_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </Col>
                </Row>
              </WidgetCard>
              <WidgetCard md="4" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para invoice-space-block">Pending Reserve Invoices</WidgetTitle>
                <Row>
                  <Col md="6">
                    <h6 className="caption-ui">
                      Not Yet Due
                      <span
                        style={{ position: 'absolute', top: '-1px', left: "83px", fontSize: '15px', color: '#fff' }}
                      >
                        <i className="fa fa-info-circle" id='notYetDueMaintanence' aria-hidden="true"></i>
                        <UncontrolledTooltip placement="right" target="notYetDueMaintanence">
                          Payments pending to be received but not past due date
                        </UncontrolledTooltip>
                      </span>
                    </h6>
                    <p className="space-block wrapword">
                      {
                        invoiceWidget.mr_not_due ?
                          <NumericLabel params={showCurrencyFormat()}>
                            {invoiceWidget.mr_not_due}
                          </NumericLabel> : '--'
                      }
                    </p>
                  </Col>
                  <Col md="6" className="text-right">
                    <h6 className="caption-ui">Overdue</h6>
                    <p className="space-block wrapword">
                      {
                        invoiceWidget.mr_overdue ?
                          <NumericLabel params={showCurrencyFormat()}>
                            {invoiceWidget.mr_overdue}
                          </NumericLabel> : '--'
                      }
                    </p>
                  </Col>
                </Row>
                <div className={(invoiceWidget.mr_not_due == 0 && invoiceWidget.mr_overdue == 0) ? 'green' : ''}>
                  <Progress value={Math.round(Math.round(invoiceWidget.mr_not_due / (invoiceWidget.mr_not_due + invoiceWidget.mr_overdue) * 100))} />
                </div>
              </WidgetCard>
              <WidgetCard md="4" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para invoice-space-block">Pending Rental Invoices</WidgetTitle>
                <Row>

                  <Col md="6">
                    <h6 className="caption-ui">Not Yet Due
                      <span
                        style={{ position: 'absolute', top: '-1px', left: "83px", fontSize: '15px', color: '#fff' }}
                      >
                        <i className="fa fa-info-circle" id='notYetDueRentals' aria-hidden="true"></i>
                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="notYetDueRentals" toggle={this.toggleTip}>
                          Payments pending to be received but not past due date
                        </Tooltip>
                      </span>
                    </h6>
                    <p className="space-block wrapword">
                      {invoiceWidget.rental_not_due_new ?
                        invoiceWidget.rental_not_due_new.length ?
                          invoiceWidget.rental_not_due_new.map((invoice, index) =>
                            <div>
                              <NumericLabel params={showCurrencyFormat(invoice.currency)}>
                                {invoice.total_amount}
                              </NumericLabel></div>) : '--' : '--'
                      }
                    </p>
                  </Col>
                  <Col md="6" className="text-right">
                    <h6 className="caption-ui">Overdue</h6>
                    <p className="space-block wrapword">
                      {invoiceWidget.rental_overdue_new ?
                        invoiceWidget.rental_overdue_new.length ?
                          invoiceWidget.rental_overdue_new.map((invoice, index) =>
                            <div>
                              <NumericLabel params={showCurrencyFormat(invoice.currency)}>
                                {invoice.total_amount}
                              </NumericLabel></div>) : '--' : '--'
                      }
                    </p>
                  </Col>
                </Row>
                <div className={(invoiceWidget.rental_not_due == 0 && invoiceWidget.rental_overdue == 0) ? 'green' : ''}>
                  <Progress value={Math.round(Math.round(invoiceWidget.rental_not_due / (invoiceWidget.rental_not_due + invoiceWidget.rental_overdue) * 100))} />

                </div>
              </WidgetCard>
            </Row>
            <div className="contract-invoice-list">
              <div className="mobile-asset-list">
                <div className="generate-invoice flex-centered">
                  {checkPermission('contracts', 'invoices', 'C') && contractInfo?.hdlineInfo?.archived_asset == false ?
                    <button onClick={() => this.props.updateGenInvoice('flag', true)} className="btn primary-btn">Generate Invoice</button> : null
                  }
                  <div className="download-block" style={{ paddingRight: '15px' }}>
                    {checkPermission('contracts', 'invoices', 'EXP') && invoiceList?.length ?
                      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret size="sm">
                          <span><img width="12" src={exportIcon} alt="export" /> Export </span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, 'pdf', removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)))}><img src={imgStoragePath + "pdf_icon.png"} alt="img" />PDF</DropdownItem>
                          <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, 'xls', removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)))} ><img src={imgStoragePath + "excel_icon.png"} alt="img" />Excel</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                      : null
                    }
                  </div>
                </div>
              </div>
              <div className="mobile-asset-list">
                <div className="asset-list-filter-sort">
                  <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={filterMenu} changeFilterFn={this.changeFilterFn} />
                </div>
                <div className="asset-list-filter-sort">
                  <InvoiceFilter />
                </div>
                {
                  invoiceList.map((invoice, index) =>
                    <InvoiceList
                      contractInfo={contractInfo}
                      updateListInvStatus={this.updateListInvStatus}
                      key={index} params={this.props.params}
                      contract_slug={ctrShortInfo.contract ? this.props.params.contract_slug : ''}
                      invoice={invoice}
                      ctrShortInfo={ctrShortInfo}
                      props={this.props}
                      attachedDocList={this.state.attachedDocList}
                      getInvoiceAttachment={this.getInvoiceAttachment}
                    />)
                }

              </div>
              {
                !invoiceLoader && !invoiceList.length ? null :
                  <div style={{ padding: '0 0 40px 0' }}>
                    <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={20}
                      totalItemsCount={(invoicePagination && invoicePagination.total) ? invoicePagination.total : 0}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                    />
                  </div>
              }
              {
                !invoiceLoader && !invoiceList.length ?
                  <NoRecordFound description="Get Started by clicking 'Generate Invoice' button above" />
                  : null
              }
            </div>

            <EditSidebar width="470px" right="-470px" title="Generate Invoice" editSideBar={genInvoice.flag} toggleEditSideBar={() => this.props.updateGenInvoice('flag', false)}>
              {
                genInvoice.flag ?
                  <form onSubmit={this.getInvoiceStr}>
                    <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
                      <ContrSelectField
                        label="Invoice Type"
                        placeholder='Select Invoice Type'
                        keyParam="invoiceType"
                        value={genInvoice.invoiceType}
                        options={[{ label: 'Reserve Invoice', value: '1' }, { label: 'Rental Invoice', value: '2' }, { label: 'Ad Hoc Invoice', value: '9' }, { label: 'Penalty Invoice', value: '3' }, { label: 'Debit Note Invoice', value: '4' }, { label: 'Credit Note Invoice', value: '5' }, { label: 'Security Deposit Invoice', value: '6' }, { label: 'Insurance Invoice', value: '7' }, { label: 'Insurance Claim Invoice', value: '8' }]}
                        updateField={this.updateForm}
                        type="edit"
                        labelKey='label'
                        valueKey='value'
                        md="12"
                      />
                      {titanInstance ?
                        <ContrSelectField
                          label={"Owner/Trustee"}
                          placeholder={"Select Owner/Trustee"}
                          keyParam="party_id"
                          value={genInvoice.party_id}
                          options={party_list}
                          updateField={this.updateForm}
                          type="edit"
                          labelKey='name'
                          valueKey='id'
                          md="12"
                        />
                        : null}
                      {
                        genInvoice.dates.length ?
                          <ContrSelectField
                            label={genInvoice.invoiceType == '1' ? 'Utilization month to be invoiced' : genInvoice.invoiceType == '2' ? 'Rental month to be invoiced' : 'Invoice number'}
                            placeholder={genInvoice.invoiceType == '1' ? 'Select month' : genInvoice.invoiceType == '2' ? 'Select month' : 'Select invoice number'}
                            keyParam="selectedDate"
                            value={genInvoice.selectedDate}
                            options={genInvoice.dates}
                            updateField={this.updateForm}
                            type="edit"
                            labelKey='label'
                            valueKey='value'
                            md="12"
                          /> :
                          null

                      }
                      {
                        genInvoice.invoiceType === '6' ?
                          <ContrSelectField
                            label={"Security Deposit Type"}
                            placeholder={"Select Security Deposit"}
                            keyParam="security_deposit_type"
                            value={genInvoice.security_deposit_type}
                            options={techConstants.filter(item => item.type == "security_deposit_type")}
                            updateField={this.updateForm}
                            type="edit"
                            labelKey='label'
                            valueKey='value'
                            md="12"
                          /> :
                          null

                      }
                      {
                        genInvoice.invoiceType === '7' ?
                          <ContrSelectField
                            label={"Insurances"}
                            placeholder={"Select Insurance"}
                            keyParam="selectedDate"
                            value={genInvoice.selectedDate}
                            options={genInvoice.insurances}
                            updateField={this.updateForm}
                            type="edit"
                            labelKey='type'
                            valueKey='id'
                            md="12"
                          /> :
                          null

                      }
                      {
                        genInvoice.invoiceType === '8' ?
                          <ContrSelectField
                            label={"Insurance Claims"}
                            placeholder={"Select Insurance Claim"}
                            keyParam="selectedDate"
                            value={genInvoice.selectedDate}
                            options={genInvoice.insurance_claim}
                            updateField={this.updateForm}
                            type="edit"
                            labelKey='type'
                            valueKey='id'
                            md="12"
                          /> :
                          null

                      }
                      {
                        genInvoice.security_deposit && genInvoice.security_deposit.length ?
                          <ContrSelectField
                            label={"Security Deposit Against ( Amount ) "}
                            placeholder={"Security Deposit Against"}
                            keyParam="selectedDate"
                            value={genInvoice.selectedDate}
                            options={genInvoice.security_deposit}
                            updateField={this.updateForm}
                            type="edit"
                            labelKey='security_against'
                            valueKey='id'
                            md="12"
                          /> :
                          null

                      }
                      {
                        genInvoice.bank_details && genInvoice.bank_details.length ?
                          <ContrSelectField
                            label={"Bank"}
                            placeholder={"Select Bank"}
                            keyParam="bank_details_id"
                            value={genInvoice.bank_details_id}
                            options={bank_details}
                            updateField={this.updateForm}
                            type="edit"
                            labelKey='name'
                            valueKey='id'
                            md="12"
                          /> :
                          null

                      }
                      <div className="form-group">
                        <button disabled={(genInvoice.invoiceType != '' && genInvoice.selectedDate != '' && genInvoice.bank_details_id != undefined && genInvoice.bank_details_id !== null && genInvoice.bank_details_id !== '' ? false : true)} className="btn-primary add-new">
                          {invStrLoader ? 'Processing...' : 'Generate Invoice'}
                        </button>
                        <span onClick={() => this.props.updateGenInvoice('flag', false)} style={{ padding: '0 16px', cursor: 'pointer', textTransform: 'uppercase', color: '#3f51b5' }}>Cancel</span>
                      </div>
                    </div>
                  </form> : null
              }

            </EditSidebar>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contractSummaryLoader: state.sharedReducers.contractSummaryLoader,
  invoiceList: state.InvoiceReducer.invoiceList,
  invoicePagination: state.InvoiceReducer.invoicePagination,
  genInvoice: state.InvoiceReducer.genInvoice,
  invoiceWidget: state.InvoiceReducer.invoiceWidget,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  invoiceLoader: state.InvoiceReducer.invoiceLoader,
  invStrLoader: state.InvoiceReducer.invStrLoader,
  techConstants: state.sharedReducers.techConstants,
  assetViewId: state.userAssetReducer.assetViewId,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,

})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchAssetInfo: () => dispatch(InvoiceAssetViewAc(ownProps.params)),
    fetchInvList: (contract_slug, queryFilter) => dispatch(invoiceListAc(contract_slug, queryFilter)),
    fetchDates: (contract_slug, value, security_deposit_type, type) => dispatch(invoiceDatesAc(contract_slug, value, security_deposit_type, type)),
    genInvoiceStr: (contract_slug, data, assetViewId) => dispatch(genInvoiceStrAc(ownProps.params, contract_slug, data, assetViewId)),
    exportInvoice: (contract, fileType, queryFilter) => dispatch(exportContractCashflowAc(ownProps, contract, fileType, queryFilter)),
    updateInvStatus: (data, contractSlug, invoiceId, type) => dispatch(changeInvoiceStatusAc(ownProps.params, data, contractSlug, invoiceId, type)),
    updateGenInvoice: (type, value) => dispatch({
      type: GEN_INVOICE,
      payload: { type, value }
    }),
    fetchTechConstant: (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Invoice, ['contracts', 'invoices', 'R'])));
