import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import moment from 'moment';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
import { displayDateFormatShort } from '../../../../constants';
import SubleaseCRU from './SubleaseCRU';
export default function SubleaseList({item, previewDetail, getResponseBack, contractInfo,deleteSubLease}){
  const [showMore, setShowMore] = useState({});
  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.sublease_lessee}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>
      {item?.details && item?.details?.length > 200 ? (
          <div style={{ maxWidth: '400px' }}>
            {showMore[item?.id] && showMore[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.details.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.details.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.details.replace(/\n/g, '<br />') }} />
          </div>
        )}
        </TableCell>
      <TableCell>{item.start_date ? moment(item.start_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.end_date ? moment(item.end_date).format(displayDateFormatShort): '--'}</TableCell>
      <TableCell>
        <SubleaseCRU getResponseBack={getResponseBack} m="V" id={item.id} contractInfo={contractInfo}/>
        {checkPermission('contracts','sublease', 'D') ? <DeleteIcon fontSize='small' color='secondary' style= {{cursor:'pointer'}} onClick={()=>deleteSubLease(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
