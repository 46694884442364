import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withRouter, browserHistory, Link } from 'react-router';
import { Header, EditSidebar, ContractHeadertext, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { MRRentalInvoiceConf, EditRentalForm,  LeaseRentalInvoice, LeaseRentalHeader, LeaseRentalList } from '../components';
import { fetchRentalAc, leaseRentalAddEditAc, leaseRentalCrudAc,fetchRevisionHistoryAc, rentalInvoiceEditAc, deleteRentalLeaseAc, uploadSheetAc } from '../actionCreators';
import {  TOGGLE_VIEW_EDIT, LEASE_RENTAL_CRUD, TOGGLE_RENTAL_INVOICE_SIDE_BAR, MODAL_TOGGLE,TOGGLE_REVISION_HISTORY } from '../actions';
import { sExportContractsAc,sAuditLogAc, sGlobalConstantAc } from '../../../../shared/actionCreators';
import { displayDateFormat, assetsType } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { checkPermission } from '../../../../utils_v2';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { HistoryList } from '../../MRRates/components';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
import { contrHdlineInfoAc } from '../../HeadlinesInfo/actionCreators';
import LeaseRentalEdit from '../components/LeaseRentalEdit';
class LeaseRentalRates extends Component {
  constructor(props){
    super(props);
    this.state = {
      editStatus: false,
      editRental: false,
      editRentalInvoice: false,
      deleteRentalId : 0,
      modal: false,
      remarksError:'',
      remarks:'',
      remarkData:{},
      modal:false,
      checkRental:false,
      rentalType:'',
    }
  }

  componentDidMount = () => {
    this.props.fetchContractInfo();
    this.props.fetchRentalInfo();
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }

  toggle(flag) {
   this.props.modalToggleFn(flag);
  }

  deleteFn = (e, rentalId) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      ...prevState,
      deleteRentalId : rentalId
    }))
    this.toggle(!this.props.modalFlag);
   }

   toggleRevisionHistory=()=>{
    this.props.fetchRevisionHistory(this.props.leaseRentalCrud.data.id);
  }

  toggleRevisionHistoryConf=()=>{
    this.props.fetchRevisionHistory(this.props.updateRentalInvoice.id);
  }

  toggleReason=(data,check=false,type='')=>{
    this.setState({
      modal: !this.state.modal,
      remarkData: data,
      remarks: '',
      remarksError: '',
      checkRental:check,
      rentalType:type
    });

  }


  handleRemarks = (e) => {
    let value = e.target.value;
    this.setState({
      remarks: value,
      remarksError:''
    })
  }

toggleModel=()=> {
    this.setState({
      modal: !this.state.modal,
      remarks: '',
      remarksError: ''
    });
  }

  updateRejectStatus = () => {
    if(this.state.remarks != ''){
      let data = Object.assign({}, this.state.remarkData);
      data={
        ...data,
        reason_for_reject:this.state.remarks
      }
      if(this.state.checkRental){
        this.props.updateRental(data,this.state.rentalType);
      }else{
        this.props.updateRentalInvoice (data);
      }

      this.toggleModel();
    }else{
      this.setState({
        remarksError: 'Please enter the Reason'
      })
    }
  }

  render() {
    const  { rentalInfo, leaseRentalLoader,mrRevisionList, revisionFlag,leaseRentalCrud, rentalInvoiceFlag, modalFlag, sheetInfo ,path,auditLogView, params, contractInfo} = this.props;
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application={`Lease Rental Rates`} type="brief" params= {this.props.params} />
        { leaseRentalLoader || this?.props?.contractSummaryLoader ?  <ListLoader /> : null}
        {Object.keys(rentalInfo).length ?
          <div>
            <div className="contract-content-blk">
              <Link to={`/${params.type}/${params.aircraft_slug}/contract/list/${params.contract_slug}`} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
                <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
              </Link>
              <ContractHeadertext
              title="Lease Rental Rates"
               desc="Section holds Rental rate & configuration applicable under the Lease"
                toggleAddEvent = {() => this.props.rentalCondnCrud(this.props.params, null, 'add')}
                addNew={ checkPermission('contracts', 'lease_rental_rate', 'C') && contractInfo?.hdlineInfo?.archived_asset ==false? '+ Add Lease Rental':''}
                addAudit=   "Revision History"
                toggleAuditlog = {() => this.props.toggleAuditlogFn('contracts', 'ctrentalrate',0, null, 'add',this.props.params.contract_slug,'contract')}
                />
              <div className="list-container" style={{minHeight: 'auto'}}>
                   <div className="table-container">
                    <table>
                        <LeaseRentalHeader/>
                        <tbody>
                          {
                            Object.keys(rentalInfo.rental_payment_schedule).length ?
                            rentalInfo.rental_payment_schedule.map((item, index) =>
                              <LeaseRentalList index={index} params = {this.props.params} deleteFn= {this.deleteFn} viewRental={this.props.rentalCondnCrud} key={index} rental = {item}/>
                            ) : null
                          }
                        </tbody>
                      </table>
                      {
                        !leaseRentalLoader && !Object.keys(rentalInfo.rental_payment_schedule).length ?
                          <NoRecordFound description="Get Started by clicking '+ Add Lease Rental' button above" />:null
                        }
                  </div>
                </div>

            <Modal isOpen={this.props.modalFlag} centered={true} toggle={() => this.toggle(!modalFlag)} className={this.props.className}>
              <ModalHeader toggle={() => this.toggle(!modalFlag)}>Delete Lease Rental</ModalHeader>
              <ModalBody>
                <div className="delete-logs">
                  <h4>Are you sure, you want to delete Lease Rental ?<b> </b></h4>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <button className="btn btn-danger" onClick = {() => this.props.deleteRentalLease(this.state.deleteRentalId)} >YES</button>
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-secondary" onClick={() => this.toggle(!modalFlag)}>NO</button>
                    </li>
                  </ul>
                </div>
              </ModalBody>
            </Modal>

              <LeaseRentalInvoice contractInfo={contractInfo} toggleEditSideBar={() => this.props.toggleRentalInvoice(!rentalInvoiceFlag)} rentalInvoiceView={rentalInfo.contract_rental_details} />
            </div>
            <EditSidebar
              title={
                leaseRentalCrud.type != '' ?
                (leaseRentalCrud.type == 'add') ? 'Add Lease Rental':
                (leaseRentalCrud.type == 'edit') ? 'Edit Lease Rental': 'View Lease Rental' : ''
                }
                width={'870px'}
                right={'-870px'}
              editSideBar={leaseRentalCrud.flag}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
            >
           { Object.keys(leaseRentalCrud.data).length && leaseRentalCrud.flag ?
              <div>
              <FormModeType
                pendingReview={true}
                rentalView={leaseRentalCrud.data}
                auditLog={true}
                auditLogMode= {()=>this.props.toggleAuditlogFn('contracts', 'ctrentalrate', 1, leaseRentalCrud.data.id, 'update')}
                type={leaseRentalCrud.type}
                changeViewMode={()=>this.props.changeViewMode(leaseRentalCrud.type)}
                editAble = { checkPermission('contracts', 'lease_rental_rate', 'U') && contractInfo?.hdlineInfo?.archived_asset ==false ? true:false}
              />
                <EditRentalForm
                  rentalInfo = {rentalInfo.rental_payment_schedule}
                  rentalInfoDetails = {rentalInfo}
                  type={leaseRentalCrud.type}
                  rentalView={leaseRentalCrud.data}
                  addEditLeaseRental={this.props.updateRental}
                  updateSheet={this.props.uploadSheet}
                  path={path}
                  sheetInfo={sheetInfo}
                  sheetLoader={this.props.sheetLoader}
                  toggleReason={this.toggleReason}
                  // addEditReturnCond={this.props.addEditReturnCond}
                  toggleRevisionHistory={() => this.toggleRevisionHistory()}
                  toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                  leaseRentalLoader={leaseRentalLoader}
                  /> </div>: null
           }
            </EditSidebar>
        <EditSidebar
          width='870px'
          right='-870px'
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
        >
         {Object.keys(auditLogView.data).length && auditLogView.flag ?
           <div>
             <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                    <AddDeleteAudit auditLogView={item} key={index}/>
                  ) :
                  auditLogView.data.map((item, index) =>
                    <UpdateAuditLog auditLogView={item} key={index}/>
                  )
                }
            </div>
          </div>
          : <NoRecordFound title="No Revision History Found"/>
         }
        </EditSidebar>



            <EditSidebar
              width="470px" right= "-470px"
              title="Edit Lease Rental Rate Invoice Configuration"
              editSideBar={this.props.rentalInvoiceFlag}
              toggleEditSideBar={() => this.props.toggleRentalInvoice(!rentalInvoiceFlag)}
            >
              {this.props.rentalInvoiceFlag ?
                // <MRRentalInvoiceConf
                <LeaseRentalEdit
                  type="edit"
                  rentalInvoiceView={rentalInfo.contract_rental_details}
                  updateRentalInvoice={this.props.updateRentalInvoice}
                  toggleReason={this.toggleReason}
                  toggleRevisionHistory={() => this.toggleRevisionHistoryConf()}
                  toggleEditSideBar={() => this.props.toggleRentalInvoice(!rentalInvoiceFlag)}
                /> : null
              }
            </EditSidebar>
            <Modal isOpen={this.state.modal} toggle={this.toggleModel} >
          <ModalHeader toggle={this.toggleModel}>Reason of Rejection</ModalHeader>
          <ModalBody>
            <textarea style={{border: '1px solid #d7d7d7', width: '100%', height: '100px',padding: '5px 10px'}} onChange={this.handleRemarks}  value={this.state.remarks}>
            </textarea>
            <h6 className="error-msg">{this.state.remarksError}</h6>
            <p>
              <button onClick={this.updateRejectStatus} style={{float: 'right'}} className="primary-btn">SAVE</button>
            </p>
          </ModalBody>
        </Modal>



            <EditSidebar title="Review Revision History" editSideBar={revisionFlag} toggleEditSideBar={() => this.props.closeRevisionSideBar(false)}>
              <div className="reviewer-issue-drawer">
              <div className="issues-listing" style={{ height: (window.innerHeight - 100) + 'px', overflow:'auto',marginBottom:'60px' }}>
                  <ul className="list-unstyled resolved-list">
                    {mrRevisionList.map((item, index) =>
                        <HistoryList
                            key={index}
                            index={index}
                            details={item}
                        />
                    )}
                  </ul>
                  {mrRevisionList.length? null:<NoRecordFound/>}
                </div>
              </div>
            </EditSidebar>
          </div>:
          <ListLoader />

        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contractSummaryLoader: state.sharedReducers.contractSummaryLoader,
  rentalInfo: state.ContrLesRentalReducer.rentalInfo ,
  sheetInfo: state.ContrLesRentalReducer.sheetInfo ,
  path: state.ContrLesRentalReducer.path ,
  sheetLoader: state.ContrLesRentalReducer.sheetLoader ,
  leaseRentalLoader: state.ContrLesRentalReducer.leaseRentalLoader,
  leaseRentalCrud: state.ContrLesRentalReducer.leaseRentalCrud,
  rentalInvoiceFlag: state.ContrLesRentalReducer.rentalInvoiceFlag,
  modalFlag: state.ContrLesRentalReducer.modalFlag,
  mrRevisionList:state.ContrLesRentalReducer.mrRevisionList,
  revisionFlag:state.ContrLesRentalReducer.revisionFlag,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchRentalInfo: () => dispatch(fetchRentalAc(ownProps.params)),
    updateRental: (data, type) => dispatch(leaseRentalAddEditAc(ownProps.params, data, type)),
    uploadSheet:(data) => dispatch(uploadSheetAc(data,ownProps.params)),
    rentalCondnCrud: (rentalId, index, type) => dispatch(leaseRentalCrudAc(rentalId, index, type)),
    updateRentalInvoice: (data) => dispatch(rentalInvoiceEditAc(ownProps.params, data)),
    fetchRevisionHistory: (id) => dispatch(fetchRevisionHistoryAc(id)),
    deleteRentalLease: (rentalId) => dispatch(deleteRentalLeaseAc(ownProps.params, rentalId)),
    modalToggleFn: (flag) => dispatch({
      type: MODAL_TOGGLE,
      payload: flag
    }),
    closeRevisionSideBar: (value) => dispatch({
      type: TOGGLE_REVISION_HISTORY,
      payload:value
    }),
    toggleRentalInvoice: (flag) => dispatch ({
      type: TOGGLE_RENTAL_INVOICE_SIDE_BAR,
      payload: flag
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    toggleEditBar: (data) => dispatch({
      type: LEASE_RENTAL_CRUD,
      payload: data
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    toggleAuditlogFn: (application, content_type, action, objectId, type,pramKey=null,paramValue=null) => dispatch(sAuditLogAc(application, content_type, action, objectId, type,pramKey,paramValue)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(LeaseRentalRates, ['contracts', 'lease_rental_rate' ,'R'])));
