import React, { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import exportIcon from '../../../shared/assets/img/export_icon.svg';
import { ContractStats, InvoiceCard, InvoiceWidgetList, CashflowPieChart, ContractExpiryCard, UtilsHourCycls, UtilsBlock, DbGraphCard, EngineAndLgUtil, DbBackCom, ContractDateFilter, AssetsDistribustion, LeaseDistribustion } from '../components';
import { contractCardWidgetAc, invoiceSummaryWidgetAc, contractExpiryWidgetAc, contractBasedMsnAc, cashflowPaymentAc, utilizationSummaryWidgetAc, cashflowSummarydbWidgetAc, assetsInfoWidgetAc, detailInvExportAc, setDefaultUrlAc } from '../actionCreators';
import NoRecordFound from '../../../shared/components/NoRecordFound';
import RoleBasedAccess from '../../../utils/RoleBasedAccess'
import { getLocalStorageInfo, removeEmptyKey } from '../../../utils'
import { checkPermission } from '../../../utils_v2'
import { trackActivity } from '../../../utils/mixpanel';
import { ListLoader } from '../../../shared';
import { getAssetDistribustionApi, getLeaseDistribustionApi, getInvoiceSummaryApi, getInvoiceTypeSummaryApi, getExpirationCertificateApi, exportExpirationCertificateApi, lowUtilsListApi, exportLowUtilsListApi, } from '../apiServices'
import InvoiceStatus from '../components/InvoiceStatus';
import InvoiceTypeSummary from '../components/InvoiceTypeSummary';
import ExpirationofCertificates from '../components/ExpirationofCertificates';
import { imgStoragePath } from '../../../constants';
import FilterBar from '../../../shared/FilterBar';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import months from '..';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
import { Accordion, TableCell, TableRow, AccordionSummary, AccordionDetails, Button, TableHead, Paper, TableBody } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router';
import config from '../../../config';
import { jetStreamInstance } from '../../../lease_management/Leases';

class ContractsFleetDb extends Component {
  constructor(props) {
    super(props);
    this.toggleUtilizationTrend = this.toggleUtilizationTrend.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      utilizationTrendTab: '1',
      startDate: moment(),
      endDate: moment().add(5, 'day'),
      msnUtilSlug: '',
      msnCashflowSlug: '',
      activeUtilAsset: 1,
      deploymentInfo: false,
      pageLoader: false,
      assetDistribustionLoader: false,
      leaseDistribustionLoader: false,
      invoiceTypeSummaryLoader: false,
      invoiceSummaryLoader: false,
      assetDistribustion: {},
      leaseDistribustion: {},
      invoiceTypeSummary: {},
      invoiceSummary: {},
      listOfCertificates: [],
      filter: {},
      appliedFilter: {},
      certificateTableLoader: false,
      dropdownOpen: false,
      lowUtilsList: [],
      filterQuerry:{},
      lowUtilsYear: { label: moment().format('YYYY'), value: moment().format('YYYY') },
      lowUtlisRange: [{ label: "Low utilization (0.01 - 10)", value: 0 }, { label: "No utilization", value: 1 }],
      selectedRange: null

    };
    this.getAssetDistribustionApi = getAssetDistribustionApi.bind(this);
    this.getLeaseDistribustionApi = getLeaseDistribustionApi.bind(this);
    this.getInvoiceSummaryApi = getInvoiceSummaryApi.bind(this);
    this.getInvoiceTypeSummaryApi = getInvoiceTypeSummaryApi.bind(this);
    this.getExpirationCertificateApi = getExpirationCertificateApi.bind(this)
    this.exportExpirationCertificateApi = exportExpirationCertificateApi.bind(this)
    this.lowUtilsListApi = lowUtilsListApi.bind(this)
    this.exportLowUtilsListApi = exportLowUtilsListApi.bind(this)
  }
  componentDidMount() {
    this.props.fetchContractWidget();
    this.props.fetchContractExpiry();
    this.props.fetchInvoiceWidget();
    this.props.fetchMsnList();
    this.props.fetchCashflowPayment();
    this.props.fetchAssetInfo({ application: 3 });
    this.getAssetDistribustionApi();
    this.getLeaseDistribustionApi();
    this.getInvoiceSummaryApi();
    this.getInvoiceTypeSummaryApi();
    this.getExpirationCertificateApi();
    this.lowUtilsListApi({ year: this.state.lowUtilsYear?.value });
    trackActivity('Page Visited', {
      application_module: 'Dashboards',
      asset: 'All',
      page_title: 'Contract Dashboard'
    })
  }
  toggleUtilizationTrend(tab) {
    if (this.state.utilizationTrendTab !== tab) {
      this.setState({
        utilizationTrendTab: tab
      });
    }
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.getExpirationCertificateApi(getQueryStr);
    this.setState({filterQuerry:getQueryStr})
    this.setState({
      appliedFilter: filterParams,
      selectedFilterTitle: '',
      page: 1
    });
  }

  changeFilterFn = (...args) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'asset_type':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Asset Type ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'asset_model':
        if (args[0]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Asset Model', value: args[1],
              filterData: args[1]
            }
          }
        }
        break;
      case 'certificate':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Certificate Type', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'serial_number':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Serial Number ', value: args[1],
              filterData: args[1]
            }
          }
        }
        break;
      case 'months':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Months', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'lessor':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Lessor', value: args[1],
              filterData: args[1]
            }
          }
        }
        break;
      case 'lessee':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Lessee ', value: args[1],
              filterData: args[1]
            }
          }
        }
      default:
        break;
    }
    this.setState({
      appliedFilter: filterParams,
      selectedFilterTitle: '',
      page: 1
    });

    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.setState({filterQuerry:getQueryStr})
    this.getExpirationCertificateApi(getQueryStr);
  }
  getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if (key == 'date') {
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      }
      else {
        queryFilter[key] = params[key].filterData
      }
    });
    return queryFilter;
  }
  changeUtilsYearFilter = (object) => {
    this.setState({ lowUtilsYear: object })
    this.lowUtilsListApi({ year: object?.value, severity: this.state.selectedRange?.value })
  }

  UtilsRangeFilter = (evt) => {
    if (evt === null) {
      this.setState({ selectedRange: null });
      this.lowUtilsListApi({ year: this.state.lowUtilsYear?.value });
    } else {
      const selectedRange = this.state.lowUtlisRange.find(option => option.value === evt.value);
      this.setState({ selectedRange });
      if (evt.value === 1) {
        this.lowUtilsListApi({ severity: 1, year: this.state.lowUtilsYear?.value });
      }
      if (evt.value === 0) {
        this.lowUtilsListApi({ severity: 0, year: this.state.lowUtilsYear?.value });
      }
    }
  }
  tabIndexLayout = (label, index) => {
    return (
      <NavItem>
        <NavLink
          className={classnames({ active: this.state.utilizationTrendTab === index })}
          onClick={() => { this.toggleUtilizationTrend(index); }}
        >
          {label}
        </NavLink>
      </NavItem>
    )
  }
  render() {
    const { contractMsn, msnUtilType, contractCardWidget, assetsInfoWidget, cashflowPayment, cashflowSummaryWidget, invoiceSummaryWidget, contractExpiryWidget, utilsSummaryWidget, msnCashflowSlug, msnUtilSlug, dbContractLoader, dbfleetGraphLoader, dbInvoiceLoader, utilSummaryLoader, dbAssetLoader, utilSumLoader, } = this.props;
    const { pageLoader, invoiceSummaryLoader, invoiceTypeSummaryLoader, assetDistribustionLoader, leaseDistribustionLoader, listOfCertificates, filter, appliedFilter, certificateTableLoader, lowUtilsList } = this.state
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: contractExpiryWidget.length <= 3 ? contractExpiryWidget.length : 4,
      slidesToScroll: 1,
      variableWidth: true,
    };
    var assetTypes = ['Aircraft', 'Engine', 'APU', 'Landing Gears', 'Propeller']
    var assetOptions = []
    for (let i = 0; i < 5; i++) {
      assetOptions[i] = { label: assetTypes[i], value: i + 1 }
    }
    var filterMenu = {
      'Asset Type': {
        'inputType': 'dropdown',
        'keyParam': 'asset_type',
        'placeholder': 'Select type of Asset',
        'options': assetOptions,
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Asset Model': {
        'inputType': 'text',
        'keyParam': 'asset_model',
        'placeholder': 'Enter Asset Model'
      },
      'Certificate Type': {
        'inputType': 'dropdown',
        'keyParam': 'certificate',
        'placeholder': 'Select type of Certificate',
        'options': [{ label: 'CoI', value: 'CoI' }, { label: 'CoA', value: 'CoA' }, { label: 'CoR', value: 'CoR' }],
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Serial Number': {
        'inputType': 'text',
        'keyParam': 'serial_number',
        'placeholder': 'Enter Serial Number',
      },
      'Lessee': {
        'inputType': 'text',
        'keyParam': 'lessee',
        'placeholder': 'Enter Lessee',
      },
    };

    if(jetStreamInstance){
      filterMenu={
        ...filterMenu,
        'Lessor/Holdco': {
          'inputType': 'text',
          'keyParam': 'lessor',
          'placeholder': 'Select Lessor/Holdco'
        },
      }
    }else{
      filterMenu={
        ...filterMenu,
        'Lessor': {
          'inputType': 'text',
          'keyParam': 'lessor',
          'placeholder': 'Select Lessor'
        },
      }
    }
    let assetWidgetCard
    if (getLocalStorageInfo().defaultLessor.id === 348) {
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length - assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing', ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count' },
        { title: 'OPERATIONAL', managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1', managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease' },
        { title: 'AOG', ownedUrl: '/assets-listing?status=3&ownership_type=1', managedUrl: '/assets-listing?status=3&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground' },
      ]
    } else {
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length, url: '/assets-listing', ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count' },
        { title: 'ON LEASE', managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1', managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease' },
        { title: 'OFF LEASE', ownedUrl: '/assets-listing?status=2&ownership_type=1', managedUrl: '/assets-listing?status=2&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 2).length, url: '/assets-listing?status=2', classes: 'widget-cards marginRight marginLeft flex-centered off-lease' },
        { title: 'ON GROUND', ownedUrl: '/assets-listing?status=3&ownership_type=1', managedUrl: '/assets-listing?status=3&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground' },
        { title: 'WRITTEN OFF / SOLD', ownedUrl: '/assets-listing?status=4&ownership_type=1', managedUrl: '/assets-listing?status=4&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing?status=4', classes: 'widget-cards marginLeft flex-centered written-off' }
      ]
    }
    const contractWidgetCard = [
      { title: 'Contracts Total', value: contractCardWidget.totalContract, classes: ' paddingRight', style: { background: '#1c79eb' } },
      { title: 'Active / Published', value: contractCardWidget.published, classes: ' paddingLeft paddingRight', style: { background: '#3785fb' } },
      { title: 'In Draft Status', value: contractCardWidget.draft, classes: ' paddingLeft paddingRight', style: { background: '#6613b2' } },
      { title: 'Expiry in the next 6 months', value: contractCardWidget.aboutToExpire, classes: ' paddingLeft', style: { background: '#f4211f' } },
    ]
    var showSplitTabs = false
    assetWidgetCard.map(item => {
      showSplitTabs = (item.managed > 0) ? true : showSplitTabs
      return item
    })
    let lowUtilsYears = []
    if (lowUtilsList?.start_year) {
      for (let startYear = lowUtilsList?.start_year; startYear <= lowUtilsList?.end_year; startYear++) {
        lowUtilsYears.push({ label: startYear, value: startYear })
      }
    }
    return (
      <div className="fleet-contracts-db">
        <DeploymentMessage />
        <Row>
          <Col md={6} className="p-3">
            { assetDistribustionLoader ? <Skeleton variant="rect" animation='wave' height={304} /> :
                <AssetsDistribustion assetDistribustion={this.state.assetDistribustion} />
            }
          </Col>
          <Col md={6} className="p-3">
            {leaseDistribustionLoader ? <Skeleton variant="rect" animation='wave' height={304} /> :
                <LeaseDistribustion leaseDistribustion={this.state.leaseDistribustion} />
            }
          </Col>
          {checkPermission('contracts', 'invoices','R') ?
            <>
              <Col md={6} className="p-3">
                { invoiceSummaryLoader ? <Skeleton variant="rect" animation='wave' height={304} /> :
                    <InvoiceStatus invoiceSummary={this.state.invoiceSummary} />
                }
              </Col>
              <Col md={6} className="p-3">
                {invoiceTypeSummaryLoader ? <Skeleton variant="rect" animation='wave' height={304} /> :
                    <InvoiceTypeSummary invoiceTypeSummary={this.state.invoiceTypeSummary} />
                }
              </Col>
            </>:null
          }
        </Row>
        {checkPermission('contracts','cashflow','R') ?
          <Row>
            <Col md={3}>
              <div className="cashflow-summary-widget" style={{ width: '100%' }}>
                <h4 className="contract-title-bar">Cashflow Summary of All Assets</h4>
                <Row style={{ marginRight: '0px' }}>
                  <Col md="12" className="pie-chart-card flex-centered">
                    {Object.keys(cashflowPayment).length ?
                      <CashflowPieChart cashflowPayment={cashflowPayment} /> :
                      <span style={{ color: '#ffffff', justifyContent: 'center', dispaly: 'block', textAlign: 'center', width: '100%' }}>Loading...</span>
                    }
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={9}>
              <div className="cashflow-summary-widget" style={{ width: '100%' }}>
                <h4 className="contract-title-bar">Cashflow Summary Graph</h4>
                <Row style={{ marginRight: '0px' }}>
                  <Col md="12" className="padding-left-0">
                    <ContractDateFilter
                      data={cashflowSummaryWidget}
                      contractMsn={contractMsn}
                      asset_type={msnUtilType}
                      startDateParam='fleetstartDateErr'
                      endDateParam='fleetEndDateErr'
                      filterCallParam='fleetSummaryWidget'
                      fetchCashflow={this.props.fetchCashflow}
                      disabledAttr={!cashflowSummaryWidget.plots ? true : false}
                      assetSlug={msnCashflowSlug}
                      slugType='msnCashflowSlug'
                      text="Cashflow"
                    />
                    {!dbfleetGraphLoader ?
                      <DbGraphCard
                        graphType='area'
                        graphTitle=''
                        graphxLabel=''
                        graphyLabel=''
                        cashflowSum={true}
                        lessorDetail={!dbfleetGraphLoader ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0] : {}}
                        contractMsn={!dbfleetGraphLoader && contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0].serial_number : ''}
                        headingText='Cashflow Summary'
                        series={
                          [{
                            showInLegend: false,
                            name: 'Cashflow',
                            color: '#80d1a0',
                            lineColor: '#46bd75',
                            data: cashflowSummaryWidget.plots && Object.keys(cashflowSummaryWidget.plots).map(year => { return { x: new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime(), y: cashflowSummaryWidget.plots[year] } })
                          }]
                        }
                      /> : null
                    }
                    {dbfleetGraphLoader ?
                      <span style={{ color: '#000', justifyContent: 'center', dispaly: 'block', textAlign: 'center', width: '100%', display: 'block', padding: '138px 0px' }}>Loading...</span>
                      : !(cashflowSummaryWidget.plots && Object.keys(cashflowSummaryWidget.plots).length) ? null : null
                    }
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>:null
        }
        { checkPermission('contracts','invoices','R') ?
          <Row>
            <Col md={3}>
              <div className="invoice-summary-widget" style={{ width: '100%' }} >
                <h4 className="contract-title-bar">MR & Rental Invoice Summary Of All Assets</h4>
                <Row>
                  <div style={{ width: '95.5%' }}>
                    <InvoiceCard dbInvoiceLoader={dbInvoiceLoader} invoiceSummaryWidget={invoiceSummaryWidget} col={12} />
                  </div>

                </Row>
              </div>
            </Col>
            <Col md={9}>
              <div className="invoice-summary-widget" style={{ margin: '0px', padding: '0px 0px 15px 15px' }}>
                <h4 className="contract-title-bar">Recent 20 Invoices</h4>
                <Row>
                  <Col md="12" className="padding-left-0">
                    {invoiceSummaryWidget.list && invoiceSummaryWidget.list.length && !dbInvoiceLoader ?
                      <div className="table-responsive table-height" style={{ height: '312px' }}>
                        <table className="table-sticky">
                          <thead>
                            <tr>
                              {['Asset', 'Asset Type', 'Lessee/Operator', 'Invoice No.', 'Type', 'Amount ( USD )', 'Due Date', 'Invoice Date', 'Invoice Status', 'Export'].map((label, index) => <th key={index}>{label}</th>)}
                            </tr>
                          </thead>
                          {invoiceSummaryWidget.list && invoiceSummaryWidget.list.length && !dbInvoiceLoader ?
                            <tbody>
                              {invoiceSummaryWidget.list.map((invoice, index) =>
                                <InvoiceWidgetList key={index} invoice={invoice} exportInvoice={() => this.props.detailInvExport(invoice)} />
                              )}
                            </tbody>
                            : null
                          }
                        </table>
                      </div> : null
                    }
                    {(!dbInvoiceLoader) && (!invoiceSummaryWidget.list || invoiceSummaryWidget.list && !invoiceSummaryWidget.list.length) ?
                      <NoRecordFound /> : !invoiceSummaryWidget.list ?
                        <span style={{ color: '#000', justifyContent: 'center', dispaly: 'block', textAlign: 'center', width: '100%', display: 'block', padding: ' 180px 0' }}>Loading...</span>
                        : null
                    }
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>:null
        }
        {contractExpiryWidget.length ?
          <div className="contract-expiry-widget">
            <h4 className="contract-title-bar"> Contracts expiring in the next <b style={{ color: '#ff1818', fontSize: '17px' }}> 6 </b>months </h4>
            <Slider {...settings} className="airframe-cards flex-not-centered">
              {contractExpiryWidget.map((contract, index) =>
                <ContractExpiryCard contract={contract} key={index} />
              )}
            </Slider>
          </div> : null
        }
        <div>
          <h4 className="contract-title-bar" style={{ display: 'flex', alignItems: 'center' }}>Expiration of Certificates in
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '59px', margin: '0px 3px 0px 3px' }}>
              <span className="select-contract-block flex-centered" style={{ width: '59px' }}>
                <Select
                  name="form-field-name"
                  placeholder="Select Months"
                  value={appliedFilter && appliedFilter.months && appliedFilter.months.value ? appliedFilter.months.value : 3}
                  labelKey='label'
                  valueKey='value'
                  options={months}
                  onChange={(evt) => this.changeFilterFn('months', evt)}
                  clearable={false}
                  style={{ width: '59px' }}
                />
              </span>
            </div>
            months</h4>
          <div className="invoice-summary-widget dashboaard_certificate_list" style={{ marginLeft: '0px', marginBottom: '24px', height: '384px' }}>
            <Row>
              <Col sm={12}>
                <div style={{ margin: '7px' }}>
                  <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={filterMenu} changeFilterFn={this.changeFilterFn} />
                </div>
                { listOfCertificates?.list?.length && checkPermission('contracts','lease_details','EXP') ?
                    <ul className="list-inline tab-right flex-centered" style={{ float: 'right' }}>
                      <li className="list-inline-item download-block">
                        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                          <DropdownToggle caret size="sm">
                            <span><img width="12" src={exportIcon} alt="export" />  Export List </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => this.exportExpirationCertificateApi('xls', this.state.filterQuerry)} ><img src={imgStoragePath + "excel_icon.png"} alt="img" />Excel</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </li>
                    </ul>
                    : null
                }
              </Col>
              <Col sm={12}>
                <div className="table-responsive table-height" style={{ height: '312px' }}>
                  <table className="table-sticky">
                    <thead>
                      <tr>
                        {['Asset', 'Asset Model', 'Certificate Type', 'Expiry Date', 'Lessor', 'Lessee'].map((label, index) => <th key={index}>{label}</th>)}
                      </tr>
                    </thead>
                    {listOfCertificates.list && listOfCertificates.list.length ?
                      <tbody>
                        {listOfCertificates.list.map((item, index) =>
                          <ExpirationofCertificates item={item} index={index} />
                        )}
                      </tbody>
                      : null
                    }

                  </table>

                  {(!certificateTableLoader) && (!listOfCertificates.list || listOfCertificates.list && !listOfCertificates.list.length) ?
                    <NoRecordFound /> : !listOfCertificates.list ?
                      <span style={{ color: '#000', justifyContent: 'center', dispaly: 'block', textAlign: 'center', width: '100%', display: 'block', padding: ' 180px 0' }}>Loading...</span>
                      : null
                  }
                  {
                    certificateTableLoader ? <ListLoader /> : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {checkPermission('contracts','utilization','R') ?
          <div className="utilization-widget-cn low-utils-listing">
            <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="contract-title-bar">
              <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '0px 3px 0px 3px', alignItems: 'center' }}>
                Low Utilization List of Year &nbsp;
                <Select
                  name="low-utils-list"
                  placeholder="Select Year"
                  value={this.state.lowUtilsYear}
                  labelKey='label'
                  valueKey='value'
                  options={lowUtilsYears}
                  onChange={(evt) => this.changeUtilsYearFilter(evt)}
                  clearable={false}
                  style={{ width: '125px' }}
                />
                <h4 style={{ fontSize: '16px', margin: '0px 10px' }}>Utilization Range</h4>
                <Select
                  name="low-utils-range"
                  placeholder={<span style={{fontSize:"13px"}}>Select Range</span>}
                  value={this.state.selectedRange}
                  labelKey='label'
                  valueKey='value'
                  options={this.state.lowUtlisRange}
                  onChange={(evt) => this.UtilsRangeFilter(evt)}
                  clearable={true}
                  style={{ width: '240px' }}
                />
              </div>
              { checkPermission('contracts','lease_details','EXP') ?
                <Button disabled={lowUtilsList?.list?.length ? false : true} onClick={() => this.exportLowUtilsListApi({ year: this.state.lowUtilsYear.value, severity: this.state.selectedRange?.value })} >
                  <span style={{ color: '#3f51b5', fontSize: '13px' }}><img width="17" src={exportIcon} alt="export" />  Export List </span>
                </Button>:null
              }
            </h4>
            {lowUtilsList?.list?.length ? null :pageLoader ? <span style={{ height: '100px', alignContent: 'center', position: 'relative', display: 'grid', left: '48%' }}>Loading...</span>:<NoRecordFound /> }
            <Paper style={{ marginBottom: '42px' }}>
              { lowUtilsList?.list?.length ?
                <TableHead className="mui-table" style={{ display: 'inline-table', width: '100%' }}>
                  <TableRow className="row-head">
                    <TableCell style={{ width: '10%', color: '#6d9bb9', fontWeight: '600', fontSize: '13px', }}> Month</TableCell>
                    <TableCell style={{ width: '10%', color: '#6d9bb9', fontWeight: '600', fontSize: '13px', }}> Asset Type</TableCell>
                    <TableCell style={{ width: '20%', color: '#6d9bb9', fontWeight: '600', fontSize: '13px', }}> Asset Serial Number</TableCell>
                    <TableCell style={{ width: '20%', color: '#6d9bb9', fontWeight: '600', fontSize: '13px', }}> Lessee</TableCell>
                    <TableCell style={{ width: '15%', color: '#6d9bb9', fontWeight: '600', fontSize: '13px', }}> Lessor</TableCell>
                    <TableCell style={{ width: '15%', color: '#6d9bb9', fontWeight: '600', fontSize: '13px', }}> Cycles</TableCell>
                    <TableCell style={{ width: '10%', color: '#6d9bb9', fontWeight: '600', fontSize: '13px', }}> Hours</TableCell>
                  </TableRow>
                </TableHead>
                : null
              }
              {lowUtilsList?.list?.map((pItem, index) =>
                <TableBody className='low-utils-list-body' style={{ display: 'inline-table', width: '100%', borderBottom: '1px solid #e0e0e0' }}>
                  { pItem?.data?.map((item, index) =>
                    <TableRow className='low-utils-list-row'>
                      <TableCell style={{ width: '10%', borderRight: '1px solid #e0e0e0', borderBottom: 'none' }} >
                        { index == 0 ? <span>{moment().month(pItem?.month-1).format('MMM')} <span>{pItem.year}</span></span>: null}
                      </TableCell>
                      <TableCell style={{ width: '10%' }} >
                        <Link to={`${config.domain.subDomian}technical-specification/${item?.asset_type == 1 ? 'aircraft' : 'engine'}/${item.asset_slug}`} target='_blank'>
                          <span className={item.severity == 0 ? 'lowUtlis redirection' : 'zeroUtils redirection'}> {item?.asset_type == 1 ? 'Aircraft' : 'Engine'}</span>
                        </Link>
                      </TableCell>
                      <TableCell style={{ width: '20%' }} >
                        <Link to={`${config.domain.lease}${item?.asset_type == 1 ? 'aircraft' : 'engine'}/${item.asset_slug}/contract/${item?.lease_slug}/utilization`} target='_blank'>
                          <span className={item.severity == 0 ? 'lowUtlis redirection' : 'zeroUtils redirection'}> {item.name}</span>
                        </Link>
                      </TableCell >
                      <TableCell style={{ width: '20%' }} className={item.severity == 0 ? 'lowUtlis' : 'zeroUtils'}> {item.lessee} </TableCell>
                      <TableCell style={{ width: '15%' }} className={item.severity == 0 ? 'lowUtlis' : 'zeroUtils'}> {item.lessor} </TableCell>
                      <TableCell style={{ width: '15%' }} className={item.severity == 0 ? 'lowUtlis' : 'zeroUtils'}> {item.cycles} </TableCell>
                      <TableCell style={{ width: '10%' }} className={item.severity == 0 ? 'lowUtlis' : 'zeroUtils'}> {item.hours} </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Paper>    
          </div>
          :null
        }
        
        {this.state.pageLoader ? <ListLoader /> : null}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  assetsInfoWidget: state.DashboardReducer.assetsInfoWidget,
  utilSumLoader: state.DashboardReducer.utilSumLoader,
  contractMsn: state.DashboardReducer.contractMsn,
  contractCardWidget: state.DashboardReducer.contractCardWidget,
  cashflowSummaryWidget: state.DashboardReducer.cashflowSummaryWidget,
  cashflowPayment: state.DashboardReducer.cashflowPayment,
  invoiceSummaryWidget: state.DashboardReducer.invoiceSummaryWidget,
  contractExpiryWidget: state.DashboardReducer.contractExpiryWidget,
  utilsSummaryWidget: state.DashboardReducer.utilsSummaryWidget,
  utilsTrendWidget: state.DashboardReducer.utilsTrendWidget,
  msnCashflowSlug: state.DashboardReducer.msnCashflowSlug,
  msnUtilType: state.DashboardReducer.msnUtilType,
  msnUtilSlug: state.DashboardReducer.msnUtilSlug,
  dbContractLoader: state.DashboardReducer.dbContractLoader,
  dbfleetGraphLoader: state.DashboardReducer.dbfleetGraphLoader,
  dbInvoiceLoader: state.DashboardReducer.dbInvoiceLoader,
  utilSummaryLoader: state.DashboardReducer.utilSummaryLoader,
  dbcashFLowTotalLoader: state.DashboardReducer.dbcashFLowTotalLoader,
  dbAssetLoader: state.DashboardReducer.dbAssetLoader,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: (queryParam) => dispatch(assetsInfoWidgetAc(queryParam)),
    fetchMsnList: () => dispatch(contractBasedMsnAc()),
    fetchUtilisationSummary: (slug = {}) => dispatch(utilizationSummaryWidgetAc(slug)),
    fetchContractWidget: () => dispatch(contractCardWidgetAc()),
    fetchContractExpiry: () => dispatch(contractExpiryWidgetAc()),
    fetchInvoiceWidget: () => dispatch(invoiceSummaryWidgetAc()),
    fetchCashflowPayment: () => dispatch(cashflowPaymentAc()),
    fetchCashflow: (slug = {}) => dispatch(cashflowSummarydbWidgetAc(slug)),
    detailInvExport: (invoice) => dispatch(detailInvExportAc(invoice)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ContractsFleetDb, ['contracts', 'dashboard', 'R']));
