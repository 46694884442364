import React, { Component } from 'react'
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { displayDateFormat, backendDateFormat, days, securityGroups, imgStoragePath } from '../../../../constants';
import { titanInstance } from '../../../../shared_elements/components';
export default class LeaseRentalEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rentalInvoiceView: {},
            adminFlag: false,
            contracterFlag: false,
        }
    }
    componentDidMount() {
        let data = JSON.parse(JSON.stringify(this.props.rentalInvoiceView));
        delete data['lease_type'];
        delete data['lease_type_accounting'];
        this.setState({
            rentalInvoiceView: data
        });
    }
    updateForm = (type, value) => {
        this.setState(prevState => ({
            ...prevState,
            rentalInvoiceView: {
                ...prevState.rentalInvoiceView,
                [type]: value
            }
        }))
    }
    updateRentalInvoice = (e) => {
        e.preventDefault();
        const {rentalInvoiceView}= this.state
        let data = Object.assign({}, this.state.rentalInvoiceView);
        
        let payload = {
            rental_invoice_day_of_month: rentalInvoiceView.rental_invoice_day_of_month ? rentalInvoiceView.rental_invoice_day_of_month :null,
            rental_invoice_due_day_of_month: rentalInvoiceView.rental_invoice_due_day_of_month? rentalInvoiceView.rental_invoice_due_day_of_month: null,
            rental_invoice_is_due_day_next_month: rentalInvoiceView.rental_invoice_is_due_day_next_month,
            rental_description: rentalInvoiceView.rental_description
        }

        if(titanInstance){
            payload = {...payload, remarks : rentalInvoiceView.remarks_rental, reference_number:rentalInvoiceView.reference_number_rental}
        }
     
        data = {
            ...data,
            is_rental: true
        }
        this.props.updateRentalInvoice(payload);
    }
    render() {
        const { rentalInvoiceView, adminFlag, contracterFlag } = this.state;
        const { type } = this.props;
        return (
            <form onSubmit={(e) => this.updateRentalInvoice(e)}>
                <div style={{ height: (window.innerHeight - 180) + 'px', overflow: 'auto', marginBottom: '60px' }}>
                    <Row>
                        <FieldCol md="12">
                            <FieldGroup className="form-group" >
                                <FieldLabel className="label">
                                    Invoice Generation Day ( For Every Month )
                                </FieldLabel>
                                <FieldSelect
                                    placeholder='Select Day'
                                    keyParam="rental_invoice_day_of_month"
                                    value={rentalInvoiceView.rental_invoice_day_of_month}
                                    type={type}
                                    options={days}
                                    updateField={this.updateForm}
                                    labelKey='label'
                                    valueKey='value'
                                />
                            </FieldGroup>

                        </FieldCol>
                        <FieldCol md="12">
                            <FieldGroup className="form-group" >
                                <FieldLabel className="label">Invoice Due Day ( For Every Month )</FieldLabel>
                                <FieldSelect
                                    placeholder='Select Day'
                                    keyParam="rental_invoice_due_day_of_month"
                                    value={rentalInvoiceView.rental_invoice_due_day_of_month}
                                    type={type}
                                    options={days}
                                    updateField={this.updateForm}
                                    labelKey='label'
                                    valueKey='value'
                                />
                            </FieldGroup>
                        </FieldCol>
                        <Col md="12">
                            <div className="form-group" >
                                <label className="label">
                                    <input type="checkbox" name="mr-invoice" checked={rentalInvoiceView.rental_invoice_is_due_day_next_month} onChange={(e) => this.updateForm('rental_invoice_is_due_day_next_month', e.target.checked)} />
                                    <span> Invoice Due Day falls in next Month</span>
                                </label>
                            </div>

                        </Col>
                        <FieldCol md="12">
                            <FieldGroup className="form-group" >
                                <FieldLabel className="label">Description</FieldLabel>
                                <FieldTextarea
                                    value={rentalInvoiceView.rental_description}
                                    type={type}
                                    keyParam="rental_description"
                                    updateField={this.updateForm}
                                />
                            </FieldGroup>
                        </FieldCol>
                        {titanInstance ? <><FieldCol md="12">
                            <FieldGroup className="form-group" >
                                <FieldLabel className="label">Reference Number</FieldLabel>
                                <FieldTextarea
                                    value={rentalInvoiceView.reference_number_rental}
                                    type={type}
                                    keyParam="reference_number_rental"
                                    updateField={this.updateForm}
                                />
                            </FieldGroup>
                        </FieldCol>   <FieldCol md="12">
                                <FieldGroup className="form-group" >
                                    <FieldLabel className="label">Remarks</FieldLabel>
                                    <FieldTextarea
                                        value={rentalInvoiceView.remarks_rental}
                                        type={type}
                                        keyParam="remarks_rental"
                                        updateField={this.updateForm}
                                        maxLength={500}
                                    />
                                </FieldGroup>
                            </FieldCol> </> : ""}
                     
                    </Row>
                </div>
                <div className="submit-block">
                    <input type="submit" className="primary-btn" value="Save Changes" />
                    <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
                </div>
            </form>
        )
    }
}
