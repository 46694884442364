import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { Header, EditSidebar, ContractHeadertext, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader } from '../../../../shared';
import { Link } from 'react-router';
import moment from 'moment';
import { imgStoragePath, displayDateFormat } from '../../../../constants';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate, FieldDateIndex } from '../../Elements';
import '../../assets/styles/MRRate.scss';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, ModalHeader, ModalBody, Modal } from 'reactstrap';
import { sExportContractsAc, sAuditLogAc, sGlobalConstantAc } from '../../../../shared/actionCreators';
import { contrResvRateListAc, contrResvRateViewAc, fetchRevisionHistoryAc, contrResvRateEditAc, contrInvcInfoAc, contrInvcInfoEditAc, mrRateCrudFnAc, fetchMUICPInflationAc, fetchAnnualRatioAc, addMUICPInflationAc, editMUICPInflationAc } from '../actionCreators';
import { utilsMajorAssemblyAc } from '../../Utilizations/actionCreators';
import { MRInvoiceConf, ReserveRateRow, HistoryList, EditMRRateForm, MRRateHeading, MRRateInvoiceInfo } from '../components';
import { CLOSE_RESERVE_RATE_SIDE_BAR, CLOSE_RESERVE_RATE_SIDE_BAR_INVOICE, MR_RATE_CRUD, TOGGLE_VIEW_EDIT, INFLATION_RATE_CRUD, INFLATION_RATE_UPDATE, TOGGLE_VIEW_EDIT_INFLATION, TOGGLE_REVISION_HISTORY } from '../actions';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import classnames from 'classnames';
import { CtrTableHd } from '../../../contracts/Elements/';
import { assetsType } from '../../../../constants';
import { NoRecordFound } from '../../../../shared';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { checkString, checkValidDate, checkFloat, checkNumber, checkDateDiffText, checkIntNumber } from '../../../../formValidator';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../shared/assets/img/view_icon.svg';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
import { fetchRentalAc } from '../../LeaseRentalRates/actionCreators';
import { contrHdlineInfoAc } from '../../HeadlinesInfo/actionCreators';
import EditReserveConfig from '../components/EditReserveConfig';

class MRRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editRateStatus: false,
      addForm: false,
      togleInflation: false,
      activeTab: '1',
      remarksError: '',
      remarks: '',
      remarkData: {},
      modal: false,
      error: {}
    }
  }
  componentDidMount() {
    this.props.fetchRentalInfo();
    this.props.fetchContractInfo();
    this.props.fetchMRRates();
    this.props.fetchContract();
    this.props.fetchMUICPInflation();
    this.props.utilsMajorAssemblyList();
    this.props.fetchAnnualRatio();
    this.props.fetchTechConstant({ constant_types: ['interval', 'mr_rate_range_condition', 'escalation_interval'] });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateFieldInflation = (key, value) => {
    this.updateErrorCode(key);
    this.props.updateInflation(key, value);

  }

  toggleReason = (data) => {
    this.setState({
      modal: !this.state.modal,
      remarkData: data,
      remarks: '',
      remarksError: ''
    });

  }

  updateErrorCode = (type) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [type]: ''
      }
    }));
  }
  handleRemarks = (e) => {
    let value = e.target.value;
    this.setState({
      remarks: value,
      remarksError: ''
    })
  }

  toggleModel = () => {
    this.setState({
      modal: !this.state.modal,
      remarks: '',
      remarksError: ''
    });
  }

  updateRejectStatus = () => {
    if (this.state.remarks != '') {
      let data = Object.assign({}, this.state.remarkData);
      data = {
        ...data,
        reason_for_reject: this.state.remarks
      }
      this.props.contrInvcInfoEdit(data);
      this.toggleModel();
    } else {
      this.setState({
        remarksError: 'Please enter the Reason'
      })
    }
  }

  toggleRevisionHistory = () => {
    this.props.fetchRevisionHistory(this.props.mrRateInvoiceInfo.data.id);
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  addInflationRate = (e) => {

    e.preventDefault();
    const validateNewInput = {
      year: checkIntNumber({ value: this.props.mrInflationCrud.data.year, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Year' }),
      annual_inflation: checkFloat({ value: this.props.mrInflationCrud.data.annual_inflation, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Annual Inflation' }),
      to_record_period: checkValidDate({ value: this.props.mrInflationCrud.data.to_record_period, required: true, minLength: '', maxLength: '', message: 'Please Enter To Date' }),
      from_record_period: checkValidDate({ value: this.props.mrInflationCrud.data.from_record_period, required: true, minLength: '', maxLength: '', message: 'Please Enter From Date' }),
      to_record_period: checkDateDiffText({ value: this.props.mrInflationCrud.data.from_record_period, required: true }, { value: this.props.mrInflationCrud.data.to_record_period, required: true }, 'From Date', 'To Date')
    };

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if (this.props.mrInflationCrud.type == 'add') {
        this.props.addInflationRate(this.props.mrInflationCrud.data);
      } else {
        this.props.editInflationRate(this.props.mrInflationCrud.data, this.props.mrInflationCrud.data.id);
      }

    } else {
      this.setState({
        error: validateNewInput
      });
    }
  }

  render() {
    const { mrRatesList, mrRatesView, mrRevisionList, revisionFlag, mrRateInvoiceInfo, reserveRateLoader, techConstants, utilsMajorAssembly, mrRateCrud, inflationList, annualRatioList, mrInflationCrud, auditLogView, params, contractInfo } = this.props;
    const { error } = this.state;
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application={`MR Rates`} type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <Link to={`/${params.type}/${params.aircraft_slug}/contract/list/${params.contract_slug}`} style={{ color: '#bd10e0', fontSize: '16px', fontWeight: '700' }}>
            <img width="16" src={backArrowIcon} alt="Back Icon" style={{ marginRight: '3px' }} /> Back to Lease Details
          </Link>
          <ContractHeadertext
            title="Reserve Rates"
            desc="Section holds all Reserve rates applicable under the Lease"
            addNew={checkPermission('contracts','reserve_rates','C') && contractInfo?.hdlineInfo?.archived_asset ==false? '+ Add Reserve Account' : ''}
            toggleAddEvent={() => this.props.mrRateCrudFn('add', {})}
            addAudit="Revision History"
            contractInfo={contractInfo?.hdlineInfo}
            toggleAuditlog={() => this.props.toggleAuditlogFn('contracts', 'ctreserveaccount', 0, null, 'add', this.props.params.contract_slug, 'contract')}
          />
          <div className="list-container">
            {reserveRateLoader || this.props?.contractSummaryLoader? <ListLoader /> : null}
            <div className="table-container">
              <table>
                <MRRateHeading />
                <tbody>
                  {mrRatesList.map((rate, index) => <ReserveRateRow viewMrRate={() => this.props.mrRateCrudFn('view', rate.id)} index={index} key={index} rate={rate} />)}
                  {!reserveRateLoader && !mrRatesList.length ? <tr><td className="text-center" colSpan="8" style={{ padding: '20px 0' }}>{contractInfo?.hdlineInfo?.is_reserve_rate ? `Reserve Rates are not applicable for this asset. Please uncheck "Reserve Rate Not Applicable" checkbox to add reserve Rates`: "No Reserve Rates Added Yet!"}</td></tr> : null}
                </tbody>
              </table>
            </div>
            <div className="edit-view-tech-spec mr-rental-tab">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                    Invoice Configuration
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >
                    MUICP Inflation Rates
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                  >
                    Annual FH/FC Ratio
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {Object.keys(mrRateInvoiceInfo.data).length ?
                    <MRRateInvoiceInfo
                    contractInfo={contractInfo}
                      mrRateInvoiceInfo={mrRateInvoiceInfo.data}
                      toggleEditSideBar={() => this.props.closeEditViewSideBar(true)}
                    /> : null
                  }
                  <div style={{ marginTop: '20px' }}>
                    <span className="warning-info-text" style={{display:'none'}}>Please Note : Individual association of Bank Details associated with Reserve and / or Rental account is discontinued. Bank details would be associated with Lessor Instance, which can be configured under “Configure”. Please contact <a href="mailto:support@sparta.aero">support@sparta.aero</a> in case of any queries.</span>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  {checkPermission('contracts','reserve_rates','C') && contractInfo?.hdlineInfo?.archived_asset == false ? <Link className="insert-inflation" onClick={() => this.props.toggleAddInflation({ type: 'add', data: {}, flag: true })}>+ Add Inflation Rate</Link>:null}
                  <div className="table-container mr-inflation-table">
                    <div className="table-responsive table-height" style={{ height: '250px' }}>
                      <table className="table-sticky common-table">
                        <CtrTableHd theads={['Year', 'Recorded Period', 'Annual Inflation (%)']} />
                        <tbody>
                          {inflationList.length ?
                            inflationList.map((inflation, childIndex) =>
                              <tr key={childIndex} onClick={() => this.props.toggleAddInflation({ type: 'view', data: inflation, flag: true })}>
                                <td>
                                  {inflation.year ? inflation.year : '--'}
                                </td>
                                <td>
                                  {inflation.from_record_period ? moment(inflation.from_record_period).format(displayDateFormat) : '--'} to {inflation.to_record_period ? moment(inflation.to_record_period).format(displayDateFormat) : '--'}
                                </td>
                                <td>
                                  {inflation.annual_inflation ? inflation.annual_inflation : '--'}
                                </td>
                              </tr>
                            ) : null
                          }
                        </tbody>
                      </table>
                      {inflationList.length ? null : <NoRecordFound />}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="table-container mr-inflation-table">
                    <div className="table-responsive table-height" style={{ height: '250px' }}>
                      <table className="table-sticky common-table">
                        <CtrTableHd theads={['Recorded Period', 'Assembly', 'Annual FH/FC Ratio']} />
                        <tbody>
                          {annualRatioList.length ?
                            annualRatioList.map((annualRatio, childIndex) =>
                              <tr key={childIndex}>
                                <td>
                                  {annualRatio.from_date ? moment(annualRatio.from_date).format(displayDateFormat) : '--'} to {annualRatio.to_date ? moment(annualRatio.to_date).format(displayDateFormat) : '--'}
                                </td>
                                <td>
                                  {annualRatio.asset_name ? annualRatio.asset_name : '--'}
                                </td>
                                <td>
                                  {annualRatio.ratio ? annualRatio.ratio : '--'}
                                </td>
                              </tr>
                            ) : null
                          }
                        </tbody>
                      </table>
                      {annualRatioList.length ? null : <NoRecordFound />}
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
        <EditSidebar width="470px" right="-470px" title="Edit Reserve Rates Invoice Configuration" editSideBar={mrRateInvoiceInfo.flag} toggleEditSideBar={() => this.props.closeEditViewSideBar(false)}>
          {mrRateInvoiceInfo.flag && Object.keys(mrRateInvoiceInfo.data).length ?
            // <MRInvoiceConf
            <EditReserveConfig
              type="edit"
              contractInfo={contractInfo}
              contrInvcInfoEdit={this.props.contrInvcInfoEdit}
              mrRateInvoiceInfo={mrRateInvoiceInfo.data}
              toggleReason={this.toggleReason}
              toggleRevisionHistory={() => this.toggleRevisionHistory()}
              toggleEditSideBar={() => this.props.closeEditViewSideBar(false)}
            /> : null
          }
        </EditSidebar>

        <EditSidebar width="470px" right="-470px" title="Revision History" editSideBar={revisionFlag} toggleEditSideBar={() => this.props.closeRevisionSideBar(false)}>
          <div className="reviewer-issue-drawer">
            <div className="issues-listing" style={{ height: (window.innerHeight - 100) + 'px', overflow: 'auto', marginBottom: '60px' }}>
              <ul className="list-unstyled resolved-list">
                {mrRevisionList.map((item, index) =>
                  <HistoryList
                    key={index}
                    index={index}
                    details={item}
                  />
                )}
              </ul>
              {mrRevisionList.length ? null : <NoRecordFound />}
            </div>
          </div>
        </EditSidebar>

        <EditSidebar width="470px" right="-470px" title="Add Inflation Rates" editSideBar={mrInflationCrud.flag} toggleEditSideBar={() => {this.setState({error:{}});this.props.toggleAddInflation({ type: '', data: {}, flag: false })}}>
          {mrInflationCrud.type != 'add' && contractInfo?.hdlineInfo?.archived_asset ==false && checkPermission('contracts', 'reserve_rates','U')?
            <span onClick={() => {this.setState({error:{}});this.props.changeViewModeInflation(mrInflationCrud.type)}} style={{ position: 'absolute', right: '50px', top: '26px', cursor: 'pointer' }}>
              {mrInflationCrud.type == 'view'  ?
                <img className="blue-icon" src={editIcon} width="12" alt="icon" /> :
                <img className="blue-icon" src={viewIcon} width="16" alt="icon" />
              }
              <span className="blue-action">{mrInflationCrud.type == 'view' ? ' EDIT' : 'VIEW'}</span>
            </span> : null
          }
          <form onSubmit={(e) => this.addInflationRate(e, mrInflationCrud.data)}>
            <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
              <Row>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" required={true}>Year</FieldLabel>
                    <FieldInput
                      value={mrInflationCrud.data.year}
                      type={mrInflationCrud.type}
                      keyParam="year"
                      updateField={this.updateFieldInflation}
                    />
                    <h6 className="error-msg">{error.year}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" required={true}>Annual Inflation(%)</FieldLabel>
                    <FieldInput
                      value={mrInflationCrud.data.annual_inflation}
                      type={mrInflationCrud.type}
                      keyParam="annual_inflation"
                      updateField={this.updateFieldInflation}
                    />
                    <h6 className="error-msg">{error.annual_inflation}</h6>
                  </FieldGroup>
                </FieldCol>
              </Row>
              <Row>
                <FieldCol md="12">
                  <h3 style={{ borderBottom: 'none', margin: '10px 15px 28px 25px' }}>Inflation Period</h3>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" required={true}>From Date</FieldLabel>
                    <FieldDate
                      value={mrInflationCrud.data.from_record_period}
                      type={mrInflationCrud.type}
                      keyParam="from_record_period"
                      updateField={this.updateFieldInflation}
                    />
                    <h6 className="error-msg">{error.from_record_period}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" required={true}>To Date</FieldLabel>
                    <FieldDate
                      value={mrInflationCrud.data.to_record_period}
                      type={mrInflationCrud.type}
                      keyParam="to_record_period"
                      updateField={this.updateFieldInflation}
                    />
                    <h6 className="error-msg">{error.to_record_period}</h6>
                  </FieldGroup>
                </FieldCol>
              </Row>
            </div>
            {
              mrInflationCrud.type != 'view' ?
                <div className="submit-block">
                  <input type="submit" className="primary-btn" value="Save Changes" />
                  <Link onClick={() => {this.setState({error:{}});this.props.toggleAddInflation({ type: '', data: {}, flag: false })}} >Cancel</Link>
                </div> : null
            }
          </form>
        </EditSidebar>

        <Modal isOpen={this.state.modal} toggle={this.toggleModel} >
          <ModalHeader toggle={this.toggleModel}>Reason of Rejection</ModalHeader>
          <ModalBody>
            <textarea autofocus style={{ border: '1px solid #d7d7d7', width: '100%', height: '100px', borderRadius: '4px', padding: '5px 10px' }} onChange={this.handleRemarks} value={this.state.remarks}>
            </textarea>
            <h6 className="error-msg">{this.state.remarksError}</h6>
            <p>
              <button onClick={this.updateRejectStatus} style={{ float: 'right' }} className="primary-btn">SAVE</button>
            </p>
          </ModalBody>
        </Modal>


        <EditSidebar
          width="800px" right="-800px"
          title={
            mrRateCrud.type != '' ?
              (mrRateCrud.type == 'add') ? 'Add Reserve Rates' :
                (mrRateCrud.type == 'edit') ? 'Edit Reserve Rates' : 'View Reserve Rates' : ''
          }
          editSideBar={mrRateCrud.flag}
          techConstants={techConstants}
          toggleEditSideBar={() => this.props.toggleEditBar({ type: '', data: {}, flag: false })}
        >
          {
            Object.keys(mrRateCrud.data).length && mrRateCrud.flag ?
              <div>
                <FormModeType
                  auditLog={true}
                  showTechnicalSpec={true}
                  auditLogMode={() => this.props.toggleAuditlogFn('contracts', 'ctreserveaccount', 1, mrRateCrud.data.id, 'update')}
                  type={mrRateCrud.type}
                  changeViewMode={() => this.props.changeViewMode(mrRateCrud.type)}
                  editAble={checkPermission('contracts', 'reserve_rates','U') && contractInfo?.hdlineInfo?.archived_asset ==false? true : false}
                  assetType={this.props.params.type}
                  aircraftSlug={this.props.params.aircraft_slug}
                />
                <EditMRRateForm
                  rentalInfo={this.props.rentalInfo}
                  type={mrRateCrud.type}
                  addEditParty={this.props.addEditParty}
                  techConstants={techConstants}
                  mrRatesView={mrRateCrud.data}
                  updateMrRate={this.props.updateMrRate}
                  utilsMajorAssembly={utilsMajorAssembly}
                  toggleEditSideBar={() => this.props.toggleEditBar({ type: '', data: {}, flag: false })}
                  reserveRateLoader={reserveRateLoader}
                />
              </div> : null
          }
        </EditSidebar>
        <EditSidebar
          title="Revision History"
          width='800px'
          right='-800px'
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({ type: 'add', data: {}, flag: false })}
        >
          {Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
              <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '20px', padding: '5px 22px 5px 25px' }}>
                {auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                    <AddDeleteAudit auditLogView={item} key={index} />
                  ) :
                  auditLogView.data.map((item, index) =>
                    <UpdateAuditLog auditLogView={item} key={index} />
                  )
                }
              </div>
            </div>
            : <NoRecordFound title="No Revision History Found" />
          }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader:state.sharedReducers.contractSummaryLoader,
  rentalInfo: state.ContrLesRentalReducer.rentalInfo ,
  mrRatesList: state.ContrMRRateReducer.mrRatesList,
  mrRatesView: state.ContrMRRateReducer.mrRatesView,
  mrRateInvoiceInfo: state.ContrMRRateReducer.mrRateInvoiceInfo,
  reserveRateLoader: state.ContrMRRateReducer.reserveRateLoader,
  techConstants: state.sharedReducers.techConstants,
  utilsMajorAssembly: state.UtilsReducer.utilsMajorAssembly,
  mrRateCrud: state.ContrMRRateReducer.mrRateCrud,
  mrInflationCrud: state.ContrMRRateReducer.mrInflationCrud,
  inflationList: state.ContrMRRateReducer.inflationList,
  annualRatioList: state.ContrMRRateReducer.annualRatioList,
  mrRevisionList: state.ContrMRRateReducer.mrRevisionList,
  revisionFlag: state.ContrMRRateReducer.revisionFlag,
  auditLogView: state.sharedReducers.auditLogView,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,

});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchRentalInfo: () => dispatch(fetchRentalAc(ownProps.params)),
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchMRRates: () => dispatch(contrResvRateListAc(ownProps.params)),
    updateMrRate: (data, type) => dispatch(contrResvRateEditAc(ownProps.params, data, type)),
    viewMrRate: (id, index) => dispatch(contrResvRateViewAc(ownProps.params, id, index)),
    fetchRevisionHistory: (id) => dispatch(fetchRevisionHistoryAc(id)),
    mrRateCrudFn: (type, id, index) => dispatch(mrRateCrudFnAc(ownProps.params, type, id, index)),
    fetchContract: () => dispatch(contrInvcInfoAc(ownProps.params)),
    addInflationRate: (data) => dispatch(addMUICPInflationAc(ownProps.params, data)),
    editInflationRate: (data, id) => dispatch(editMUICPInflationAc(ownProps.params, data, id)),
    fetchMUICPInflation: () => dispatch(fetchMUICPInflationAc(ownProps.params)),
    fetchAnnualRatio: () => dispatch(fetchAnnualRatioAc(ownProps.params)),
    fetchTechConstant: (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    contrInvcInfoEdit: (data) => dispatch(contrInvcInfoEditAc(ownProps.params, data)),
    utilsMajorAssemblyList: () => dispatch(utilsMajorAssemblyAc(ownProps)),
    closeEditViewSideBar: (value) => dispatch({
      type: CLOSE_RESERVE_RATE_SIDE_BAR_INVOICE,
      payload: value
    }),
    closeRevisionSideBar: (value) => dispatch({
      type: TOGGLE_REVISION_HISTORY,
      payload: value
    }),
    closeMRRateSideBar: (type, data) => dispatch({
      type: CLOSE_RESERVE_RATE_SIDE_BAR,
      viewType: type,
      payload: data
    }),
    toggleEditBar: (data) => dispatch({
      type: MR_RATE_CRUD,
      payload: data
    }),
    toggleAddInflation: (data) => dispatch({
      type: INFLATION_RATE_CRUD,
      payload: data
    }),
    updateInflation: (key, value) => dispatch({
      type: INFLATION_RATE_UPDATE,
      payload: { key: key, value: value }
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    changeViewModeInflation: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT_INFLATION,
      payload: type
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
      exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
      toggleAuditlogFn: (application, content_type, action, objectId, type, pramKey = null, paramValue = null) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, pramKey, paramValue)),
    }
  }
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(MRRate, ['contracts', 'reserve_rates', 'R'])));
