import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { globalExportService, globalGetService, globalPostService } from '../../../globalServices'
import checkApiStatus from '../../../utils'
import { useSnackbar } from 'notistack'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AutocompleteCreatable, LabelValueCard, PageLoader } from '../../../shared_elements'
import { checkPermission, downloadFileType, getCurrencyFormat, getLocalStorageInfo } from '../../../utils_v2'
import moment from 'moment'
import NumericLabel from 'react-pretty-numbers';
import { backendDateFormat, displayDateFormat, displayMonthYear, fieldDateFormat } from '../../../constants'
import { browserHistory } from 'react-router'
import emailIcon from '../../../shared/assets/img/send_email_icon.svg';
import { regexConstants } from '../../../constants/regEx';
import MailIcon from '@material-ui/icons/Mail';
// import { InfoIcon } from '../../../shared'
import InfoIcon from '@material-ui/icons/Info';

const InvoiceItemList = ({ item, invoiceInfo }) => {
    return (
        <TableRow>
            <TableCell>
                <div>
                    {item?.asset || '--'}
                    <p>({item.invoice_number})</p>
                </div>
            </TableCell>
            {invoiceInfo.invoice_type.value === 2 ? <TableCell>{item?.invoice_item_type_id?.name || '--'}</TableCell> : null}
            <TableCell>{invoiceInfo.invoice_type.value === 2 ? `${item?.description || '--'}` : `Utilization rent for the month of : ${moment(invoiceInfo.end_date).format(displayMonthYear)}`}</TableCell>
            {/* <TableCell>{item?.unit_type || '--'}</TableCell> */}
            {/* <TableCell>{item?.no_of_unit || '--'}</TableCell> */}
            {/* <TableCell>{item?.rate_per_unit? parseFloat(item?.rate_per_unit).toFixed(2) : '--'}</TableCell> */}
            <TableCell>{item?.sub_total ? <NumericLabel params={getCurrencyFormat({ currency: invoiceInfo.currency, shortFormat: false })}>{parseFloat(item?.sub_total).toFixed(2)}</NumericLabel> : '--'}</TableCell>
        </TableRow>
    )
}
export default function SummaryInvoiceView({ params }) {
    useEffect(() => {
        getInvoiceDetails()
    }, [])
    const [invoiceInfo, setInvoiceInfo] = useState({})
    const [isLoading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openModal, setOpenModal] = useState(false);
    const [emailDetails, setEmailDetails] = useState({ to_address: '', subject: '', content: '', cc_address: '' });
    const [error, setError] = useState({});

    const [syncAgainFlag, setSyncAgainFlag] = useState({ flag: false, data: null });
    const getInvoiceDetails = () => {
        setLoading(true)
        globalGetService(`contracts/summary-invoice/${params.slug}/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setInvoiceInfo(response.data.data)
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const exportInvoice = () => {
        setLoading(true)
        globalExportService(`contracts/summary-invoice/${params.slug}/?download=pdf`)
            .then(response => {
                setLoading(false)
                downloadFileType(response, `Summary_invoice_${invoiceInfo?.invoice_number}`, 'pdf')
            })
    }
    const getShareInvoiceInfo = () => {
        setLoading(true)
        globalGetService(`contracts/summary-invoice/${params.slug}/initiate-mail/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    const data = response.data.data;
                    setEmailDetails({
                        to_address: data.to_address || [],
                        cc_address: data.cc_address || [],
                        subject: data.subject || '',
                        content: data.content || '',
                    });
                    setOpenModal(true);
                }
                else {
                    enqueueSnackbar(response.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const sendEmail = () => {
        let validationInputs = {
            to_address: !String(emailDetails?.to_address)?.length ? 'Please enter To Email(s)' : '',
            subject: String(emailDetails.subject).trim().length ? '' : 'Please enter Subject',
            content: String(emailDetails.content).trim().length ? '' : 'Please enter Content',
            cc_address: ''
        };
    
        const validateEmails = (emails, key) => {
            let invalidEmails = [];
            let uniqueEmails = new Set();
            let duplicateEmails = new Set();
    
            emails.forEach(email => {
                if (!regexConstants.email.test(email)) {
                    invalidEmails.push(email);
                } else {
                    if (uniqueEmails.has(email)) {
                        duplicateEmails.add(email);
                    } else {
                        uniqueEmails.add(email);
                    }
                }
            });
    
            return { invalidEmails, duplicateEmails: Array.from(duplicateEmails) };
        };
    
        if (emailDetails.to_address?.length) {
            const { invalidEmails, duplicateEmails } = validateEmails(emailDetails.to_address, 'to_address');
    
            if (invalidEmails.length) {
                validationInputs.to_address = `Invalid Emails for TO: "${invalidEmails.join(', ')}"`;
            }
            if (duplicateEmails.length) {
                validationInputs.to_address += validationInputs.to_address ? ' & ' : ''; 
                validationInputs.to_address += `Duplicate email(s) found in TO address: "${duplicateEmails.join(', ')}"`;
            }
        }
    
        if (emailDetails.cc_address?.length) {
            const { invalidEmails, duplicateEmails } = validateEmails(emailDetails.cc_address, 'cc_address');
    
            if (invalidEmails.length) {
                validationInputs.cc_address = `Invalid Emails for CC: "${invalidEmails.join(', ')}"`;
            }
            if (duplicateEmails.length) {
                validationInputs.cc_address += validationInputs.cc_address ? ' & ' : ''; 
                validationInputs.cc_address += `Duplicate email(s) found in CC address: "${duplicateEmails.join(', ')}"`;
            }
        }
    
        if (Object.keys(validationInputs).every(key => validationInputs[key] === '')) {
            setLoading(true);
            globalPostService(`contracts/summary-invoice/${params.slug}/send-mail/`, emailDetails)
                .then(response => {
                    setLoading(false);
                    if (checkApiStatus(response)) {
                        setOpenModal(false);
                        setError([])
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    }
                });
        } else {
            setError(validationInputs);
        }
    };
    
    


    const handleFieldChange = (key, value) => {
        if (key === 'cc_address' || key === 'to_address') {
            const newValue = value && Array.isArray(value) ? value.map(item => item.inputValue || item) : [];
            setEmailDetails({ ...emailDetails, [key]: newValue });
        } else {
            setEmailDetails({ ...emailDetails, [key]: value || '' });
        }
    };

    const resetErrorKey = (key) => { setError({ ...error, [key]: '' }) }
    const isSendEmailDisabled = !emailDetails.to_address?.length && !emailDetails.cc_address?.length && !emailDetails.subject?.trim().length && !emailDetails.content?.trim().length;

    const getThirdPartyAppUrl = (query) => {
        globalGetService(`contracts/app-plugin/quickbooks/authenticate/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    window.open(response.data.data.url, '_blank');
                }
            })
            .catch(() => {
                enqueueSnackbar('Error syncing with QuickBooks', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            });
    };

    const getThirdPartyFn = (data) => {
        if (invoiceInfo?.qb_last_sync_by?.name && invoiceInfo?.qb_last_sync_time) {
            setSyncAgainFlag({ flag: true, data });
        } else {
            getThirdPartyAppUrl(data);
        }
    };

    return (
        <div className='summary-invoice-container'>
            {isLoading ? <PageLoader /> : null}
            <div className='smr-inv-pg'>
                <Grid container spacing={1}>
                    <Grid item sm='8'>
                        <div style={{ cursor: "pointer", marginBottom: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}> <ArrowBackIcon color='primary' onClick={() => browserHistory.push('/invoices/?summary_invoice')} style={{ marginRight: '4px' }} /> Summary Invoice</div>
                            <div style={{ margin: '0px 25px', display: 'flex', alignItems: 'center' }}><h5 style={{ fontWeight: '500' }}>Invoice #{invoiceInfo?.invoice_number}</h5></div>
                        </div>
                    </Grid>
                    <Grid item sm='4' >
                        <ul className='list-inline flex-centered' style={{ float: 'right' }}>
                        {invoiceInfo?.status?.value !== 3 ?
                            <li className="list-inline-item">
                                <Button onClick={getShareInvoiceInfo} size='small' style={{ marginBottom: '13px' }} variant="outlined" color="primary" ><MailIcon fontSize='small' color='primary' style={{marginRight:'3px'}}/> Send Email</Button>
                            </li>:null}
                            {invoiceInfo?.status?.value === 2 && [13, 442].includes(getLocalStorageInfo().defaultLessor?.id) && invoiceInfo?.qb_enabled && invoiceInfo?.qb_enabled == true ?
                                <li className='list-inline-item'>
                                    <Button size='small' style={{ marginBottom: '13px' }} variant="outlined" color='primary' onClick={() => getThirdPartyFn({ summary_invoice_slug: invoiceInfo?.slug })} >Sync to QB</Button>
                                </li> : <li className='list-inline-item'>
                                    {invoiceInfo?.qb_enabled === false?
                                        <Tooltip disableInteractive title={<table>
                                            <tbody className='qb-status-tooltip' >
                                                <tr>
                                                    <td>Sync Status(QB):</td>
                                                    <td>Already Synchronized on QB</td>
                                                </tr>
                                                <tr>
                                                    <td>Last Updated By:&nbsp; </td>
                                                    <td>{invoiceInfo?.qb_last_sync_by?.name || '--'} {' '}({invoiceInfo?.qb_last_sync_time ? moment(invoiceInfo?.qb_last_sync_time).format('MMM DD, YYYY') : '--'})</td>
                                                </tr>
                                            </tbody>
                                        </table>} arrow placement='top'>
                                            <span style={{ marginBottom: '13px' }} >
                                                <InfoIcon fontSize='small' style={{ color: 'rgb(174 172 172)',marginBottom:'13px' }} />
                                            </span>
                                        </Tooltip>:null}
                                </li>}
                            <li className='list-inline-item'>
                                {checkPermission('contracts', 'summary_invoice', 'EXP') ?
                                    <Button size='small' style={{ marginBottom: '13px' }} variant="outlined" color="primary" onClick={() => { exportInvoice() }}>
                                        Export
                                    </Button> : null
                                }
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container style={{ marginLeft: '24px' }}>
                    <LabelValueCard md={3} label='Invoice Number' value={invoiceInfo?.invoice_number || '--'} />
                    <LabelValueCard md={2} label='Invoice Status' value={invoiceInfo?.status?.label || '--'} />
                    <LabelValueCard md={2} label='Invoice Date' value={invoiceInfo?.invoice_date ? moment(invoiceInfo?.invoice_date).format(displayDateFormat) : '--'} />
                    <LabelValueCard md={2} label='Invoice Due Date' value={invoiceInfo?.due_date ? moment(invoiceInfo?.due_date).format(displayDateFormat) : '--'} />
                </Grid>
                <Grid container spacing={1} style={{ margin: "20px 0px" }}>
                    <Grid item sm='6' style={{ paddingRight: '25px' }}>
                        <div className='from-lessor-card'>
                            <h4 className="heading-block">From Lessor/Holdco</h4>
                            <div className='lessee-lessor-data'>
                                <LabelValueCard md={12} label='Name' value={invoiceInfo?.from_company_name || '--'} />
                                <LabelValueCard md={12} label='Address' value={invoiceInfo?.from_company_address || '--'} />
                                <LabelValueCard md={12} label='Contact (Email and Phone)' value={invoiceInfo?.from_company_contact || '--'} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm='6'>
                        <div className='from-lessor-card'>
                            <h4 className="heading-block">To (Lessee)</h4>
                            <div className='lessee-lessor-data'>
                                <LabelValueCard md={12} label='Name' value={invoiceInfo?.to_company_name || '--'} />
                                <LabelValueCard md={12} label='Address' value={invoiceInfo?.to_company_address || '--'} />
                                <LabelValueCard md={12} label='Contact (Email and Phone)' value={invoiceInfo?.to_company_contact || '--'} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className='receiavables-head'>
                    {invoiceInfo?.currency === 'USD' ? 'USD' : invoiceInfo?.currency === 'EUR' ? 'EUR' : ''} Recievables
                </div>
                <Table className='inv-smr-list' style={{ marginBottom: '15px' }}>
                    <TableHead>
                        <TableRow >
                            <TableCell>Asset</TableCell>
                            {invoiceInfo.invoice_type?.value === 2 ? <TableCell>Item</TableCell> : null}
                            <TableCell>Description</TableCell>
                            {/* <TableCell>Unit Description	</TableCell> */}
                            {/* <TableCell>No of Units</TableCell> */}
                            {/* <TableCell>Fixed Rate ({invoiceInfo?.currency==='USD'? 'USD':'EUR'})	</TableCell> */}
                            <TableCell>Sub Total ({invoiceInfo?.currency === 'USD' ? 'USD' : invoiceInfo?.currency === 'EUR' ? 'EUR' : ''})</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{
                        invoiceInfo?.invoice_items?.map((item, index) =>
                            <InvoiceItemList
                                item={item}
                                index={index}
                                invoiceInfo={invoiceInfo}
                            />)}
                    </TableBody>
                </Table>
                <div className='sub-total-inv-smr' style={{ textAlign: 'right' }}>
                    <h5 style={{ marginBottom: '6px' }}>Billable Amount : {invoiceInfo?.currency === 'USD' ? 'US$' : invoiceInfo?.currency === 'EUR' ? '€' : ''}{invoiceInfo?.amount ? parseFloat(invoiceInfo?.amount).toFixed(2) : '0.00'}</h5>
                    <h4>Total :{invoiceInfo?.currency === 'USD' ? 'US$' : invoiceInfo?.currency === 'EUR' ? '€' : ''}{invoiceInfo?.amount ? parseFloat(invoiceInfo?.amount).toFixed(2) : '0.00'}</h4>
                </div>
                <div className='bank-details-head'>
                    <h5>Bank Detail For {invoiceInfo?.currency === 'USD' ? 'US$' : invoiceInfo?.currency === 'EUR' ? 'EUR' : ''} Rental Receivable</h5>
                </div>
                <Grid container spacing={1} className='bank-details-section-smr'>
                    <LabelValueCard md={3} label='Name' value={invoiceInfo?.bank_name || '--'} />
                    <LabelValueCard md={3} label='Account Name' value={invoiceInfo?.bank_account_name || '--'} />
                    <LabelValueCard md={3} label='Account Number' value={invoiceInfo?.bank_account_number || '--'} />
                    <LabelValueCard md={3} label='IBAN Code' value={invoiceInfo?.bank_iban || '--'} />
                    <LabelValueCard md={3} label='ABA Number' value={invoiceInfo?.aba_number || '--'} />
                    <LabelValueCard md={3} label='SWIFT Code' value={invoiceInfo?.bank_swift_code || '--'} />
                    <LabelValueCard md={3} label='SORT Code' value={invoiceInfo?.bank_sort_code || '--'} />
                    <LabelValueCard md={3} label='Footer Note' value={invoiceInfo?.footer_note || '--'} />
                </Grid>
            </div>
            <Dialog open={openModal} maxWidth={'md'} className='lease-management-modal' >
                <DialogTitle>Send Email</DialogTitle>
                <DialogContent dividers style={{ width: '650px' }}>
                    <Grid container spacing={2} >
                        <Grid item md={12}>
                            <AutocompleteCreatable
                                options={[]}
                                paramsKey="to_address"
                                value={emailDetails.to_address || []}
                                resetErrorKey={() => resetErrorKey('to_address')}
                                onFieldChange={(e, key, newValue) => handleFieldChange('to_address', newValue)}
                                optionKey='label'
                                label="To"
                                required={true}
                                error={error.to_address}
                                multiple={true}
                                helperText={error.to_address}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <AutocompleteCreatable
                                options={[]}
                                paramsKey="cc_address"
                                value={emailDetails.cc_address || []}
                                onFieldChange={(e, key, newValue) => handleFieldChange('cc_address', newValue)}
                                resetErrorKey={() => resetErrorKey('cc_address')}
                                optionKey='label'
                                label="CC"
                                // required={true}
                                error={error.cc_address}
                                multiple={true}
                                helperText={error.cc_address}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                required
                                label="Subject"
                                fullWidth
                                margin="normal"
                                name="subject"
                                InputLabelProps={{ shrink: true }}
                                value={emailDetails.subject || ''}
                                onChange={(e) => { handleFieldChange('subject', e.target.value); resetErrorKey('subject') }}
                                error={error.subject}
                                helperText={error.subject}
                                onFocus={() => resetErrorKey('subject')}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                required
                                label="Message"
                                fullWidth
                                margin="normal"
                                name="content"
                                InputLabelProps={{ shrink: true }}
                                value={emailDetails.content || ''}
                                onChange={(e) => { handleFieldChange('content', e.target.value); resetErrorKey('content') }}
                                error={error.content}
                                onFocus={() => resetErrorKey('content')}
                                helperText={error.content}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={isLoading} onClick={() => {setOpenModal(false);setEmailDetails({});setError(false)}} size='small' variant='outlined' color="primary">Cancel</Button>
                <Button disabled={isLoading || isSendEmailDisabled} onClick={sendEmail} variant='contained' size='small' color="primary" >Send Email</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
