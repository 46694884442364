import React from 'react';
import { TableRow, TableCell, TextField} from '@material-ui/core';
export default function CapeTownList({cape_town_clauses, flag, onFieldChange}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        { flag ?
          <TextField
            id="cape_town_clauses"
            fullWidth
            margin="normal"
            value={cape_town_clauses ? cape_town_clauses:''}
            InputLabelProps={{shrink: true}}
            multiline
            rows="6"
            onChange={(e) => onFieldChange(e, 'cape_town_clauses', e.target.value)}
          />:
          cape_town_clauses ? ( <span dangerouslySetInnerHTML={{ __html: cape_town_clauses.replace(/\n/g, '<br />')}}/>):'--'
        }
      </TableCell>
    </TableRow>
  )
}
