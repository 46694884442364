import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import { ordinalSuffixOf, getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import { InvoiceConfigCol } from '../../Elements';
import editIconWhite from '../../../../shared/assets/img/edit_icon_white.svg';
import { titanInstance } from '../../../../shared_elements/components';

export default class MRRateInvoiceInfo extends Component{
  constructor(props){
    super(props);
    this.state = {
      mrRateInvoiceInfo:{}
    }
  }

  componentDidMount(){
    let data = this.props.mrRateInvoiceInfo
    // data = {
    //   ...data,
    //   mr_bank_account_name: this.props.mrRateInvoiceInfo.bank_account_name !== undefined && this.props.mrRateInvoiceInfo.bank_account_name !== null && this.props.mrRateInvoiceInfo.bank_account_name !== '' ? this.props.mrRateInvoiceInfo.bank_account_name : getLocalStorageInfo().defaultLessor.bank_account_name,
    //   mr_bank_code: this.props.mrRateInvoiceInfo.bank_code !== undefined && this.props.mrRateInvoiceInfo.bank_code !== null && this.props.mrRateInvoiceInfo.bank_code !== '' ? this.props.mrRateInvoiceInfo.bank_code : getLocalStorageInfo().defaultLessor.bank_code,
    //   mr_bank_iban: this.props.mrRateInvoiceInfo.bank_iban !== undefined && this.props.mrRateInvoiceInfo.bank_iban !== null && this.props.mrRateInvoiceInfo.bank_iban !== '' ? this.props.mrRateInvoiceInfo.bank_iban : getLocalStorageInfo().defaultLessor.bank_iban,
    //   mr_bank_name: this.props.mrRateInvoiceInfo.bank_name !== undefined && this.props.mrRateInvoiceInfo.bank_name !== null && this.props.mrRateInvoiceInfo.bank_name !== '' ? this.props.mrRateInvoiceInfo.bank_name : getLocalStorageInfo().defaultLessor.bank_name,
    //   mr_bank_note: this.props.mrRateInvoiceInfo.bank_note !== undefined && this.props.mrRateInvoiceInfo.bank_note !== null && this.props.mrRateInvoiceInfo.bank_note !== '' ? this.props.mrRateInvoiceInfo.bank_note : getLocalStorageInfo().defaultLessor.bank_note,
    //   mr_bank_reference: this.props.mrRateInvoiceInfo.bank_reference !== undefined && this.props.mrRateInvoiceInfo.bank_reference !== null && this.props.mrRateInvoiceInfo.bank_reference !== '' ? this.props.mrRateInvoiceInfo.bank_reference : getLocalStorageInfo().defaultLessor.bank_reference,
    //   mr_bank_sort_code: this.props.mrRateInvoiceInfo.bank_sort_code !== undefined && this.props.mrRateInvoiceInfo.bank_sort_code !== null && this.props.mrRateInvoiceInfo.bank_sort_code !== '' ? this.props.mrRateInvoiceInfo.bank_sort_code : getLocalStorageInfo().defaultLessor.bank_sort_code,
    //   mr_bank_account_number: this.props.mrRateInvoiceInfo.bank_account_number !== undefined && this.props.mrRateInvoiceInfo.bank_account_number !== null && this.props.mrRateInvoiceInfo.bank_account_number !== '' ? this.props.mrRateInvoiceInfo.bank_account_number : getLocalStorageInfo().defaultLessor.bank_account_number,
    //   mr_bank_swift_code: this.props.mrRateInvoiceInfo.bank_swift_code !== undefined && this.props.mrRateInvoiceInfo.bank_swift_code !== null && this.props.mrRateInvoiceInfo.bank_swift_code !== '' ? this.props.mrRateInvoiceInfo.bank_swift_code : getLocalStorageInfo().defaultLessor.bank_swift_code,
    //   mr_footer_note: this.props.mrRateInvoiceInfo.mr_footer_note !== undefined && this.props.mrRateInvoiceInfo.mr_footer_note !== null && this.props.mrRateInvoiceInfo.mr_footer_note !== '' ? this.props.mrRateInvoiceInfo.mr_footer_note : getLocalStorageInfo().defaultLessor.footer_note,
    //   mr_hub_number: this.props.mrRateInvoiceInfo.mr_hub_number !== undefined && this.props.mrRateInvoiceInfo.mr_hub_number !== null && this.props.mrRateInvoiceInfo.mr_hub_number !== '' ? this.props.mrRateInvoiceInfo.mr_hub_number : getLocalStorageInfo().defaultLessor.hub_number,
    //   mr_vat_number: this.props.mrRateInvoiceInfo.mr_vat_number !== undefined && this.props.mrRateInvoiceInfo.mr_vat_number !== null && this.props.mrRateInvoiceInfo.mr_vat_number !== '' ? this.props.mrRateInvoiceInfo.mr_footer_note : getLocalStorageInfo().defaultLessor.vat_number,
    // }
    this.setState({
      mrRateInvoiceInfo: data
    });
  }

  render(){
    const { mrRateInvoiceInfo, contractInfo } = this.props;
    return(
      <div className="contract-invoice-config data-invoice-block">
        { checkPermission('contracts','reserve_rates','U') && contractInfo?.hdlineInfo?.archived_asset ==false ?
          <span style={{float:'right',marginRight:'23px'}}>
            <img
              onClick={this.props.toggleEditSideBar}
              src={editIconWhite}
              alt="edit"
              className="edit-card-icon"
              width="16"
            />
          </span>:null
        }
        <Row>
          <InvoiceConfigCol
          size = "4"
            title="Invoice Generation Day"
            value={ mrRateInvoiceInfo.mr_invoice_day_of_month ? ordinalSuffixOf(mrRateInvoiceInfo.mr_invoice_day_of_month) + ' of Month':'--' }
          />
          <InvoiceConfigCol
          size = "4"
            title="Invoice Due Day"
            value={ mrRateInvoiceInfo.mr_invoice_due_day_of_month ? ordinalSuffixOf(mrRateInvoiceInfo.mr_invoice_due_day_of_month) + ' of Month':'--' }
          />
          <InvoiceConfigCol
          size = "2"
            title="Invoice Due Month"
            value={ mrRateInvoiceInfo.mr_invoice_is_due_day_next_month ? 'Next Month':'Same Month' }
          />
        </Row>

        <Row>
        
          {titanInstance ? <><InvoiceConfigCol
            size="4"
            title="Reference Number"
            value={mrRateInvoiceInfo.reference_number_reserve ? mrRateInvoiceInfo.reference_number_reserve : '--'}
          /><InvoiceConfigCol
              size="4"
              title="Remarks"
              value={mrRateInvoiceInfo.remarks_reserve ? mrRateInvoiceInfo.remarks_reserve : '--'}
            /></> : ""}
        </Row>


        {
          ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
            <p style={{display:'none'}}>Invoice Sender</p>
          :null
        }
        {
          ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
          <Row style={{display:'none'}}>
            <InvoiceConfigCol
              size="4"
              title="Name"
              value={ mrRateInvoiceInfo.mr_from_company_name ? mrRateInvoiceInfo.mr_from_company_name:'--' }
            />
            <InvoiceConfigCol
              size="4"
              title="Company Address"
              value={ mrRateInvoiceInfo.mr_from_company_address ? mrRateInvoiceInfo.mr_from_company_address:'--' }
            />
            <InvoiceConfigCol
              size="4"
              title="Contact Details"
              value={ mrRateInvoiceInfo.mr_from_company_contact ? mrRateInvoiceInfo.mr_from_company_contact:'--' }
            />
          </Row>
          :null
        }
        {
          ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
            <p style={{display:'none'}}>Invoice Receiver</p>
          :null
        }
        {
          ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
          <Row style={{display:'none'}}>
            <InvoiceConfigCol
              size="4"
              title="Name"
              value={ mrRateInvoiceInfo.mr_to_company_name ? mrRateInvoiceInfo.mr_to_company_name:'--' }
            />
            <InvoiceConfigCol
              size="4"
              title="Company Address"
              value={ mrRateInvoiceInfo.mr_to_company_address ? mrRateInvoiceInfo.mr_to_company_address:'--' }
            />
            <InvoiceConfigCol
              size="4"
              title="Contact Details"
              value={ mrRateInvoiceInfo.mr_to_company_contact ? mrRateInvoiceInfo.mr_to_company_contact:'--' }
            />
          </Row>
          :null
        }
        <Row className="invoice-last-block" style={{display: 'none'}}>
          <InvoiceConfigCol
            size="4"
            title="Bank Name"
            value={ mrRateInvoiceInfo.mr_bank_name ? mrRateInvoiceInfo.mr_bank_name:'--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Bank Account Name"
            value={ mrRateInvoiceInfo.mr_bank_account_name ? mrRateInvoiceInfo.mr_bank_account_name:'--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Bank Account Number"
            value={ mrRateInvoiceInfo.mr_bank_account_number ? mrRateInvoiceInfo.mr_bank_account_number:'--' }
          />

          <InvoiceConfigCol
            size="4"
            title= {getLocalStorageInfo().defaultLessor.id==14?"Bank ABA":"Bank IBAN"}
            value={ mrRateInvoiceInfo.mr_bank_iban ? mrRateInvoiceInfo.mr_bank_iban:'--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Bank Swift Code"
            value={ mrRateInvoiceInfo.mr_bank_swift_code ? mrRateInvoiceInfo.mr_bank_swift_code:'--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Bank SORT Code"
            value={ mrRateInvoiceInfo.mr_bank_sort_code ? mrRateInvoiceInfo.mr_bank_sort_code:'--' }
          />
          {[14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
            <Fragment>
              <InvoiceConfigCol
                size="4"
                title="Hub Account"
                value={mrRateInvoiceInfo.mr_hub_number ? mrRateInvoiceInfo.mr_hub_number :'--'}
              />
              <InvoiceConfigCol
                size="4"
                title="VAT Number"
                value={mrRateInvoiceInfo.mr_vat_number ? mrRateInvoiceInfo.mr_vat_number :'--'}
              />
          </Fragment> :null
          }
          <InvoiceConfigCol
            size="4"
            title="Footer Note"
            value={ mrRateInvoiceInfo.mr_footer_note ? mrRateInvoiceInfo.mr_footer_note:'--' }
          />
        </Row>
     </div>
    )
  }
}
