import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { addEditWorkingGroupMemberApi, getWorkingGroupMemberApi } from '../apiServices';
import { errorCode, addMemberObj } from '../';
class MemberCRU extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader: false,
      member: {},
      error:{},
      mode:'add'
    }
    this.addEditWorkingGroupMemberApi = addEditWorkingGroupMemberApi.bind(this);
    this.getWorkingGroupMemberApi = getWorkingGroupMemberApi.bind(this);
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      member: {
        ...prevState.member,
        [keyParam]: value
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditMemberFn = () => {
    const { member } = this.state;
    let validationInputs = {
      first_name:errorCode['first_name'][fieldValidation({...errorCode['first_nameObj'], fieldval: member.first_name})],
      last_name:errorCode['last_name'][fieldValidation({...errorCode['last_nameObj'], fieldval: member.last_name})],
      email:errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: member.email})],
      mobile:errorCode['mobile'][fieldValidation({...errorCode['mobileObj'], fieldval: member.mobile})],
      designation:errorCode['designation'][fieldValidation({...errorCode['designationObj'], fieldval: member.designation})],
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.addEditWorkingGroupMemberApi(this.props, this.props.groupId, member)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({
            modal:false,
            member:{}
          });
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs})
    }
  }
  render(){
    const { mode, modal, formSubmitLoader, member, error, pageLoader } = this.state;
    const { id, groupId, m, contractInfo,  } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        { id ?
          <span onClick={() => this.getWorkingGroupMemberApi(this.props, groupId, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset == false ?
          <Tooltip title="Add Member">
            <AddCircleOutlineIcon style={{width:'18px', position:'relative', top:'7px'}} onClick={() => this.setState({modal:true, member: addMemberObj})} color="primary" />
          </Tooltip>
          : null
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, member:{}, error:{}}) }
          >
            <div className="drawer-container" style={{width:'670px'}}>
              <div className="drawer-header">

                 <span className="left-blk">{`${capitalizeFirstLetter(mode)} Member`}</span>
                 <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                 { mode !== 'add' ?
                   <ul className="list-inline right-blk">
                     <li className="list-inline-item">
                       <RevisionHistory
                         url='audit/contracts/ctworkinggroupsmembers/'
                         queryParams={{action:'1', object_id:member.id}}
                         buttonType={false}
                       />
                     </li>
                     <li className="list-inline-item">
                       {contractInfo?.hdlineInfo?.archived_asset == false ? checkPermission('contracts','working_groups', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>: null}
                       {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                     </li>
                   </ul>:null
                 }
              </div>
              <div className="drawer-body">
                <Grid container spacing={3}>
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='First Name' value={member.first_name} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="first_name"
                        label="First Name"
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 30 }}
                        value={member.first_name}
                        onChange={(e, value) => this.onFieldChange(e, 'first_name', e.target.value)}
                        error={error.first_name ? true:false }
                        helperText={error.first_name ? error.first_name:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('first_name','')}
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Last Name' value={member.last_name} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="last_name"
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 30 }}
                        value={member.last_name}
                        onChange={(e, value) => this.onFieldChange(e, 'last_name', e.target.value)}
                        error={error.last_name ? true:false }
                        helperText={error.last_name ? error.last_name:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('last_name','')}
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Email Address' value={member.email} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="email"
                        label="Email Address"
                        fullWidth
                        margin="normal"
                        value={member.email}
                        inputProps={{ maxLength: 55 }}
                        onChange={(e, value) => this.onFieldChange(e, 'email', e.target.value)}
                        error={error.email ? true:false }
                        helperText={error.email ? error.email:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('email','')}
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Mobile Number' value={member.mobile} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="mobile"
                        label="Mobile Number"
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 16 }}
                        value={member.mobile}
                        onChange={(e, value) => this.onFieldChange(e, 'mobile', e.target.value)}
                        error={error.mobile ? true:false }
                        helperText={error.mobile ? error.mobile:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('mobile','')}
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Designation' value={member.designation} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="designation"
                        label="Designation"
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 30 }}
                        value={member.designation}
                        onChange={(e, value) => this.onFieldChange(e, 'designation', e.target.value)}
                        error={error.designation ? true:false }
                        helperText={error.designation ? error.designation:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('designation','')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={member.remarks?( <span dangerouslySetInnerHTML={{ __html: member.remarks.replace(/\n/g, '<br />')}}/>):'--'}  /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        multiline
                        rows="4"
                        margin="normal"
                        // inputProps={{ maxLength: 255 }}
                        value={member.remarks}
                        onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                        error={error.remarks ? true:false }
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                {
                  mode !== 'view' ?
                  <Button onClick={this.addEditMemberFn} variant="contained" color="primary" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button> : null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', member:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(MemberCRU));
