import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, withRouter, browserHistory } from 'react-router';
import ContractLeaseInfo from './ContractLeaseInfo';
import { basicInfoAc } from '../Listing/actionCreators';
import { assetViewIdAc } from '../../../User/actionCreators';
import { getMsnSwitcherAc } from '../../../shared/actionCreators';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';
import { getCookie } from '../../../utils';
import { checkPermission } from '../../../utils_v2';
import { ListLoader } from '../../../shared'
import { publishContractAc } from '../Listing/actionCreators';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { imgStoragePath } from '../../../constants';
import techSpecIcon from '../../../shared/assets/img/tech_specs_icon.svg';
import exportIcon from '../../../shared/assets/img/export_icon.svg';
import config from '../../../config';
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			height: 0,
			subHeaderTop: document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0
		}
	}

	changeMsn(evt) {
		this.props.changeMsn(evt);
		this.componentDidMount();
	}
	componentDidMount() {
		this.props.getMsnSwitcher();
		let height = document.getElementById('contract-header').clientHeight;
		this.setState({
			height: height
		});
		if (this.props.type == 'short') {
			this.props.fetchAssetView(this.props.params.aircraft_slug, this.props.pageTitle, this.props.headingText);
		} else {
			this.props.fetchAssetView(this.props.params.aircraft_slug, this.props.pageTitle, this.props.headingText);
			this.props.fetchBasicInfo(this.props.params);
		}

		let cookieVal = getCookie();
		let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0;
		let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0;
		if (cookieVal) {
			this.props.fixedPositionFn('closeMaintenance', false);
			this.props.fixedPositionFn('maintenanceBarHeight', 0);
			this.props.fixedPositionFn('subHeaderTop', headerHeight);
		} else {
			this.props.fixedPositionFn('closeMaintenance', true);
			this.props.fixedPositionFn('maintenanceBarHeight', maintenanceHeight);
			this.props.fixedPositionFn('subHeaderTop', maintenanceHeight + headerHeight);

		}

	}

	refCallback = element => {
		if (element) {
			let height = element.clientHeight;
			let url = "extract-file/" + this.props.dataId;
			if (element.baseURI.includes(url)) {
				this.props.draftHeight(height);

			}
		}
	};

	render() {
		const { msn, serialNo, type, basicInfo, assetViewId, headingText, msnSwitcher, subHeaderTop } = this.props;
		var activeAsset = assetViewId.asset_type == 1 ? msnSwitcher.filter(item => item.asset_type == 1 && item.msn == assetViewId.msn) : assetViewId.asset_type == 2 ? msnSwitcher.filter(item => item.asset_type == 2 && item.esn == assetViewId.esn) : assetViewId.asset_type == 3 ? msnSwitcher.filter(item => item.asset_type == 3 && item.serial_number == assetViewId.serial_number) : assetViewId.asset_type == 4 ? msnSwitcher.filter(item => item.asset_type == 4 && item.serial_number == assetViewId.serial_number) : assetViewId.asset_type === 5 ? msnSwitcher.filter(item => item.asset_type == 5 && item.serial_number == assetViewId.serial_number) : assetViewId.asset_type === 6 ? msnSwitcher.filter(item => item.asset_type == 6 && item.esn == assetViewId.esn) : assetViewId.asset_type === 7 ? msnSwitcher.filter(item => item.asset_type == 7 && item.esn == assetViewId.esn) : assetViewId.asset_type === 8 ? msnSwitcher.filter(item => item.asset_type == 8 && item.esn == assetViewId.esn) : msnSwitcher.filter(item => item.asset_type == 9 && item.esn == assetViewId.esn)
		let asseType = '';
		if (assetViewId.esn) {
			asseType = 'engine';
		} else {
			asseType = 'aircraft';
		}
		if (type == 'short') {
			const slug = basicInfo.slug ? basicInfo.slug : assetViewId.slug;
			return (
				<div className="sub-headder-info">
					<Row className="sub-headder-fixed" id="contract-header" style={{ top: subHeaderTop }}>
						<Col sm="6" className="switcher-container" style={{ maxWidth: '45%' }}>
							<div className="left-side-info">
								<ul className="list-inline msn-switcher flex-centered">
									<li className="list-inline-item">
										<Link to="/assets-listing"><img src={imgStoragePath + "back_arrow.png"} style={{ cursor: "pointer" }} alt="img" width="16" /></Link>
									</li>
									<li className="list-inline-item">
										<Select
											name="form-field-name"
											placeholder="Select Asset"
											className="custom-select-block"
											value={activeAsset[0]}
											options={msnSwitcher}
											valueKey="asset"
											labelKey="value"
											matchProp="any"
											clearable={true}
											onChange={(evt) => this.changeMsn(evt)}
										/>
									</li>
									{headingText ?
										<li className="list-inline-item" style={{ marginRight: 0, marginLeft: '15px' }}>
											<ul className="list-inline other-links">
												<li>
													<Link
														to={`${config.domain.subDomian}technical-specification/${this.props.params.type}/${this.props.params.aircraft_slug}`}
														target='_blank'>
														<img width="15" src={techSpecIcon} alt="tech summery" />
														Technical Specification
													</Link>
												</li>
											</ul>
										</li> : null
									}
								</ul>
							</div>
						</Col>
					</Row>
				</div>
			)
		} else {
			return (
				<div className="sub-headder-info" >
					<Row className="sub-headder-fixed" id="contract-header" style={{ top: subHeaderTop }}>
						<Col sm="6" className="switcher-container" style={{ maxWidth: '45%' }}>
							<div className="left-side-info">
								<ul className="list-inline msn-switcher flex-centered">
									<li className="list-inline-item">
										<span onClick={() => window.history.back()}><img src={imgStoragePath + "back_arrow.png"} style={{ cursor: "pointer"}} alt="img" width="16" /></span>
									</li>
									<li className="list-inline-item">
										<Select
											name="form-field-name"
											placeholder="Select Asset"
											className="custom-select-block"
											value={activeAsset[0]}
											options={msnSwitcher}
											valueKey="asset"
											labelKey="value"
											matchProp="any"
											onChange={(evt) => this.changeMsn(evt)}
										/>
									</li>
									<li className="list-inline-item" style={{ marginRight: 0, marginLeft: '15px' }}>
										<ul className="list-inline other-links">
											<li>
												<Link
													to={`${config.domain.subDomian}technical-specification/${this.props.params.type}/${this.props.params.aircraft_slug}`}
													target='_blank'>

													<img width="15" src={techSpecIcon} alt="tech summery" />
													Technical Specification
												</Link>
												{checkPermission('contracts','lease_details','EXP') ?
													<>
														{assetViewId.esn ?
															<Link onClick={(evt) => this.props.exportContract(basicInfo)}>
																<span className="inventory-link">
																	<img width="16" src={exportIcon} alt="export" />
																	Lease Summary</span>
															</Link> :
															<Link onClick={(evt) => this.props.exportContract(basicInfo)}>
																<span className="inventory-link">
																	<img width="16" src={exportIcon} alt="export" />
																	Lease Summary</span>
															</Link>
														}
													</>:null
												}
											</li>
										</ul>
									</li>
								</ul>

							</div>
						</Col>
						<Col sm="6" style={{display:'flex', justifyContent:'end'}}>
							<div className="contract-lease-info">
								{Object.keys(basicInfo).length ?
									<ContractLeaseInfo leaseInfo={basicInfo} /> : null
								}
							</div>
						</Col>
						{Object.keys(basicInfo).length && basicInfo.lease_status.value == '0' && ( checkPermission('contracts','lease_details','U')) && basicInfo?.archived_asset != true ?
							<p ref={this.refCallback} className="contract-draft-mode" >
								Lease is in Draft Status -
								<span onClick={this.props.publishContract}> Publish Now</span>
							</p> : null
						}
					</Row>
					{
						this.props.contractListLoader ?
							<ListLoader />
							: null
					}
				</div>
			)
		}
	}
}
const mapStateToProps = state => ({
	basicInfo: state.ContractListReducer.basicMetaInfo ,
	assetViewId: state.userAssetReducer.assetViewId,
	msnSwitcher: state.sharedReducers.msnSwitcher,
	closeMaintenance: state.sharedReducers.closeMaintenance,
	maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
	subHeaderTop: state.sharedReducers.subHeaderTop,
	contractListLoader: state.ContractListReducer.contractListLoader,
});
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getMsnSwitcher: () => dispatch(getMsnSwitcherAc()),
		fetchBasicInfo: (id) => dispatch(basicInfoAc(id)),
		fetchAssetView: (id, pageTitle, applicationModule) => dispatch(assetViewIdAc(id, ownProps.params, pageTitle, applicationModule)),
		publishContract: () => dispatch(publishContractAc(ownProps.params)),
		fixedPositionFn: (type, position) => dispatch({
			type: FIXED_POSITIONS_VALUE,
			payload: { type: type, position: position }
		})
	}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
