import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Link } from 'react-router';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea } from '../../Elements';
import { checkString } from '../../../../formValidator';
import { emailRegx } from '../../../../constants';
import { Creatable } from 'react-select';
export default class ShareInvoice extends Component {
  constructor(props){
    super(props)
    this.state = {
      shareInv: {},
      error: {}
    }
  }
  componentDidMount(){
    let data = Object.assign({}, this.props.shareInv);
    data = {
      ...data,
      to_address: this.props.shareInv.to_address.map(email => { return {label: email, value: email} } ),
      cc_address: this.props.shareInv.cc_address ? this.props.shareInv.cc_address.map(email => { return { label: email, value: email } }) : [] 
    }
    this.setState({
      shareInv: data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      shareInv: {
        ...prevState.shareInv,
        [type]: value,
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }));
  }
  onChange = (emails, fieldName) => {
    const emailList = emails.filter(email => emailRegx.test(email.value));
  
    this.setState(prevState => ({
      ...prevState,
      shareInv: {
        ...prevState.shareInv,
        [fieldName]: emailList  
      },
      error: {
        ...prevState.error,
        [fieldName]: ''  
      }
    }));
  }
  
  shareInvoice = (e) => {
    e.preventDefault();
    const validateNewInput = {
      subject: checkString({value: this.state.shareInv.subject, required: true, minLength: '', maxLength: '', message: 'Please enter the Subject'}),
      content: checkString({value: this.state.shareInv.content, required: true, minLength: '', maxLength: '', message: 'Please enter the Content'}),
      to_address: this.state.shareInv.to_address.length > 0 ? '':'Please enter atleast one Email',
    };
    if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.shareInvoiceFn(this.state.shareInv);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { shareInv, error } = this.state;
    const { invoiceLoader } = this.props;
    if(!Object.keys(shareInv).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.shareInvoice(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">To <sup className="reqStar"> * </sup></FieldLabel>
                <Creatable
                  value={shareInv.to_address}
                  onChange={(emails) => this.onChange(emails, 'to_address')}
                  className="custom-select-block"
                  placeholder="Enter Email"
                  multi={true}
                  noResultsText="Type New Email Address"
               />
               {/* <h6 className="error-msg">{error.to_address}</h6> */}
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">CC</FieldLabel>
                <Creatable
                  value={shareInv.cc_address}
                  onChange={(emails) => this.onChange(emails, 'cc_address')}
                  className="custom-select-block"
                  placeholder="Enter CC Email(s)"
                  multi={true}
                  noResultsText="Type New Email Address"
                />
                <h6 className="error-msg">{error.cc_address}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Subject <sup className="reqStar"> * </sup></FieldLabel>
                <FieldTextarea
                  value={shareInv.subject}
                  type="edit"
                  keyParam="subject"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.subject}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Content <sup className="reqStar"> * </sup></FieldLabel>
                <FieldTextarea
                  value={shareInv.content}
                  type="edit"
                  keyParam="content"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.content}</h6>
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        <div className="submit-block">
           <input type="submit" disabled={invoiceLoader} className="primary-btn" value={invoiceLoader ? 'Processing..': 'Share Invoice'}/>
           <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div>
      </form>
    )
  }
}
